@media screen and (max-width: 767px) {
    .dd-uz-auswahl {
        background: #fff;
        border:0;
        border-bottom: .25rem solid var(--color-primary);
        color:var(--color-text-default);
        font-family: var(--font-default);
    }
    .dd-uz-auswahl:before {
        pointer-events: none;
        content: var(--icon-burger);
        font-family: iconfont;
        font-size: .625rem;
        margin-right: .375rem;
        border: 0;
    }

    .dd-uz-auswahl:after {
        display: none;
    }

    .dropdown-menu.dropdown-menu--urlaubszentren {
        border-radius: 0;
        background: #fff;
        border:0;
        padding: 0;
        left:0;
        right:0;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    }
    .dropdown-menu.dropdown-menu--urlaubszentren .nav-item {
        display: block;
        width: 100%;
    }
    .dropdown-menu.dropdown-menu--urlaubszentren .nav-link.active {
        display: none;
    }
}