.fullwidth-teaser {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(360rem / 16);
    @media screen and (min-width: 768px){
        min-height: calc(680rem / 16);
    }
}

.fullwidth-teaser__img {
    background-image: var(--image-src-right-xs) !important;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: 768px) {
        background-image: var(--image-src-right) !important;
    }
}

.fullwidth-teaser__img:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
    z-index:10;
}

.fullwidth-teaser__body {
    position: relative;
    z-index: 11;
    color: #fff;
    text-align: center;
}