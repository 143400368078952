@media screen and (min-width: 768px) {
    .map-scroll {
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

.map-teaser {
    position: relative;
    background: #fff;
    scroll-margin: 1rem;
    border: 4px solid transparent;
    transition: .35s ease-in-out border;
    margin: .75rem 0;

    @media screen and (min-width: 768px) {
        margin: 0 1rem 1.5rem 1rem;
    }
}

.map-teaser--hidden {
    display: none;
    visibility: hidden;
}

.map-teaser:hover,
.map-teaser--is-highlighted {
    border: 4px solid color-mix(in srgb, var(--color-secondary-dark) 50%, white);
    cursor: pointer;
}

.map-teaser--selected,
.map-teaser--selected:hover {
    border: 4px solid var(--color-secondary-dark);
}

.map-teaser--selected .map-teaser__close {
    display: flex;
}

.map-teaser--is-highlighted .map-teaser__hl,
.map-teaser--is-highlighted .map-teaser__categories-text,
.map-teaser--is-highlighted .teaser-tour__atrribute-item {
    /* color: white; */
}

.map-teaser__body {
    padding:1.125rem 3rem 1.125rem 1.125rem;

    @media screen and (min-width: 768px) {
        padding:1.75rem 3rem 1.75rem 1.75rem;
        min-height: 15.25rem;
    }
}

.map-teaser__close {
    position: absolute;
    top: .5rem;
    right: .5rem;
    color: var(--color-dark-grey);
    border-radius: 10rem;
    height: 40px;
    width: 40px;
    font-size: .75rem;

    display: none;
    justify-content: center;
    align-items: center;
}

.map-teaser__hl {
    font-size:1.125rem;
    @media screen and (min-width: 768px) and (max-width: 1439px) {
        font-size:1.25rem;
    }
    @media screen and (min-width: 1440px) {
        font-size:1.625rem;
    }
}
.map-teaser__detaillink {
    display: block;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right:1rem;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #e6e6e6;
}
.map-teaser__detaillink .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
@media screen and (min-width: 768px) {
    .map-teaser__body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        justify-content: center;
        height:100%;
    }
    .map-teaser__body-top {
        justify-content: normal;
    }
    .map-teaser__body-bottom {
        align-self: flex-end;
        width: 100%;
    }
}

.map-teaser .watchList-btn {
    position: absolute;
    left:0;
    top:0;
    padding:1.125rem;
    font-size: 1.25rem;
    color:#fff;
    z-index:10;
    text-shadow: 0 0 10px rgba(0,0,0,.5);
}

.map-teaser .watchList-btn.is-added{
    color: var(--color-danger);
}

.map-teaser__image.embed-responsive-item {
    @media screen and (max-width: 768px) {
        width: 100%;
        object-fit: cover;
        left: 0;
        max-width: unset;
        transform: none;
    }
}
