.flag {
    display: inline-block;
    width:1.5rem;
    height:1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 100%;
    box-shadow: 0 0 5px 0 rgba(67,79,41,0.5);
}
.flag--de {
    background-image: url(/static/img/flags/de-at.svg);
}
.flag--en {
    background-image: url(/static/img/flags/uk.svg);
}
.flag--cs {
    background-image: url(/static/img/flags/cz.svg);
}
.flag--pl {
    background-image: url(/static/img/flags/pl.svg);
}
.flag--nl {
    background-image: url(/static/img/flags/nl.svg);
}