.benefit {
    font-size: calc(13rem / 16);
    line-height: calc(24/15);
    padding: 0 calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
.benefit__icon {
    background: var(--color-primary);
    width: calc(80rem / 16);
    height: calc(80rem / 16);
    font-size: calc(30rem / 16);
    border-radius: 50%;
}
.benefit__icon .icon {
    position: relative;
    top: calc(2rem / 16);
}
.benefit__title {
    font-size: calc(16rem / 16);
    line-height: calc(26/22);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}

/* slider */
.benefit-slider .slick-list {
    padding-bottom: calc(30rem / 16);
    margin-bottom: calc(-30rem / 16);

    @media screen and (max-width: 767px) {
        padding: 0 calc(50rem / 16) calc(30rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .benefit-slider {
        padding: 0 calc(30rem / 16);
    }
    .benefit-slider .slick-list{
        padding-left: 0;
        padding-right: 0;
        margin-left: calc(-12rem / 16);
        margin-right: calc(-12rem / 16);
    }
    .benefit-slider .slider-item {
        padding-left: calc(12rem / 16);
        padding-right: calc(12rem / 16);
    }
    .benefit-slider .slick-arrow {
        width: auto;
        padding: 0;
        height: calc(20rem / 16);
    }
    .benefit-slider .slick-arrow:before {
        font-size: calc(12rem / 16);
        color: var(--color-primary);
        content: var(--icon-arrow-down);
        position: absolute;
        left: 50%;
        top: 50%;
    }
    .benefit-slider .slick-arrow.slick-disabled:before {
        color: var(--color-text-default);
    }
    .benefit-slider .slick-next:before {
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    }
    .benefit-slider .slick-prev:before {
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
}
