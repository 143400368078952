.avalanche-color-1{
     color: #3cb300;
 }

.avalanche-color-2{
    color: #fac700;
}

.avalanche-color-3{
    color: #ff7f00;
}

.avalanche-color-4{
    color: #d20032;
}

.avalanche-color-5{
    color: #9e0025;
}

.bg-avalanche-1{
    background-color: #3cb300;
}

.bg-avalanche-2{
    background-color: #fac700;
}

.bg-avalanche-3{
    background-color: #ff7f00;
}

.bg-avalanche-4{
    background-color: #d20032;
}

.bg-avalanche-5{
    background-color: #9e0025;
}
.avalanche-img__img{

}

.avalanche-img__text{
    font-size: 2rem;
    color: #262626;
    font-family: var(--font-special);
}

.avalanche-text{
    padding: 1rem 1.5rem;
}

.avalanche-info{
    font-size: 0.875rem;
    line-height: 1.4;
}


/*small*/
.avalanche-small {
    position: relative;
    display: inline-block;

    @media screen and (min-width: 768px){
        padding-left:2.5rem;
        top:-.625rem;
        margin: 0 auto;
    }
    @media screen and (max-width: 767px){
        font-size:1rem;
        margin: 0 auto;
    }
}
.avalanche-small__indicator {
    display: block;
    width:1.375rem;
    height:1.375rem;
    border-radius: 50%;
    margin:.125rem auto .625rem auto;

    @media screen and (min-width: 768px){
        width:1.5rem;
        height:1.5rem;
        position: absolute;
        top: 0;
        left:0;
        margin:0;
    }
}
.avalanche-small__detaillink {
    text-decoration: underline;
}
.avalanche-small__detaillink:hover,
.avalanche-small__detaillink:focus {
    text-decoration:none;
}