.embed-responsive-gallery-admin-headerimg:before {
    padding-top: 75%;
    @media screen and (min-width: 768px) {
        padding-top: 25.5%;
    }
}

.gallery-admin-upload-container,
.gallery-admin-img-container {
    position: relative;
}

.gallery-admin-position {
    display: block;
    padding:.75rem 1rem;
    position: absolute;
    background: transparent;
    left:.75rem;
    top:50%;
    transform: translateY(-50%);
    z-index:10;
    border:0;
    -webkit-appearance: none;
    line-height:1;
    @media screen and (min-width: 768px) {
        padding:.375rem .75rem;
    }
}

.gallery-admin-position .icon {
    pointer-events: none;
}
.gallery-admin-position--top {
    margin-top:-1.125rem;
    border-bottom:1px solid #000;
}
.gallery-admin-position--bottom {
    margin-top:1.125rem;
    border-top:1px solid #000;
}
.gallery-admin-position--bottom > .icon {
    position: relative;
    left:-.125rem;
}

.gallery-admin-row {
    position: relative;
    padding:1rem 0;
    margin:.5rem 0;
    background:#fff;
    z-index: 1100;

    @media screen and (min-width: 768px) {
        padding:1.5rem 2rem 1.5rem 3.5rem;
    }
}

.gallery-admin-row--upload{
    padding:0;
    @media screen and (min-width: 768px) {
        padding:0;
    }
}


@media screen and (min-width: 768px) {
    .gallery-admin-row:not(.gallery-admin-row--upload):focus,
    .gallery-admin-row:not(.gallery-admin-row--upload):hover {
        box-shadow: 0 0 4px 0 rgba(62,54,63,0.33);
    }
}
.rating-form .gallery-admin-row:not(.gallery-admin-row--upload),
.rating-form .gallery-admin-row:not(.gallery-admin-row--upload) {
    border: 1px dashed var(--color-default);
    padding: 0.5rem 0.5rem 3.5rem 0.5rem;
    @media screen and (min-width: 768px) {
        padding:1.5rem 2rem 1.5rem 3.5rem;
    }
}

.rating-form .gallery-admin-position {
    @media screen and (max-width: 767px) {
        top: 95%;
    }
}

.rating-form .btn-eem {
    @media screen and (max-width: 767px) {
        position: absolute;
        right: 0.5rem ;
        top: 1.75rem;
    }
}