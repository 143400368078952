.percentage-item {
    position: relative;
    border-radius: 50%;
    background: conic-gradient(var(--color-grey) 0% 0%, var(--color-grey) 0% );
    height: calc(223rem / 16);
    width: calc(223rem / 16);
    margin: 0 auto;
    transition: background ease-in 0.5s;
}

.percentage-item__content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    background: var(--color-white);
    height: calc(190rem / 16);
    width: calc(190rem / 16);
}

.percentage-item__points {
    color: var(--color-primary);
    font-size: calc(64rem /16);
    line-height: 1;
    font-family: var(--font-default-bold);
}

.percentage-item__text {
    color: var(--color-text-default);
    font-size: calc(20rem /16);
}