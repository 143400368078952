.image-teaser {
    position: relative;
}
@media screen and (min-width: 768px) {
    .image-teaser__img {
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.image-teaser__img:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(108.15deg, rgba(67,79,41,0) 0%, #3E363F 100%);
    z-index:10;
    transition: all .2s linear;
    transform: scaleX(-1);
}
.image-teaser--right .image-teaser__img:after {
    transform: none;
}
/*.image-teaser:focus:after,
.image-teaser:hover:after {
    opacity: 0.125;
}*/
.image-teaser__body {
    padding:1rem 0;
    position:relative;
    @media screen and (min-width: 768px) {
        padding:4rem 0;
        color:#fff;
        z-index:15;
        text-shadow: 0 0 10px rgba(0,0,0,.25);
    }

    @media screen and (min-width: 768px) and (max-width: 1600px) {
        padding-left:6.5rem;
    }

    @media screen and (max-width: 767px) {
        background:#fff;
    }
}
.image-teaser--right .image-teaser__body {
    @media screen and (min-width: 768px) and (max-width: 1600px) {
        padding-left:0rem;
    }
}
.image-teaser__body .btn {
    text-shadow:none;
}

.img-teaser__video{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
}
