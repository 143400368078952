.wysiwyg table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.25rem;

}

.wysiwyg table > thead > tr > th,
.wysiwyg table > tbody > tr > th,
.wysiwyg table > tfoot > tr > th,
.wysiwyg table > thead > tr > td,
.wysiwyg table > tbody > tr > td,
.wysiwyg table > tfoot > tr > td {
    padding: .5rem 1rem;
    text-align:left;
    background-color: var(--color-white);
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    @media screen and (min-width: 768px){
        padding: 1rem  1.25rem;
    }
}

.wysiwyg table > thead > tr > th {
    font-family: var(--font-default-bold);
}

.wysiwyg table > thead > tr > th:first-child,
.wysiwyg table > tbody > tr > th:first-child,
.wysiwyg table > tfoot > tr > th:first-child,
.wysiwyg table > thead > tr > td:first-child,
.wysiwyg table > tbody > tr > td:first-child,
.wysiwyg table > tfoot > tr > td:first-child {
    border-left: 1px solid var(--color-grey);
}

.wysiwyg table > thead > tr > th:last-child,
.wysiwyg table > tbody > tr > th:last-child,
.wysiwyg table > tfoot > tr > th:last-child,
.wysiwyg table > thead > tr > td:last-child,
.wysiwyg table > tbody > tr > td:last-child,
.wysiwyg table > tfoot > tr > td:last-child{
    border-right: 1px solid var(--color-grey);
}

.wysiwyg table > tbody > tr.has-additional-info > td {
    border:0;
    padding:0;
}
.additional-info-area {
    background:#fff;
    border:.25rem solid var(--color-primary);
}

.table--opening-hours {
    position: relative;
    margin-bottom:2rem;
}
.table--opening-hours td, .table--opening-hours th {
    font-size:.875rem;
    line-height:1.25;
    padding: .375rem;
    vertical-align: middle;
    @media screen and (min-width: 768px) {
        padding: .25rem;
    }
}
.table--opening-hours th {
    font-size:1rem;
    font-family: var(--font-default-bold);
    vertical-align: middle;
    border-top:0;
}
.opening-status {
    margin-top:.125rem;
    font-size:.625rem;
}
.opening-text--open,
.opening-status--open {
    color:var(--color-primary);
}
.opening-text--closed,
.opening-status--closed {
    color:var(--color-danger);
}
.opening-text {
    font-size:.875rem;
}


@media screen and (max-width: 767px) {
    .xs-table-scroller {
        color:var(--color-primary);
        font-size: 1rem;
    }
    .table-responsive {
        min-height: .01%;
        overflow-x: auto;
        margin-right:-1rem;
        width: calc(100% + 1rem);
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /*border:2px solid #000;*/
    }
    .table-responsive table {
        border:0;
    }
    .table-responsive > table > thead > tr > th,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tfoot > tr > td {
        white-space: nowrap;
    }
}