.profile-row + .profile-row {
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem / 16);
    }
}

.profile-row {
    @media screen and (max-width: 767px) {
        padding: 0 calc(15rem / 16);
    }
}

.profile__sticky-button {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary);
    @media screen and (min-width: 768px) {
        background: var(--color-mid-grey);
        margin-left: calc(-100rem / 16);
    }
}

.profile__sticky-button.profile__sticky-button--selection {
    z-index: 997;
}