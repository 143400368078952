.content-box {
    position: relative;
    padding: calc(20rem / 16) calc(20rem / 16) calc(20rem / 16);
    box-shadow: 0 calc(20rem / 16) calc(50rem / 16) 0 rgba(67,79,41,0.3);
    border-radius: calc(8rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(25rem / 16) calc(30rem / 16);
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(50rem / 16);
    }
}
.content-box__fill-xs {
    @media screen and (max-width: 767px) {
        margin: 0 calc(-20rem / 16);
    }

}
.content-box__subtitle {
    font-size: calc(18rem / 16);
    line-height: calc(26/22);

    @media screen and (min-width: 1400px) {
        font-size: calc(22rem / 16);
    }
}
.content-box__title {
    font-size: calc(28rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(42rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(52rem / 16);
    }
}
.content-box__title-sm {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(32rem / 16);
    }
}

.content-box__title-container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 768px) {
        padding: 0 calc(130rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(200rem / 16);
    }
}
.content-box__title-container--sm.content-box__title-container--sm{
    margin-left: 0;
    padding: 0;
}
.info-badge + .content-box__title-container {
    @media screen and (max-width: 767px) {
        margin-top: calc(70rem / 16);
    }
}

.content-box--loyalty{
    position: relative;
    padding: calc(20rem / 16);
    box-shadow: 0 calc(20rem / 16) calc(50rem / 16) 0 rgba(67,79,41,0.3);
    border-radius: calc(8rem / 16);
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(20rem / 16) calc(20rem / 16) calc(10rem / 16) calc(20rem / 16) ;
    }
}

/* small */
.content-box--sm {
    position: relative;
    padding: calc(20rem / 16);
    box-shadow: 0 calc(20rem / 16) calc(50rem / 16) 0 rgba(67,79,41,0.3);
    border-radius: calc(8rem / 16);
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(20rem / 16);
        font-size: calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(53rem / 16) calc(20rem / 16);
    }
}
.content-box__count {
    font-size: calc(100rem / 16);
    line-height: 1;
    font-family: var(--font-special);
    font-weight: 400;
    padding: calc(10rem / 16) 0;

    @media screen and (min-width: 768px) {
        font-size: calc(75rem / 16);
        padding: calc(20rem / 16) 0;
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(130rem / 16);
        padding: calc(40rem / 16) 0;
    }
}