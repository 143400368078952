.btn {
    text-shadow: none;
    font-size:.9375rem;
    border-radius:0;
    font-family:var(--font-default-bold);
    padding:.5rem 1.125rem;
    text-shadow: none;

    @media screen and (min-width: 768px) {
        padding:.75rem 2rem;
        font-size:1rem;
    }
}

.btn.disabled {
    cursor: not-allowed;
}
.btn.btn-sm {
    font-size:.875rem;
    padding:.375rem .875rem;

    @media screen and (min-width: 768px) {
        padding:.5rem 1rem;
    }
}

.btn.btn-lg {
    font-size:1rem;
    padding:1rem 2rem;

    @media screen and (min-width: 768px) {
        font-size: 1.375rem;
        padding: 2.125rem 1.75rem;
    }
}

.btn.btn-lg.btn-bb-quickfinder {
    @media screen and (min-width: 768px) {
        padding: 1.125rem 1rem;
    }
}
.btn.btn-xs-collapse {
    display: block;
    color:#fff;
    font-size:1rem;
    font-family:var(--font-default);
    padding:.75rem 1rem;
    border: 1px solid var(--color-grey);
    background-color: var(--color-text-muted);;
    margin-bottom: .375rem;
}
.btn.btn-xs-collapse.collapsed {
    color:var(--color-text-muted);
    background-color: #fff;
}
.btn.btn-xs-collapse .icon {
    font-size:.625rem;
    line-height: 1px;
    position: relative;
    top: -.125rem;
    margin: 0 .5rem;
}
.btn.btn-xs-collapse.collapsed .icon {
    transform: rotate(45deg);
}
.btn.btn-eem {
    color:#fff;
    font-size:.75rem;
    border-radius:5rem;
    font-family:var(--font-default);
    padding:.125rem .75rem;
    text-transform: uppercase;
    background-color: #8E8E8D;
}
.btn.btn-eem--dark {
    background-color: #3E363F;
}
.btn.btn-eem--white {
    background-color: #fff;
    color: #8e8e8d;
    border: 1px #8e8e8d solid;
}
.btn.btn-eem>.icon {
    position: relative;
    margin-right:.375rem;
    top:.125rem;
}

.btn.btn-icon {
    padding-left:1.75rem;
    padding-right:1.75rem;
}
.btn.btn-icon>.icon {
    position: relative;
    top:.375rem;
    line-height:1px;
    font-size:1.375rem;
    transition: all .2s linear;
    @media screen and (min-width: 768px) {
        top:.5rem;
        font-size:1.125rem;
    }
}

.btn.btn-icon--right>.icon-plus {
    margin-left:.5rem;
    top:.125rem;
    font-size:.75rem;
    @media screen and (min-width: 768px) {
        top:0;
        font-size:.75rem;
    }
}
.btn.collapsed>.icon {
    transform: rotate(45deg);
}


.btn.btn-icon-text .icon {
    position: relative;
    line-height:1px;
    font-size:1.125rem;
    margin-right:.5rem;
    top:.25rem;
    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}

.btn.btn-icon-text .icon-close {
    font-size:.625rem;
    top:0rem;
    @media screen and (min-width: 768px) {
        font-size:.75rem;
    }
}
.btn.btn-icon-text--right .icon {
    margin-right:0;
    margin-left:.5rem;
}

.btn-lg.btn-icon-text .icon {
    font-size:1.5rem;
    margin-right:.5rem;
    top:.375rem;
    @media screen and (min-width: 768px) {
        font-size:2rem;
        top:.5rem;
    }
}

.btn__icon {
    font-size:1.125rem;
    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}

/* booking button */
.btn-booking {
    color: #fff;
    background: var(--color-booking);
}
.btn-booking>.icon {
    position: relative;
}
.btn-booking:hover,
.btn-booking:focus {
    color: #fff;
    background: var(--color-booking-dark);
}

@media screen and (min-width: 768px) {
    .btn.btn-booking--has-ticket,
    .btn.btn-booking--with-ticket {
        font-size: 1rem;
        padding: 1.5rem 1.75rem .75rem 1.75rem;
    }
    .btn.btn-booking--has-ticket>.icon.icon,
    .btn.btn-booking--with-ticket>.icon.icon {
        display: block;
        font-size: 1.5rem;
        margin:0 0 1rem 0;
        top: auto;
    }
}

/* skiticket button */
.btn-booking--has-ticket {
    color: #fff;
    background: #5990d6;
    margin-right:-.25rem;
}
.btn-booking--has-ticket:hover,
.btn-booking--has-ticket:focus {
    color: #fff;
    background: #5176b4;
}



/* default */
.btn-default{
    color: var(--color-primary);
    border-color: #F5F5F5;
    background: #F5F5F5;
}
.btn-default:hover,
.btn-default:focus {
    color: var(--color-primary);
    border-color: #e9e9e9;
    background: #e9e9e9;
}


/* white */
.btn-white {
    color: var(--color-primary);
    background: #fff;
}
.btn-white:hover,
.btn-white:focus {
    color: var(--color-primary);
    background: #e9e9e9;
}
.btn-white--txt-dark {
    color: var(--color-text-default);
    font-family: var(--font-default);
}
.btn-white--txt-dark:hover,
.btn-white--txt-dark:focus {
    color: var(--color-text-default);
    background: #e9e9e9;
}

.btn-shadow {
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(67,79,41,0.15);
}

/* light-grey */
.btn-light-grey {
    font-family:var(--font-default);
    color: var(--color-dark-grey);
    background: #fff;
    border: 1px solid #DDDDDD;
}
.btn-light-grey:hover,
.btn-light-grey:focus {
    color: var(--color-text-default);
    background: #DDDDDD;
}


/* nostyle */
.btn.btn-nostyle,
.btn.btn-nostyle:hover,
.btn.btn-nostyle:focus {
    color:inherit;
    margin:0;
    padding:0;
    border:0;
}
.btn.btn-nostyle--browse,
.btn.btn-nostyle--browse:hover,
.btn.btn-nostyle--browse:focus,
.btn.btn-nostyle--calendar,
.btn.btn-nostyle--calendar:hover,
.btn.btn-nostyle--calendar:focus {
    border:1px solid;
    padding:0 .5rem;
}
.btn.btn-nostyle--browse {
    cursor: pointer;
    padding: .875rem .5rem !important;
    line-height:1px;
}
.btn.btn-nostyle--browse:hover,
.btn.btn-nostyle--browse:focus,
.btn.btn-nostyle--calendar:hover,
.btn.btn-nostyle--calendar:focus{
    background:var(--color-text-default);
    border-color:var(--color-text-default);
    color:#fff;
}
.btn-outline-secondary:hover {
    color:#fff;
}
.btn.btn-nostyle--choose{
    padding: calc(3rem/16) calc(10rem/16);
    border: solid 1px var(--color-text-default);
    font-size: calc(12rem/16);
    cursor: pointer;
    border-radius: 2px;
}
.btn.btn-nostyle--choose:hover,
.btn.btn-nostyle--choose:focus{
    background:var(--color-text-default);
    border-color:var(--color-text-default);
    color:#fff;
    padding: calc(3rem/16) calc(10rem/16);
}




/* facebook */
.btn-facebook {
    color:#fff;
    background: #3b5999;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
    color:#fff;
    background: #32437a;
}


/* google */
.btn-google{
    color:#fff;
    background: #dc4e41;
}
.btn-google:hover,
.btn-google:focus,
.btn-google:active {
    color:#fff;
    background: #c8493c;
}

/* apple */
.btn-apple{
    color:#fff;
    background: #000000;
}
.btn-apple:hover,
.btn-apple:focus,
.btn-apple:active {
    color:#fff;
    background: #000000;
}


.button-row .btn {
    @media screen and (max-width: 767px) {
        display:block;
    }
}
.button-row .btn + .btn {
    @media screen and (min-width: 768px) {
        margin-left:.5rem;
    }
}

.btn-transparent{
    background: transparent;
    border: transparent;
}

.btn-demi-default,
.btn.btn-demi-default {
    color: #333;
    background: #e6e6e6;
    font-weight: 400;
    font-family: var(--font-default);
    padding: .6875rem .75rem;
    font-size: .8125rem;
    line-height: 1.25;
}
.btn-demi-default:focus {
    /*override important*/
    box-shadow: 0 0 0 3px rgba(177,194,0,.25) !important;
}
.btn-demi-default .btn__icon,
.btn.btn-demi-default .btn__icon {
    font-size: 16px;
    vertical-align: -.2em;
}
.btn-text {
    color:var(--color-primary);
    text-decoration: none;
}
.btn-text:hover,
.btn-text:focus {
    color:var(--color-primary);
    text-decoration: underline;
}