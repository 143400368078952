.demi-list-footer{
  color: #fff;
  text-align: center;
  padding: 1rem;
  background: var(--color-primary);
  text-align: center;
}

.demi-list-footer a{
    text-decoration: underline;
}

.demi-list-footer a:hover {
  text-decoration: none;
}