.tab-wrapper-white {
    background: #fff;
    padding:.875rem .875rem .125rem .875rem;

    @media screen and (min-width: 768px) {
        padding:.875rem 1.75rem .125rem 1.75rem;
    }
}

.nav-tabs {
    border:0;
}
.nav-tabs .nav-item {
    margin:0;
    background-color: var(--color-white);
    border: 0;
    position:relative;
/*    border-bottom: 0.25rem solid transparent;
    border-bottom-color:#e9ecef;*/

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.nav-tabs .nav-link{
    border:0;
    min-width:4rem;
    text-align: center;
    padding: .5rem .5rem;
    font-family: var(--font-default);
    color: var(--color-dark-dark);
    display: block;

    @media screen and (min-width: 768px) {
        padding: .75rem 1rem .75rem 1rem;
        min-width:5rem;
        /*height:100%;*/
    }
    @media screen and (min-width: 1440px) {
        font-size: 1.125rem;
    }
}
.nav-tabs--small .nav-link{
    min-width:4rem;
    @media screen and (min-width: 768px) {
        padding: .75rem 1rem 1rem 1rem;
        min-width:4rem;
    }
    @media screen and (min-width: 1440px) {
        font-size: 1rem;
    }
}

.nav-tabs--poi-list .nav-link{
    padding: .625rem 1rem .5rem .5rem;
    @media screen and (min-width: 768px) {
        padding: .875rem 1.875rem .75rem 1.25rem;
    }
}

.nav-tabs .nav-link .flag {
    position: relative;
    top:.25rem;
    margin-right: .5rem;
}

/*.nav-tabs .nav-item.active,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus{
    color: var(--color-dark-dark);
    border-bottom-color:var(--color-primary);
}*/
.nav-tabs .nav-item.active:after,
.nav-tabs .nav-link.active:after,
.nav-tabs .nav-link:hover:after,
.nav-tabs .nav-link:focus:after {
    content:'';
    display: block;
    position: absolute;
    bottom:0;
    right:0;
    left:0;
    color: var(--color-dark-dark);
    border-bottom: 0.25rem solid var(--color-primary);
}
.nav-tabs .nav-link .icon,
.nav-tabs__img,
.nav-tabs__icon{
    line-height:1px;
    position: relative;
    top:0.125rem;
    margin:0 .5rem;
    font-size: 1.25rem;

    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
        top:0.25rem;
    }
}

.nav-tabs__img {
    display: inline-block;
    width:1.25rem;
    height:auto;
    top:0;
    filter: grayscale(100%);

    @media screen and (min-width: 768px) {
        top:-.25rem;
        width:3.25rem;
    }
}

@media screen and (min-width: 768px) {
    .nav-tabs .nav-tabs__img.nav-tabs__img--block,
    .nav-tabs .nav-tabs__icon.nav-tabs__icon--block{
        display: block;
        margin:.5rem auto 1rem auto;
    }
    .nav-tabs .nav-tabs__img.nav-tabs__img--block{
        width: auto;
        height:1.5rem;
        margin-bottom: -.25rem;
    }
}
.nav-tabs .nav-link.active .nav-tabs__img,
.nav-tabs .nav-link:hover .nav-tabs__img,
.nav-tabs .nav-link:focus .nav-tabs__img{
    filter: grayscale(0);
}
.nav-tabs--weather {
    width: 100%;
    max-width: 55rem;
    margin: 0 auto;
}


/* v2 */
.nav-tabs--v2 .nav-link{
    min-width:4rem;
    @media screen and (min-width: 768px) {
        padding: 1.25rem 3rem .5rem 3rem;
        min-width:4rem;
    }
    @media screen and (min-width: 1440px) {
        font-size: 1rem;
    }
}


@media screen and (max-width: 768px) {
    .nav-tabs--xs-full{
        border:0;
    }
    .nav-tabs--xs-full .nav-item{
        margin-bottom:.375rem;
        display: block;
        width: 100%;
        border-width:1px;
    }
    .nav-tabs--xs-full .nav-icon{
        margin-left:0;
    }
}

/* if there are more than 8 elements*/
.nav-tabs .nav-item:nth-last-child(n+8) .nav-link,
.nav-tabs .nav-item:nth-last-child(n+8) ~ .nav-item .nav-link {
    @media screen and (min-width: 768px) {
        padding: .25rem .5rem .5rem .5rem;
        font-size: .625rem;
    }

    @media screen and (min-width: 1200px) {
        padding: .625em .75em;
        font-size: .75rem;
    }

    @media screen and (min-width: 1440px) {
        font-size: 1rem;
    }
}
.nav-tabs .nav-item:nth-last-child(n+8) .nav-tabs__icon,
.nav-tabs .nav-item:nth-last-child(n+8) ~ .nav-item .nav-tabs__icon {
    @media screen and (min-width: 768px) {
        font-size: 1rem;
    }
}