.lang-switch {

}
a.lang-switch__dditem,
.lang-switch__link {
    font-size:1rem;
    margin:0;
    padding: .5rem 1.125rem .5rem 2.125rem;
    border:0;
    display: block;
    position: relative;
    color:var(--color-dark-grey);
    font-family:var(--font-default-bold);
}
a.lang-switch__dditem .flag,
.lang-switch__link .flag {
    position: absolute;
    left:0;
    top: 50%;
    transform: translateY(-50%);
}
a.lang-switch__dditem:hover,
a.lang-switch__dditem:focus {
    color:var(--color-dark);
}
.lang-switch__link:after{
    display: none !important;
}
.lang-switch__link:before{
    content:'';
    position: absolute;
    top:.75rem;
    right:0;
    width:8px;
    height:8px;
    border:2px solid;
    border-width:0 2px 2px 0;
    transform: rotate(45deg);
    transition: 0.15s linear;
}
.dropdown-menu.lang-switch__dropdown {
    padding: .5rem 1.125rem;
    margin:0;
    font-size: 1rem;
    color: var(--color-text-default);
    border:0;
    border-radius:0;
    box-shadow: 0 2px 14px 0 rgba(67,79,41,0.2);
}


.lang-switch__dditem {

}
