.feed-teaser__list {
    margin-top: calc(100rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: calc(160rem / 16);
        margin-bottom: calc(24rem / 16);
    }
}

.feed-teaser {
    display: grid;
    position: relative;
    background-color: #fff;
    border-radius: calc(8rem / 16);
    box-shadow: 0 calc(4rem / 16) calc(8rem / 16) 0 rgba(0, 0, 0, 0.1);
    padding: calc(16rem / 16) calc(36rem / 16) calc(22rem / 16) calc(16rem / 16);
    grid-template-columns: 20% calc(100% - 20%);
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas:
        ". .";
    @media screen and (min-width: 768px) {
        grid-template-columns: 12% calc(100% - 12%);
        padding: calc(55rem / 16) 12% calc(45rem / 16) calc(18rem / 16);
    }
}

.feed-teaser + .feed-teaser {
    margin-top: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: calc(80rem / 16);
    }
}

.feed-teaser__icon {
    background-color: var(--color-primary);
    border-radius: 50%;
    height: calc(40rem / 16);
    width: calc(40rem / 16);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        height: calc(80rem / 16);
        width: calc(80rem / 16);
        font-size: calc(40rem / 16);
        margin-right: calc(30rem / 16);
    }
}

.feed-teaser__icon.feed-teaser__icon--img {
    background-color: var(--color-light-grey);
}

.feed-teaser__icon.feed-teaser__icon--img img {
    width: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
    }
}

.feed-teaser__icon:before {
    content: "";
    position: absolute;
    width: calc(1rem / 16);
    background: var(--color-primary);
    height: calc(46rem / 16);
    top: calc(-46rem / 16);
    left: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        height: calc(135rem / 16);
        top: calc(-135rem / 16);
        left: calc(40rem / 16);
    }
}

.feed-teaser__icon:after {
    content: "";
    position: absolute;
    width: calc(1rem / 16);
    background: var(--color-primary);
    top: calc(40rem / 16);
    left: calc(20rem / 16);
    height: calc(100% - calc(16rem / 16));
    @media screen and (min-width: 768px) {
        top: calc(80rem / 16);
        left: calc(40rem / 16);
        height: calc(100% - calc(35rem / 16));
    }
}

.feed-teaser:first-child .feed-teaser__icon:before,
.feed-teaser:last-child .feed-teaser__icon:after {
    display: none;
}

.feed-teaser__content {
    text-align: left;
}

.feed-teaser__date {
    color: var(--color-text-muted);
    font-size: calc(14rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}

.feed-teaser__title {
    color: var(--color-text-default);
    font-family: var(--font-default-bold);
    font-size: calc(18rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.feed-teaser__image {
    border-radius: calc(10rem / 16);
    @media screen and (min-width: 768px) {
        max-width: calc(500rem / 16);
        border-radius: calc(10rem / 16) 0 calc(10rem / 16) calc(10rem / 16);
    }
}

.feed-teaser__text {
    color: var(--color-text-muted);
    font-size: calc(14rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}

.feed-teaser__text--read-more {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.feed-teaser__link {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    text-decoration: underline;
    font-size: calc(16rem / 16);
    display: block;
    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}

.feed-teaser__link:hover {
    text-decoration: none;
    color: var(--color-primary);
}

.feed-backdrop {
    position: fixed;
    inset: 0;
    z-index: 996;
    background: #1D1D1B90;
}

.feed-teaser__select-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 996;
    border-bottom-left-radius: calc(6rem / 16);
    border-bottom-right-radius: calc(6rem / 16);
}

.feed-teaser__select-btn:hover,
.feed-teaser__select-btn:active,
.feed-teaser__select-btn:focus {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.feed-teaser__select-btn .icon-minus {
    top: unset !important;
    font-size: calc(3rem / 16) !important;
    bottom: 0.125rem;
}

.feed-teaser__select-btn--remove,
.feed-teaser__select-btn--add {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feed-teaser.is-selected {
    box-shadow: 0 0 0 calc(4rem / 16) var(--color-primary);
    z-index: 996;
}

.feed-teaser.is-selected:after {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: all;
    background: linear-gradient(0deg, rgba(29, 29, 27, 1) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 100%);
    border-bottom-left-radius: calc(8rem / 16);
    border-bottom-right-radius: calc(8rem / 16);
}

.feed-select__banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 997;
    text-align: center;
    background: var(--color-mid-grey);
    font-family: var(--font-default-bold);
    color: var(--color-text-muted);
    padding: calc(16rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(24rem / 16);
        font-size: calc(24rem / 16);
    }

}

.feed__story {
    margin-top: -40vh;
    @media screen and (min-width: 768px) {
        margin-top: -50vh;
    }
}