.cookie-bar {
    font-size:.875rem;
    padding: .75rem 1rem;
    z-index: 1000;
}

@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: baseline;
    }
}

.cookie-bar__left{
    width: 100%;
    @media screen and (max-width: 767px){
        text-align:center;
    }
}

.cookie-bar__text,
.cookie-bar__detail{
    display: inline-block;
}

@media screen and (min-width: 768px){
    .cookie-bar__text{
        margin-right: calc(15rem/16);
    }
}
.cookie-bar__detail-link{
    text-decoration: underline;
    font-size:.875rem;
}

.cookie-bar__detail-link +.cookie-bar__detail-link{
    margin-left: calc(15rem/16);
}

.cookie-bar__detail-link:hover{
    text-decoration: none;
}


.cookie-bar__detail-btn-no-style{
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
}

.cookie-bar__buttons {
    margin-top: .75rem;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin:0 0 0 1rem;
        text-align: right;
    }
}


.cookie-bar__accept {
    margin-left: .75rem;

    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
    }
}
.cookie-modal__label {
    font-size: 1.125rem;
}
.cookie-modal .modal-header .close {
    font-size: 2rem;
    padding: 1rem 1.5rem;
}