/* base styles */
.ratings-teaser {
    position: relative;
    margin-bottom: 1.5rem;
    background:#fff;
    overflow:hidden;
}
.ratings-teaser + .ratings-teaser {
    @media screen and (min-width: 768px) {
        margin-top: 4rem;
    }
}
.ratings-teaser__body {
    padding: 1.125rem 1rem 1rem 1rem;
    @media screen and (min-width: 768px) {
        padding:1.5rem;
    }
}

.ratings-teaser__hl {
    line-height:1;
    margin-bottom:.875rem;
    position: relative;
    font-family: var(--font-default-bold);
    font-size: calc(18rem/ 16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/ 16);
        margin-right: calc(96rem /16);
    }
    @media screen and (max-width: 767px){
        margin-top: calc(14rem /16);
    }
}
.ratings-slider {
    position: relative;
}
.ratings-slider__arrows {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: .375rem 3rem;
    background: #fff;
    z-index: 1;
    font-size: .875rem;
}
.ratings-slider__arrow:before {
    font-size: calc(18rem /16)!important;
}
.ratings-slider__arrow {
    height: calc(33rem /16)!important;
    width: calc(33rem /16)!important;
}

.ratings-teaser__author-img {
    height: calc(26rem / 16);
    width: calc(26rem / 16);
    display: inline-block;
    border-radius: 50%;
    margin-right: calc(6rem /16);
}

.ratings-teaser__comment{
    padding: calc(16rem /16) calc(16rem /16);
    position: relative;
    width: 100%;
    @media screen and (min-width: 768px){
        padding: calc(24rem /16) calc(40rem /16);
    }
}

.ratings-teaser__badges {
    margin-bottom: calc(16rem /16);
    @media screen and (min-width: 768px){
        position: absolute;
        right: calc(20rem /16);
        top: calc(20rem /16);
    }
}
.ratings-teaser__btn-edit,
.ratings-teaser__btn-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(38rem / 16);
    padding: calc(8rem / 16) calc(16rem / 16) !important;
    position: absolute;
    right: 0;
    @media screen and (max-width:767px){
        height: calc(24rem / 16);
        font-size: calc(12rem /16);
        padding: calc(6rem / 16) calc(10rem / 16) !important;
    }
}
.ratings-teaser__btn-delete {
    top: 2.25rem;
    @media screen and (min-width:768px){
        top: calc(68rem /16);
    }
}
.ratings-teaser__btn-edit {
    top: calc(6rem /16);
    @media screen and (min-width:768px){
        top: calc(24rem /16);
    }
}

.ratings-teaser__slider-wrapper .slick-slide > div,
.ratings-teaser__slider-wrapper .slick-list,
.ratings-teaser__slider-wrapper .slick-track {
    height: 100%;
}

.ratings-teaser__data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: calc(8rem /16);
    line-height: 2;
}