.custom-file-upload input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    display: block;
    width: 100%;
}
.custom-file-upload .custom-file-upload__inner {
    position: relative;
    width: 100%;
    height:auto;
    padding: 2.375rem .75rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border:1px dashed var(--color-default);
}
.custom-file-upload:hover .custom-file-upload__inner {
    border:1px dashed #000;
}
.custom-file-upload__body {
    display: block;
    position: relative;
    padding: 0.625rem;
}

