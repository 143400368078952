.scroll-hint {
    position: absolute;
    bottom: 15rem;
    right: .5rem;
    pointer-events: none;
    z-index: 900;
    text-shadow: 0px 0px 20px rgba(0,0,0,0.59);
    @media screen and (max-width:1770px) {
        display:none;
    }
}
.scroll-hint__inner  {
    display: inline-block;
    white-space: nowrap;
    padding:0;
    font-size: .925rem;
    color:var(--color-mid-grey);
    font-family: var(--font-default);
    transform-origin: left center;
    transform: rotate(90deg) translate(-50%,-50%);
    z-index:850;
    pointer-events:initial;
}
.scroll-hint__inner > .icon  {
    position: absolute;
    right: -1rem;
    top: .5rem;
    font-size: .375rem;
    transform: rotate(-90deg);
    color: var(--color-mid-grey);
}
.scroll-hint__inner:before  {
    content:'';
    position: absolute;
    left: -5rem;
    top: .75rem;
    width:4.375rem;
    height:1px;
    background: var(--color-mid-grey);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.59);
}
