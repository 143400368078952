.sticky-contact{
    background-color: var(--color-white);
    position: fixed;
    top: 0;
    left:-4rem;
    border-bottom: 8px solid var(--color-primary);
    transform: skewX(-45deg);
    padding:0 3rem;
}

.sticky-contact__list{
    padding: 1rem 0 0.75rem 0;
    font-family: var(--font-default-bold);
    color: var(--color-secondary);
    font-size: 1rem;
    transform: skewX(45deg);
}