.eem-box {
    background: #fff;
    padding:.5rem;
    box-shadow: 0 0 4px 0 rgba(62,54,63,0.1);
    margin-bottom:.5rem;
    @media screen and (min-width: 768px) {
        margin-bottom:.75rem;
    }
}

.eem-box__add-link {
    display: block;
    font-size:1rem;
    color:var(--color-text-muted);
    background: #fff;
    padding:.5rem;
    box-shadow: 0 0 4px 0 rgba(62,54,63,0.1);
    margin-bottom:.5rem;
    @media screen and (min-width: 768px) {
        margin-bottom:.75rem;
    }
}

.eem-subline {
    color:var(--color-text-muted);
    margin-bottom:.5rem;
}
.eem-fieldcollection {
    margin-bottom:2rem;
    border-bottom:1px solid var(--color-text-muted);
    padding-bottom:2rem;
}

.eem-content-headline {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-family: var(--font-default-bold);
}

.eem-preview {
    font-size:1rem;
    position: relative;
    top:-.25rem;
    margin-left:.5rem;
}
.eem-preview .icon {
    position: relative;
    top:.25rem;
    margin-right:.5rem;
    font-size:1.75rem;
}


/* controls */
.eem-controls {
    margin-bottom:1rem;
}
.eem-control-btn {
    color: var(--color-text-default);
    font-size: .75rem;
    border-radius: 5rem;
    font-family: var(--font-default);
    padding: .25rem .75rem;
    background-color: #ddd;
}
.eem-control-btn .icon {
    position: relative;
    top: .125rem;
}
.eem-control-btn__add {
    color: #fff !important;
    background:var(--color-success);
}
.eem-control-btn__add .icon {
    transform: rotate(45deg);
    font-size: .625rem;
    top: 0;
}
.eem-control-btn__delete {
    color: #fff !important;
    background:var(--color-danger);
}
.eem-control-btn__down .icon,
.eem-control-btn__up .icon {
    font-size: .5rem;
    top: 0;
}
.eem-control-btn__up .icon {
    transform: rotate(180deg);
    top: -.125rem;
}



/* teaser */
.eem-teaser {
    background: #fff;
    padding:.375rem;
    box-shadow: 0 0 4px 0 rgba(62,54,63,0.1);
    margin-bottom:.5rem;
    font-size:.875rem;
    color:var(--color-text-muted);
    @media screen and (min-width: 768px) {
        margin-bottom:.75rem;
    }
}
.eem-teaser__headline {
    text-transform: uppercase;
    font-size:1rem;
}



/* dropdown */
.dropdown-item--eem,
.dropdown-item--eem:hover,
.dropdown-item--eem:focus {
    color:var(--color-text-default);
}


/* dashboard */
.eem-dashboard {
    position: relative;
    margin-bottom:.375rem;

    @media screen and (min-width: 768px) {
        margin-bottom:1rem;
    }
}
.eem-dashboard:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity: 0.45;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    z-index:1;
    transition: all .2s linear;
}
.eem-dashboard:focus:after,
.eem-dashboard:hover:after {
    opacity: 0.66;
}
.eem-dashboard__body {
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    right:0;
    padding:0 1rem;
    text-align: center;
    color:#fff;
    text-shadow: 0 0 10px rgba(0,0,0,.25);
    z-index:5;
}
.eem-dashboard__icon {
    font-size:1.5rem;

    @media screen and (min-width: 768px) {
        font-size:3.5rem;
    }
}
.eem-dashboard__hl {
    @media screen and (min-width: 768px) {
        margin-top:.25rem;
    }
}
.eem-teaser__state{
    position: absolute;
    bottom: 0;
    right: 6px;
}
.eem-state__active{
    color: var(--color-primary)
}
.eem-state__inactive{
    color: #e88300;
}