/*
    SEE ALSO NAV-INLINE.CSS
 */

/* base stylings */
.nav-header,
.navbar a {
    color:var(--color-dark-grey);
    font-family: var(--font-default-bold);
    display: block;
}
.nav-header,
.navbar a.nav-link {
    position: relative;
    font-size: 1rem;
    margin-right:4.5rem;
    padding:.75rem 1rem .75rem 2.125rem;

    @media screen and (min-width: 768px) and (max-height: 800px) {
        padding-top:.5rem;
        padding-bottom:.5rem;
    }

    @media screen and (max-width: 767px) {
        padding: .625rem 1.625rem;
        margin-right:0;
    }

    @media screen and (min-width: 1600px) {
        font-size: 1.125rem;
    }
}
.navbar a.nav-link--home {
    margin-bottom:1rem;
    color:var(--color-dark-grey);
    font-family: var(--font-default);
}
.navbar a.nav-link--xs-searchlink,
.navbar a.nav-link--xs-contactlink {
    display:none;
    margin-left:1.25rem;
    color:var(--color-dark-grey);
    font-family: var(--font-default);
}

@media screen and (max-width: 767px) {
    .navbar a.nav-link--xs-searchlink,
    .navbar a.nav-link--xs-contactlink,
    .navbar a.nav-link--home {
        display: inline-block;
    }
}
.nav-header {
    font-size: 1.25rem;
    margin-bottom: .75rem;
}
.navbar a.nav-link:hover,
.navbar a.nav-link:focus,
.navbar li.active>a {
    color: var(--color-primary);
}
.navbar li.active>a>.nav-plus {
    transform: rotate(45deg);
    color: var(--color-primary) !important;
}
@media screen and (min-width: 768px) {
    .navbar li.has-children.active>a:after {
        content:'';
        display: block;
        position:absolute;
        right:-6rem;
        top:50%;
        transform: translateY(-50%);
        width:60px;
        height:8px;
        background:url(/static/img/hl-pattern-primary.svg) 0 0 no-repeat; /* todo grafik neu */
        background-size:contain;
        pointer-events: none;
        z-index: 50;
    }
}
.navbar .nav-plus {
    position: absolute;
    right:-2.5rem;
    top:.375rem;
    line-height:1px;
    display: block;
    padding: 1rem .75rem;
    font-size: 1.5rem;
    cursor:pointer;
    color:var(--color-dark-grey) !important;
    @media screen and (max-width: 767px) {
        position:absolute;
        float:right;
        right:1rem;
        top:50%;
        font-size: 1.625rem;
        transform: translateY(-50%);
    }
    /*border:1px solid;*/
}

.navbar a.nav-link:has(> .nav-plus)  {
    @media screen and (max-width: 767px){
        padding-right: 4rem;
    }
}

.navbar .nav-plus:hover {
    color:var(--color-primary) !important;
}
/* 1st level */
.navbar>nav>ul {
    padding-left:6rem;
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    width:calc(300rem/16);

    @media screen and (min-width: 768px) and (max-height: 800px) {
        top:45%;
    }
    @media screen and (min-width: 1200px) {
        padding-left:6rem;
        width:calc(400rem/16);
    }
    @media screen and (min-width: 1600px) {
        padding-left:9rem;
        width:calc(500rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-left:0;
        width:100%;
        top:5rem;
        transform: translateY(0);
    }
}
.navbar>nav>ul:before {
    content:'';
    position: absolute;
    left:0;
    top:-1500px;
    bottom:-1500px;
    right:0;
    width:auto;
    height:auto;
    background-color: #EBEAEB;
    z-index:-1;

    @media screen and (min-width: 768px) {
        box-shadow: 0 2px 14px 0 rgba(67,79,41,0.1);
    }
}
.navbar>nav>ul ul {
    display: none;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    @media screen and (min-width: 768px) {
        width:calc(200rem/16);
        top: 50%;
        transform: translateY(-50%);
    }
    @media screen and (min-width: 1200px) {
        width:calc(250rem/16);
    }
    @media screen and (min-width: 1600px) {
        width:calc(340rem/16);
    }
}



/* 2nd level */
.navbar>nav>ul>li.active>ul {
    display: block;
    left:0;
    z-index:10;
    @media screen and (min-width: 768px) {
        left:100%;
    }
}
.navbar>nav>ul>li.active>ul:before {
    content:'';
    position: absolute;
    left:0;
    top:-500px;
    bottom:0;
    right:0;
    width:auto;
    height:auto;
    background-color: #EBEAEB;
    z-index:-1;
    @media screen and (min-width: 768px) {
        background-color: #F5F4F5;
        bottom:-1500px;
        box-shadow: 0 2px 14px 0 rgba(67,79,41,0.1);
    }
}


/* 3rd level */
.navbar>nav>ul ul>li.active>ul {
    display: block;
    left:0;
    z-index:20;
    @media screen and (min-width: 768px) {
        left:100%;
    }
}
.navbar>nav>ul ul>li.active>ul:before {
    content:'';
    position: absolute;
    left:0;
    top:-500px;
    bottom:0;
    right:0;
    width:auto;
    height:auto;
    background-color: #EBEAEB;
    z-index:-1;
    @media screen and (min-width: 768px) {
        background-color: #F5F4F5;
        bottom:-1500px;
        box-shadow: 0 2px 14px 0 rgba(67,79,41,0.1);
    }
}



/* mobile stuff */
@media screen and (max-width: 767px) {
    .navbar.is-open {
        height:100vh;
        overflow: hidden;
        padding-bottom:4rem;
    }
    .navbar.is-open nav {
        height:calc(100vh - 4rem);
        overflow-y: scroll;
    }
    .navbar>nav>ul ul>li.active>ul,
    .navbar>nav>ul>li.active>ul {
        height:calc(100vh - 4rem);
        overflow-y: scroll;
        overflow-x: hidden;
    }
    li.has-children.active ~ li {
        display: none;
    }
    .navbar>nav>ul>li.active>ul a.nav-link {
        background: #F5F5F5;
        font-family: var(--font-default);
        padding-left:2rem;
    }
    .nav-item--header + .nav-item>.nav-link {
        padding-top:1.5rem;
    }
    .navbar li.active>ul>.nav-item:last-child>.nav-link {
        padding-bottom:5rem;
    }
    .navbar a.nav-link.nav-link--back {
        text-align: center;
        color:#fff !important;
        font-family: var(--font-default-bold)!important;
        padding: 1rem 1.5rem;
        font-size: .925rem;
        margin-bottom:.75rem;
    }
    .nav-link--back>.icon {
        position: relative;
        margin-right: .375rem;
        margin-left:-.75rem;
        top:.125rem;
    }
}