.video-js .vjs-big-play-button.vjs-big-play-button,
.play-btn{
    border-radius: 50%;
    padding: .125rem 0 0 .25rem;
    border: none;
    font-size: 1rem;
    height: 60px;
    width: 60px;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255,.8);
    text-align: center;
}
.play-btn {
    display: block;
}
.video-js:hover .vjs-big-play-button.vjs-big-play-button,
.play-btn:hover{
    background: rgba(255,255,255,1);
}
.video-js .vjs-big-play-button:before,
.play-btn:before {
    content: var(--icon-play);
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: 400;
    color:var(--color-primary);
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button .vjs-icon-placeholder {
    display: none;
}



/* mute */
.video-mute__btn {
    color:#fff;
    display: inline-block;
    position: absolute;
    right:1.875rem;
    bottom:1.5rem;
    width: 1.5rem;
    height:1.5rem;
}
.video-mute__btn .icon {
    display: inline-block;
    font-size:1.5rem;
    position: absolute;
    right:0;
    top:0;
    text-shadow: 0 0 5px rgba(0,0,0,.5);
}
.video-mute__btn .icon-mute {
    right:-.25rem;
}
