.zoom-button {
    width: calc(45rem/16);
    height:calc(45rem/16);
    background-color:#fff;
    border: 1px solid var(--color-primary);
    font-size:calc(16rem/16);
    position:absolute;
    top: calc(40rem/16);
    left: calc(40rem/16);
    display:flex;
    align-items:center;
    justify-content:center;
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        top: calc(10rem/16);
        left:calc(10rem/16);
    }
}
.zoom-button .icon-minus {
    font-size:3px;
}
.zoom-button + .zoom-button {
    top: calc(90rem/16);
    @media screen and (max-width: 767px) {
        top: calc(65rem/16);
    }
}