/* base styles */
.text-teaser {
    position: relative;
    height:calc(100% - .75rem);
    margin-bottom:.75rem;
}
.text-teaser__body {
    padding:1.125rem 1rem 1rem 1rem;
}
@media screen and (min-width: 768px) {
    .text-teaser__body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding:1.5rem 1.875rem 1.875rem 1.875rem;
        height:100%;
    }
    .text-teaser__body-top {
        justify-content: normal;
    }
    .text-teaser__body-bottom {
        align-self: flex-end;
        width: 100%;
    }
}
.text-teaser__hl {
    line-height:1;
    margin-bottom:1.125rem;
    position: relative;
    font-family: var(--font-special);
}
.text-teaser__hl--canceled>a {
    text-decoration: line-through;
    color:var(--color-danger);
}
.text-teaser__decorator{
    width:10rem;
    height:.75rem;
    margin-bottom:1rem;
    background:url(/static/img/hl-pattern-dark.svg) 0 0 no-repeat;
}
.text-teaser--primary .text-teaser__decorator {
    background:url(/static/img/hl-pattern-white.svg) 0 0 no-repeat;
}
.text-secondary .text-teaser__decorator {
    background:url(/static/img/hl-pattern-secondary.svg) 0 0 no-repeat;
}


/* white */
.text-teaser--white {
    color:var(--color-text-default);
    background: #fff;
}

/* primary */
.text-teaser--primary{
    color:#fff;
    background: var(--color-primary);
}





/* with image */
.text-teaser__img {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size:cover;
    overflow:hidden;
}