:root {
    --color-primary: #B1C200;
    --color-secondary: #434F29;
    --color-booking: #F85A3E;
    --color-text-default: #3E363F;
    --color-text-muted: #8E8E8D;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #ececec;
    --color-grey: #dddddd;
    --color-dark-grey: var(--color-text-muted);
    --color-mid-grey: #f5f5f5;
    --color-dark: var(--color-text-default);

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-dark-medium-light: #E6E6E6; /*demi*/
    --color-dark-medium: #8C8C8C; /*demi*/
    --color-progress: var(--color-secondary);

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #98a900;
    --color-secondary-dark: #3b4723;
    --color-booking-dark: #c44f34;
    --color-star-rating: #FABB21;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #3A88C4;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Ubuntu_Regular", sans-serif;
    --font-default-bold: "Ubuntu_Bold", sans-serif;
    --font-default-bold-extended: "Ubuntu_Bold_Ext", sans-serif;
    --font-default-medium: "Ubuntu_Regular", sans-serif;
    --font-special: "LobsterTwo_BoldItalic", sans-serif;
    --font-kievit-slab: "Kievit_Slab", sans-serif;

    --font-size-default: 1rem;
}
