.breadcrumb-wrapper {
    position: relative;
    font-size:.875rem;
    margin-top:3rem;
    @media screen and (max-width: 767px) {
        margin-top:1rem;
        margin-bottom:-1.5rem;
    }
}
.breadcrumb {
    padding: .75rem 0;
    background:transparent;

    @media screen and (max-width: 767px){
        display:block;
        position: relative;
        padding:10px 0;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        z-index:5;
    }
}
.breadcrumb-item a {
    color:var(--color-primary);
    font-size:.875rem;
    text-decoration: none;
}
.breadcrumb-item a:hover,
.breadcrumb-item a:focus {
    text-decoration: underline;
}
.breadcrumb-item.active {
    color:var(--color-text-muted);
}
.breadcrumb-item{
    position: relative;
    @media screen and (max-width: 767px){
        float:none;
        display: inline-block;
    }
}
.breadcrumb-item+.breadcrumb-item{
    padding-left:1.75rem;
}
.breadcrumb-item+.breadcrumb-item:before {
    position: absolute;
    left:.75rem;
    top:.125rem;
    transform: rotate(16deg);
    letter-spacing: -1px;
    padding: 0;
    font-size:.625rem;
    color:var(--color-primary);
    content: "//";
}

.breadcrumb-item__back {
    font-size: calc(10rem/16);
}