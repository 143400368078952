.info-badge {
    position: absolute;
    right: calc(-8rem / 16);
    top: calc(20rem / 16);
    font-size: calc(14rem / 16);
    line-height: 1;
    padding: calc(10rem / 16) calc(30rem / 16);
    max-width: calc(162rem / 16);
    background: var(--color-primary);
    color: var(--color-secondary);
    border-radius: calc(8rem / 16) calc(8rem / 16) 0 calc(8rem / 16);
    box-shadow: 0 calc(4rem / 16) calc(12rem / 16) 0 rgba(67,79,41,0.3);

    @media screen and (min-width: 1400px) {
        top: calc(30rem / 16);
        max-width: calc(227rem / 16);
        padding: calc(12rem / 16) calc(40rem / 16);
        font-size: calc(18rem / 16);
        right: calc(-20rem / 16);
    }
}
.info-badge--sm {
    padding: calc(10rem / 16) calc(15rem / 16);
    max-width: calc(124rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(140rem / 16);
    }
}

.info-badge.info-badge--xs {
    padding: calc(6rem / 16) calc(12rem / 16);
    max-width: calc(124rem / 16);
    font-size: calc(12rem /16);
    line-height: 1;
    font-family: var(--font-default-bold);
    right: calc(-10rem / 16);
    z-index: 1;
    @media screen and (min-width: 768px) {
        max-width: calc(168rem / 16);
    }
}
.info-badge.info-badge--xs:after {
    bottom: -.5rem;
    border-width: 0.5rem 0.675rem 0 0;
    border-color: #5A6E2F transparent transparent transparent;
}
.info-badge:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: calc(-10rem / 16);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(10rem / 16) calc(8rem / 16) 0 0;
    border-color: var(--color-secondary) transparent transparent transparent;

    @media screen and (min-width: 1400px) {
        bottom: calc(-14rem / 16);
        border-width: calc(14rem / 16) calc(20rem / 16) 0 0;
    }
}
.info-badge__icon {
    font-size: calc(22rem / 16);
    margin-right: calc(13rem / 16);
    position: relative;
    top: calc(3rem / 16);

    @media screen and (min-width: 1400px) {
        font-size: calc(30rem / 16);
    }
}
.info-badge--dark{
    background: #1d1d1b;
    color: #fff;
}
.info-badge.info-badge--xs.info-badge--dark:after{
    border-color: #000 transparent transparent transparent;
}
.info-badge--container{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(10rem / 16);
    right: calc(-10rem / 16);

    .info-badge{
        position: relative;
        top: unset;
        right: 0;
        margin-bottom: calc(10rem/16);
        min-height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}