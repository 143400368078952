.header-slider-main {
    position: relative;
}
.header-slider__arrows {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding: .375rem 3rem;
    background: #fff;
    z-index: 1;
    font-size: .875rem;
}