.form-group {
    position: relative;
    margin-bottom:1.125rem;
    @media screen and (min-width: 768px) {
        margin-bottom:1.5rem;
    }
}
.form-group--floating {
    margin-bottom:1.75rem;
    @media screen and (min-width: 768px) {
        margin-bottom:2.75rem;
    }
}
.form-group--range-filter {
    margin-bottom:2.5rem;
    @media screen and (min-width: 768px) {
        margin-bottom:3.125rem;
    }
}
.form-group--lg {
    background: #fff;
    border: 1px solid #DDDDDD;
    margin-bottom:.375rem;
    @media screen and (min-width: 768px) {
        margin-bottom:.75rem;
    }
}
.form-group--lg:not(.form-group--lg-has-dropdown) {
    padding:.375rem .75rem .25rem .75rem;

    @media screen and (min-width: 768px) {
        padding:1rem 1.25rem 1.25rem 1.25rem;
    }
}
.form-group-inline {
    display: inline-block;
}
.form-group.form-group--with-icon .icon {
    position: absolute;
    right:.75rem;
    top:.75rem;
    font-size:1.125rem;
    pointer-events: none;
}
.form-group.form-group--with-icon-left .icon {
    right:auto;
    left:.75rem;
}
.form-group.form-group--with-icon .form-control{
    padding-right:1.75rem;
}
.form-group.form-group--with-icon-left .form-control{
    text-indent:2.25rem;
    padding-right:0;
}
.form-control--narrow {
    width:100%;
    max-width:220px;
}
.form-control--extra-narrow {
    width:100%;
    max-width:90px;
}
.form-control,
.form-control:focus {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem 0;
    font-size:1rem;
    border:0;
    border-radius: 0;
    border-bottom:1px solid var(--color-secondary);
    background: transparent;
}
.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-invalid, .has-error .form-control {
    background: transparent;
}
.form-group--white,
.form-group--white .form-control,
.form-group--white .form-control-label {
    color:#fff;
    border-color:#fff;
}
textarea.form-control,
textarea.form-control:focus {
    height:auto;
    padding: .375rem .75rem;
    border:1px solid var(--color-secondary);
}
/*.form-group--floating textarea.form-control,
.form-group--floating textarea.form-control:focus {
    border-top:.375rem solid transparent;

    @media screen and (min-width: 768px) {
        border-top:1rem solid transparent;
    }
}*/
.demi-additionalservice__filterbox label,
.form-control-label--small  {
    color:var(--color-text-default);
    font-size: .75rem;
    top: 5px;
    position: relative;
}
.form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}
.form-control-label span {
    color:var(--color-primary);
}
.has-error .form-control {
    border-color:var(--color-danger);
}

select.form-control {
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    cursor: pointer;
}
select.form-control,
select.form-control:focus {
    padding-right:1.625rem;
}

.filter-dd-toggle {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.625rem 0 0;
    font-size:1rem;
    display: block;
    font-family: var(--font-default);
    border-bottom: 1px solid var(--color-secondary);
}
.select:after,
.filter-dd-toggle:after {
    pointer-events: none;
    content:var(--icon-arrow-down);
    font-family: iconfont;
    font-size:.375rem;
    position: absolute;
    right: .25rem;
    top: .75rem;
    border:0;
}

.form-group--lg.select:after{
    position: relative;
    right: 1rem;
    top: -.25rem;
    pointer-events:none;
}

.dropdown-menu.dropdown-menu--filter {
    padding:.25rem 1rem 0 1rem;
    background: var(--color-mid-grey);
    border-radius: 0;
    right:0;
}

.remove-link {
    position: absolute;
    left: -.5rem;
    top: -.5rem;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.25rem;
    display: inline-block;
    color: #fff;
    font-family: var(--font-default-bold);
    background: var(--color-secondary);
    border-radius: 50%;
    z-index: 5;
}
.remove-link--right {
    left:auto;
    right: -.5rem;
}
.remove-link>.icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
    font-size: .625rem;
}
.remove-link:focus,
.remove-link:hover {
    color: #fff;
    background: var(--color-text-default);
}

/* form large + dropdown in forms */
.dropdown-toggle__form {
    display: block;
    padding: .375rem .5rem .25rem;
    text-align: center;
    font-family: var(--font-default-bold);
    font-size: 1rem;
    color:var(--color-secondary);

    @media screen and (min-width: 768px) {
        font-size: 1.75rem;
        padding:1rem;
    }
}
.form-group--lg .form-control.hasDatepicker,
.form-group--lg select.form-control {
    font-family: var(--font-default-bold);
    font-size: 1rem;
    color:var(--color-secondary);
    border:0;
    padding:0 1.25rem;
    text-align: center;
    height: calc(1.125em + 0.1875rem + 2px);
    display: inline-block;
    width: auto;

    @media screen and (min-width: 768px) {
        font-size: 1.75rem;
    }
}
.form-group--lg select.form-control.select--medium {
    @media screen and (min-width: 768px) {
        font-size: 1.125rem;
    }
    @media screen and (min-width: 1400px) {
        font-size: 1.25rem;
    }
}
.form-group--lg .form-control.hasDatepicker {
    display: block;
    width: 100%;
}
.dropdown-toggle__form:after{
    display: none !important;
}
.dropdown-toggle__form-dd {
    position: relative;
    display: inline-block;
    padding-right:1.5rem;
}
.dropdown-toggle__form-dd:before{
    content:'';
    position: absolute;
    top:.5rem;
    right:0;
    width:8px;
    height:8px;
    border:2px solid;
    border-width:0 2px 2px 0;
    transform: rotate(45deg);
    transition: 0.15s linear;
    @media screen and (min-width: 768px) {
        top:1rem;
    }
}
/*.show .dropdown-toggle__form-dd:before{
    transform: rotate(225deg);
}*/
.form-control-label--large,
.dropdown-toggle__form-label {
    display: block;
    font-size: .875rem;
    font-family: var(--font-default);
    color:var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        margin-bottom:.5rem;
        font-size: .9375rem;
    }
}
.dropdown-menu.dropdown-menu--form {
    padding: 1.25rem 1.75rem;
    margin:0;
    border-radius:0;
    border: 1px solid #DDDDDD;
    left:-1px !important;
}
.dropdown-menu.dropdown-menu--form li {
    margin:.625rem 0;
}
.dropdown-menu.dropdown-menu--form .dropdown-divider {
    margin: .5rem -1.75rem 1rem -1.75rem;
}



/* filter */
.subcategory-checkbox {
    display: inline-block;
    min-width:215px;
    white-space: nowrap;
    margin-left:1rem;
}

/* EEM Forms */
.form-group.form-group--eem {
    margin: 0;
}

textarea.form-control--eem,
textarea.form-control--eem:focus,
.form-control--eem,
.form-control--eem:focus,
.form-control--eem:disabled,
.form-control--eem[readonly] {
    border:1px solid #DDDDDD;
    font-size:1rem;
    padding:1.25rem .75rem;
    color:var(--color-text-muted);
    background-color: #F5F5F5;
}
textarea.form-control--eem,
textarea.form-control--eem:focus {
    padding:.5rem .75rem;
}
textarea.form-control--eem:focus,
.form-control--eem:focus {
    background-color: #fff;
}
select.form-control.form-control--eem {
    padding:.25rem .75rem;
    height:2.5rem;
}
.form-control--eem-download,
.form-control--eem-download:focus {
    padding:.5rem .75rem;
    height:3rem;
}
.form-control--eem:focus {
    color:var(--color-text-default);
}
.form-control-label--eem {
    margin:.125rem 0 .375rem 0;
    color:var(--color-text-muted);
}
.eem-box textarea.form-control--eem{
    display: block !important;
    opacity: 0;
    height: 0;
    position: absolute;
}
.form-control.is-valid ~ .note-editor.note-frame{
    border-color: var(--color-success);
}
.form-control.is-invalid ~ .note-editor.note-frame{
    border-color: var(--color-danger);
}
.form-group.form-group--eem.is-invalid{
    margin-bottom: calc(20rem/16);
}
.js-datepicker.is-valid .form-control--eem{
    border-color: var(--color-success);
}
.js-datepicker.is-invalid .form-control--eem{
    border-color: var(--color-danger);
}