.info-circle__circle{
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
    margin: 0 auto;

    @media screen and (min-width: 768px){
        width: 5rem;
        height: 5rem;
    }
}

.live__element__circle{
    width: 3rem;
    height: 3rem;
    position: relative;
    margin: 0 auto;

    @media screen and (min-width: 768px){
        width: 4rem;
        height: 4rem;
    }
}