.info-alert {
    background: #fff;
    box-shadow: 0 calc(20rem / 16) calc(50rem / 16) 0 rgba(67,79,41,0.3);
    position: fixed;
    right: calc(16rem / 16);
    bottom: calc(20rem / 16);
    padding: calc(40rem / 16) 0 calc(20rem / 16);
    width: 90%;
    max-width: calc(323rem / 16);
    font-size: calc(14rem / 16);
    line-height: calc(24/18);
    transform: translateX(100%);
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    display: block;
    border-radius: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        width: 100%;
        right: calc(40rem / 16);
        bottom: calc(50rem / 16);
        padding: calc(35rem / 16) 0 calc(30rem / 16);
        max-width: calc(550rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(18rem / 16);
        max-width: calc(676rem / 16);
    }
}
.info-alert.is-active {
    opacity: 1;
    transform: translateX(0);
    z-index: 801;
}
.info-alert__text {
    margin-top: calc(30rem / 16);
}
.info-alert__head,
.info-alert__text {
    padding: 0 calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(60rem / 16);
    }
}
.info-alert__head.info-alert__head {
    padding-right: 0;
}
.info-alert__close {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: 0;
    color: var(--color-grey);
    font-size: calc(13rem / 16);
    padding: calc(10rem / 16);
}
.info-alert__title {
    color: var(--color-primary);
    font-size: calc(20rem / 16);
    line-height: calc(44/30);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(32rem / 16);
    }
}
.info-alert .info-badge {
    position: relative;
    top: 0;
    right: 0;
    margin-right: calc(-8rem / 16);

    @media screen and (min-width: 1400px) {
        margin-right: calc(-20rem / 16);
    }
}