/* Copied from DeMI. Customizations in step-progress-custom.css */
.step-progress__bar {
    display: flex;
}
.step-progress__bar-item {
    height: 100%;
    flex: 1 1 0;
}

@media (max-width: 767px) {
    .step-progress {
    }
    .step-progress__icon {
        font-size: 14px;
        vertical-align: -.1em;
    }
    .step-progress__bar {
        display: flex;
        height: 5px;
        background-color: var(--color-dark-medium-light);
        border-radius: 5px;
        overflow: hidden;
    }
    .step-progress__bar-item {
        height: 100%;
        flex: 1 1 0;
    }
    .step-progress__bar-item + .step-progress__bar-item {
        border-left: 1px solid var(--color-dark-medium);
    }
    .step-progress__bar-item.is-active {
        background: var(--color-success);
        height: 100%;
        border-left: none;
    }
}

@media (min-width: 768px) {

    .step-progress__icon-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .step-progress__icon-number {
        font-size: 16px;
    }

    .step-progress__icon {
        font-size: 14px;
        vertical-align: -.1em;
        background: var(--color-dark-medium-light);
        border-radius: 50%;
        height: 30px;
        width: 30px;
        position: relative;
        margin: 0 auto;
        z-index: 1;
        font-weight: var(--font-default-medium-weight);
    }

    .step-progress__bar-item {
        position: relative;
        text-align: center;
    }

    .step-progress__bar-item:after {
        content: "";
        background-color: var(--color-dark-medium-light);
        height: 3px;
        width: 100%;
        position: absolute;
        right: 50%;
        top: 14px;
    }

    .step-progress__bar-item.is-done .step-progress__icon {
        background-color: var(--color-success);
    }
    .step-progress__bar-item.is-done .step-progress__text {
        color: var(--color-success);
    }

    .step-progress__bar-item.is-active .step-progress__icon {
        background-color: var(--color-primary);
        color: white;
    }
    .step-progress__bar-item.is-active .step-progress__text{
        color: var(--color-primary);
    }

    .step-progress__bar-item.is-done:after {
        background-color: var(--color-success);
    }

    .step-progress__bar-item.is-active:after {
        background: linear-gradient(90deg, var(--color-success) 0%, var(--color-primary) 100%);
    }

    .step-progress__bar-item:first-child:after {
        display: none;
    }

    .step-progress__text {
        font-size: 16px;
        margin-top: 10px;
        font-weight: var(--font-default-medium-weight);
        color: var(--color-dark-medium);
    }
}