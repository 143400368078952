.navbar {
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    margin:0;
    padding:0;
    display: block;
    background: rgba(255,255,255,.5);
    z-index: 900;
    transform: translateX(-100%);
}
.navbar.is-open {
    transform: translateX(0%);
}