.icon-teaser {
    position: relative;
    margin-bottom:.375rem;

    @media screen and (min-width: 768px) {
        margin-bottom:.75rem;
    }
}
.icon-teaser:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity: 0.45;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    z-index:1;
    transition: all .2s linear;
}
.icon-teaser:focus:after,
.icon-teaser:hover:after {
    opacity: 0.66;
}
.icon-teaser__body {
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    right:0;
    padding:0 1rem;
    text-align: center;
    color:#fff;
    text-shadow: 0 0 10px rgba(0,0,0,.25);
    z-index:5;
}
.icon-teaser__icon {
    font-size:1.5rem;

    @media screen and (min-width: 768px) {
        font-size:5rem;
    }
}
.icon-teaser__hl {
    font-size:1.25rem;
    @media screen and (min-width: 768px) {
        margin-top:1.5rem;
    }
    @media screen and (min-width: 1200px) {
        font-size:3rem;
    }
}