.geolocation-nav__item {
    width: 20%;
}
.geolocation-tabs .geolocation-nav .geolocation-tabs__link {
    line-height: 1.2;
    @media screen and (min-width: 768px) {
        font-size: 1rem;
    }
}

.geolocation-nav__select .form-control {
    font-family: var(--font-default-bold);
    font-weight: 500;
    border-bottom: 0.25rem solid var(--color-primary);
    background-color: white;
    padding: calc(10rem/16);
    height: 100%;
}
.geolocation-nav__select .form-control:focus, .geolocation-nav__select .form-control:active {
    border-bottom: 0.25rem solid var(--color-primary);
    background-color: white;
}

.geolocation-nav__select:after {
    content: '';
}
.geolocation-nav__select select.form-control {
    appearance:auto;
}

.geolocation-nav__select .form-control option {
    font-family: var(--font-default);
    font-weight: 300;
    background-color: white;
    color: black;
}
.geolocation-nav__select .form-control option:active, .geolocation-nav__select .form-control option:focus {
    background-color: white;
    color: var(--color-default);
}

.geolocation-nav__label {
    position: relative;
}
.geolocation-nav__radio:checked + .geolocation-tabs__link-border {
    content:'';
    display: block;
    position: absolute;
    bottom:0;
    right:0;
    left:0;
    color: var(--color-dark-dark);
    border-bottom: 0.25rem solid var(--color-primary);
}