/* base styles */
.classic-teaser {
    position: relative;
    height:calc(100% - .75rem);
    margin-bottom:.75rem;
    background:#fff;
    overflow:hidden;
}
.classic-teaser__body {
    padding:1.125rem 1rem 1rem 1rem;
}
.classic-teaser--angebote .classic-teaser__body-top {
    margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
    .classic-teaser__body {
        padding:1.5rem 1.5rem 4rem 1.5rem;
    }
    .classic-teaser__body--orders{
        padding: 1.5rem 1.5rem 2rem;
    }
    .classic-teaser__body-top {
        margin-bottom: 2.5rem;
    }
    .classic-teaser__body-bottom {
        position: absolute;
        left:1.5rem;
        right:1.5rem;
        bottom:1.5rem;
        width: auto;
    }
    .classic-teaser--angebote .classic-teaser__body-top {
        margin-bottom: 2rem;
    }
}

.classic-teaser__body--orders{
    padding: 1.125rem 1rem 1rem 1rem;
}
.classic-teaser__hl {
    line-height:1;
    margin-bottom:.875rem;
    position: relative;
    font-family: var(--font-special);
}
.classic-teaser__decorator{
    width:10rem;
    height:.75rem;
    margin-bottom:1.25rem;
    background:url(/static/img/hl-pattern-dark.svg) 0 0 no-repeat;
}
