.contwise-maps {
    scroll-margin: 4.125rem;
    width: 100%;
    min-height: 50vh;
}

@media screen and (min-width: 768px) {
    .contwise-maps {
        min-height: 0;
        aspect-ratio: 1.2;
    }
}
.contwise__item-list-scroll-container {
    min-height: 75vh;
}

@media screen and (min-width: 768px) {
    .contwise__item-list-scroll-container {
        min-height: 0;
    }
}