.wysiwyg,
.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    line-height:1.75;
    margin-bottom: 1.75rem;

    @media screen and (min-width: 768px) {
        font-size:1.0625rem;
    }
}
.wysiwyg.lead,
.wysiwyg.lead > p,
.wysiwyg.lead > ol,
.wysiwyg.lead > ul,
.wysiwyg.lead > table {
    font-size:1.0625rem;
    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}
/*.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}*/
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.modal-body a:not(.btn),
.wysiwyg a:not(.btn) {
    color: var(--color-primary);
    text-decoration: underline;
}
.modal-body a:not(.btn):hover,
.modal-body a:not(.btn):focus,
.modal-body a:not(.btn):active,
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
    color: var(--color-text-default);
    text-decoration: underline;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5 {
    margin-top:1.5rem;
    margin-bottom:.875rem;

    @media screen and (min-width: 768px) {
        margin-top:2.5rem;
        margin-bottom:1rem;
    }
}


.wysiwyg h1 {
    font-size: calc(40rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
    }
}

.wysiwyg h2 {
    font-size: calc(30rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
    }
}

.wysiwyg h3 {
    font-size: calc(20rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(26rem/16);
    }
}

.wysiwyg h4 {
    font-size: calc(18rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(22rem/16);
    }
}

.wysiwyg ul>li {
    margin:.375rem;
    margin-left:1rem;
    padding-left:1rem;
    list-style: none;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.wysiwyg ul>li:before {
    content:'';
    position: absolute;
    left:-.75rem;
    top:.675rem;
    display: block;
    width:8px;
    height:8px;
    background: var(--color-primary);
    border-radius: 50%;
}

.wysiwyg ol>li {
    margin:.375rem;
    margin-left:1rem;
    padding-left:1rem;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}

.wysiwyg--multicol{
    @media screen and (min-width: 768px){
        column-count: 2;
        column-gap: 4rem;
    }
}
