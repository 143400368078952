.watchList-btn{

}
.teaser .watchList-btn,
.classic-teaser .watchList-btn,
.text-teaser .watchList-btn{
    position: absolute;
    left:0;
    top:0;
    padding:1.125rem 1.875rem;
    font-size: 1.5rem;
    color:#fff;
    z-index:10;
    text-shadow: 0 0 10px rgba(0,0,0,.5);
}
.watchList-btn:hover,
.watchList-btn:focus {
    color:#fff;
}
.teaser .watchList-btn.is-added,
.map-teaser .watchList-btn.is-added{
    color:darkred;
}
.watchList-btn.is-added>.icon:before{
    content:var(--icon-heart-filled);
}

.cart-count:empty,
.watchList-count:empty {
    display: none;
}
.cart-count,
.watchList-count {
    position: absolute;
    right:1.5rem;
    top:2.5rem;
    font-size: .75rem;
    color:#000;
    width:1rem;
    height:1rem;
    line-height:1rem;
    text-align: center;
    border-radius: 50%;
    background:var(--color-light-grey);
    z-index: 10;

    @media screen and (max-width: 767px) {
        top:2rem;
        right:1rem;
    }
}

.watchList-count {
    background-color: transparent;
    right: 1.8rem;
}
.watchList-count:before {
    position: absolute;
    font-family: iconfont;
    content: var(--icon-heart-filled);
    font-size: 16px;
    color: var(--color-primary);
    border: 1px solid white;
    z-index: -1;
    opacity: 0.7;
    margin-left: calc(-2rem/16);
    left: 0;
    top: calc(1rem/16);
}
.watchList-count {
    @media screen and (max-width: 767px) {
        top:.875rem;
        right:.125rem;
    }
}