.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 9999;
    text-align: center;
}

.loading-overlay__gs-map {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 99;
}

.loading-overlay__inner {
    position:absolute;
    left: 50%;
    top:50%;
    width:250px;
    transform: translateY(-50%) translateX(-50%);
}
.loading-overlay--rating {
    position: absolute;
}