/* webfont
   google fonts:
   https://fonts.google.com/specimen/Ubuntu?selection.family=Ubuntu
   https://fonts.google.com/specimen/Lobster+Two?selection.family=Lobster+Two
*/

@font-face {
    font-family: 'Ubuntu_Regular';
    src: url('/static/fonts/subset-Ubuntu-Regular.woff2') format('woff2'),
    url('/static/fonts/subset-Ubuntu-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu_Bold';
    src: url('/static/fonts/subset-Ubuntu-Bold.woff2') format('woff2'),
    url('/static/fonts/subset-Ubuntu-Bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu_Bold_Ext';
    src: url('/static/fonts/ubuntu-bold-webfont-extended.woff2') format('woff2'),
    url('/static/fonts/ubuntu-bold-webfont-extended.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'LobsterTwo_BoldItalic';
    src: url('/static/fonts/subset-LobsterTwo-BoldItalic.woff2') format('woff2'),
    url('/static/fonts/subset-LobsterTwo-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}