body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    font-variant-numeric: lining-nums;
}
b,
strong,
.strong {
    font-family: var(--font-default-bold) !important;
    font-weight: normal;
}

.font-default{
    font-family: var(--font-default) !important;
}
.font-default-bold {
    font-family: var(--font-default-bold) !important;
    font-weight: 600;
}
.font-special {
    font-family: var(--font-special);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    display: block;
    color: inherit;
    font-family: var(--font-special);
}

h1, .h1 {
    font-size: calc(38rem/16);
}
h2, .h2 {
    font-size: calc(32rem/16);
}
h3, .h3 {
    font-size: calc(28rem/16);
}
h4, .h4 {
    font-size: calc(24rem/16);
}
h5, .h5 {
    font-size: calc(20rem/16);
}
h6, .h6 {
    font-size: 1.125rem;
}
.main-title-block .main-title-block__title {
    display: inline-block;
    padding-top:.375rem;
    position: relative;
    border-top:4px solid #ddd;
}
.main-title-block .main-title-block__title:before {
    content:'';
    width: 100vw;
    position: absolute;
    left:0;
    top:-4px;
    border-top:1px solid #ddd;
}
.main-title-block.text-right .main-title-block__title:before {
    left:auto;
    right:0;
}

.title-block__scroll-link:active,
.title-block__scroll-link:focus,
.title-block__scroll-link {
    color: var(--color-primary);
    text-decoration: underline;
    font-family: var(--font-default-bold);
    white-space: nowrap;
}

.title-block__scroll-link:hover {
    text-decoration: none;
    color: var(--color-primary);
}

@media screen and (min-width: 768px) {
    h1, .h1 {
        font-size: calc(60rem/16);
    }
    h2, .h2 {
        font-size: calc(52rem/16);
    }
    h3, .h3 {
        font-size: calc(44rem/16);
    }
    h4, .h4 {
        font-size: calc(32rem/16);
    }
    h5, .h5 {
        font-size: calc(26rem/16);
    }
    h6, .h6 {
        font-size: calc(20rem/16);
    }
    .main-title-block .main-title-block__title {
        padding-top:.375rem;
    }
}


@media screen and (min-width: 1400px) {
    h1, .h1 {
        font-size: calc(80rem/16);
    }
    h2, .h2 {
        font-size: calc(60rem/16);
    }
    h3, .h3 {
        font-size: calc(56rem/16);
    }
}