.gamification-slider__title-block {
    font-family: var(--font-kievit-slab);
}

.gamification-slider--portrait {
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        width: 500px;
    }
}

.gamification-slider {
    margin: 0 auto;
}
.gamification-slider-area.is-finished .gamification-slider__likes {
    opacity: .5;
    cursor: not-allowed;
}
.gamification-slider-area.is-finished .gamification-slider__likes button {
    cursor: not-allowed;
    pointer-events: none;
}
.gamification-slider-area.is-finished .gamification-slider__slider {
    pointer-events: none !important;
}

.gamification-slider__item {
    position: relative;
    opacity: 0;
}
.slick-current .gamification-slider__item {
    opacity: 1;
}

.gamification-slider__body {
    position: absolute;
    color: #FFF;
    bottom: 0;
    padding: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16);
    }
}

.gamification-slider__body--landscape {
    position: absolute;
    color: #FFF;
    bottom: 0;
    left: 0;
    padding: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        bottom: 0;
        width: 50%;
        padding: calc(30rem / 16);
    }
}

.gamification-slider__content {
    line-height: 18px;
    margin-top: 1rem;

    @media screen and (min-width: 768px) {
        line-height: 22px;
        margin-top: 1.4rem;
    }
}

.gamification-slider__controls {
    position: relative;
    width: 100%;
    min-height: 10rem;
    background-color: #ffffff;
    padding: calc(18rem / 16) calc(28rem / 16);
}

.gamification-slider__arrow {
    border-radius: 50%;
    color: var(--color-grey);
    border: 1px solid #DDDDDD;
    right: 3%;
    height: calc(38rem / 16);
    width: calc(38rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(58rem / 16);
        width: calc(58rem / 16);
    }
}

.gamification-slider__arrow.slick-prev {
    right: auto;
    left: 3%;

    @media screen and (min-width: 768px) {
        left: 5%;
    }
}

.gamification-slider__arrow:before {
    font-size: 0.5rem;
    content: var(--icon-arrow-down);
    transform: translateX(-38%) rotate(-90deg);

    @media screen and (min-width: 768px) {
        font-size: 0.7rem;
    }
}

.gamification-slider__arrow.slick-prev:before {
    content: var(--icon-arrow-down);
    transform: translateX(-58%) rotate(90deg);
}

.gamification-slider__likes {
    width: calc(216rem / 16);
    margin: auto;
    position: relative;

    @media screen and (min-width: 768px) {
        width: calc(320rem / 16);
    }
}

.gamification-slider__dislike,
.gamification-slider__like {
    width: calc(70rem / 16);
    height: calc(70rem / 16);
    font-size: calc(35rem / 16);
    border-radius: 50%;
    border: 1px solid var(--color-grey);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    background: transparent;

    @media screen and (min-width: 768px) {
        width: calc(108rem / 16);
        height: calc(108rem / 16);
        font-size: calc(60rem / 16);
    }
}

.gamification-slider__dislike {
    left: calc(27rem / 16);
    color: var(--color-danger);

    @media screen and (min-width: 768px) {
        left: calc(38rem / 16);
    }
}

.gamification-slider__dislike .icon {
    transform: translateY(8%);
}

.gamification-slider__like {
    right: calc(27rem / 16);
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        right: calc(38rem / 16);
    }
}

.gamification-slider__like .icon {
    transform: translateY(-3%);
}

.gamification-slider__dislike:hover {
    @media (hover: hover) {
        background: var(--color-danger);
        border: 1px solid var(--color-danger);
        color: #FFF;
    }
}

.gamification-slider__dislike:active,
.gamification-slider__dislike.active {
    background: var(--color-danger);
    border: 1px solid var(--color-danger);
    color: #FFF;
}

.gamification-slider__like:hover {
    @media (hover: hover) {
        background: var(--color-primary);
        border: 1px solid var(--color-primary);
        color: #FFF;
    }
}

.gamification-slider__like:active,
.gamification-slider__like.active {
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: #FFF;
}

.gamification-slider__likes__bg {
    color: #FFF;
    background-color: transparent;
    width: 100%;
}

.gamification-slider__likes__bg:before {
    text-shadow: 0 0 14px #bcbcbc;
}

.gamification-slider-img {
    position: relative;
}

.gamification-slider-img::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}