.slider--teaser:not(.slick-slider)>div+div,
.slider:not(.slick-slider)>div+div{
    display: none;
}
.slider--teaser:not(.slick-slider)>div{
    display: block;
    width:25%;
}
.slider {
    position: relative;
}

.slick-slider {
    line-height: 0;
}

.slider--hide-dots .slick-dots{
    display: none !important;
}





/* basic arrows */
.slick-arrow {
    position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
    color: transparent;
    background: transparent;
    width:2.5rem;
    height:2.5rem;
    border-radius:0;
    line-height: 0;
    cursor: pointer;
    z-index: 90;
    border:0;
    outline:0 !important;
    opacity:.8;
    font-size:0;

    @media screen and (min-width: 768px) {
        width:3.5rem;
        height:3.5rem;
    }
}
.slick-arrow:before {
    font-family: iconfont;
    content:var(--icon-arrow-right);
    font-size:1.25rem;
    display: inline-block;
    position: absolute;
    left:50%;
    top:50%;
    color: var(--color-text-default);
    transform: translateX(-50%) translateY(-50%);

    @media screen and (min-width: 768px) {
        font-size:2.25rem;
    }
}
.slick-arrow.slick-prev:before {
    content:var(--icon-arrow-left);
}

.slick-arrow.slick-disabled {
    opacity: .33;
    cursor: pointer !important;
}

.slick-arrow.slick-prev {
    right:auto;
    left:0;
}

.slick-arrow:not(.slick-disabled):hover,
.slick-arrow:not(.slick-disabled):focus {
    opacity: 1;
}

/* small arrows */
.slick-arrow.slick-arrow--small {
    width:2rem;
    height:2rem;
}
.slick-arrow.slick-arrow--small:before {
    font-size:1rem;
    color:#fff;
}


/* white arrows */
.slider--white-arrow .slick-arrow:before,
.slick-arrow--white:before {
    color:#fff;
}


/* relative for img-copyright */
.slick-active{
    position: relative;
}


/* opening-slider */
.slider--opening .slick-arrow {
    font-size: .75rem;
    color:inherit;
    width:6rem;
    height:2rem;
    top:100%;
    transform: translateY(-25px);
}

.slider--opening .slick-arrow.slick-next {
    text-align:right;
    padding-right:.875rem;
}
.slider--opening .slick-arrow.slick-prev {
    text-align:left;
    padding-left:.875rem;
}
.slider--opening .slick-arrow:before {
    font-size:.75rem;
    left:0rem;
    top:1.1rem;
    transform: translateX(0) translateY(0);
}
.slider--opening .slick-arrow.slick-next:before {
    left:auto;
    right:0rem;
}


/* teaser slider */
.slider--teaser .slick-arrow {
    background: var(--color-mid-grey);
}


@media screen and (min-width: 768px) {
    .slider--teaser .teaser{
        margin:0 .375rem;
    }
}
@media screen and (min-width: 1780px) {
    .slider--teaser .slick-arrow {
        background: var(--color-mid-grey);
    }
    .slider--teaser .slick-arrow.slick-prev {
        left:-4rem;
    }
    .slider--teaser .slick-arrow.slick-next {
        right:-4rem;
    }
}


/* content teaser slider */
@media screen and (min-width: 768px) {
    .slider--contentteaser .slick-arrow.slick-prev {
        left:58.33333%;
        margin-left:-.875rem;
    }
}
@media screen and (max-width: 767px) {
    .slider--contentteaser .slick-arrow {
        background: rgba(0,0,0,.5);
        width:3rem;
        height:3rem;
    }
    .slider--contentteaser .slick-arrow:before {
        font-size:1.5rem;
        color: #fff;
    }
}




/* header + galleryslider */
.header-slider .slick-arrow,
.gallery-slider .slick-arrow {
    background: #fff;
    opacity: 1;
    top:auto;
    bottom:0;
    transform: translateY(0);
}
.header-slider .slick-arrow:before,
.gallery-slider .slick-arrow:before {
    color:var(--color-text-default);
}



/* hero */
.hero-slider {
    position: relative;
    @media screen and (min-width: 768px) {
        margin-bottom:-4rem;
    }
}
.hero-slider__arrow-holder {
    position: absolute;
    top:50%;
    right:1rem;
    width:6rem;
    padding:0 2rem;
    z-index: 80;
    font-size:.875rem;
    color: #fff;
    text-shadow: 0px 0px 15px rgba(0,0,0,0.59);

    @media screen and (max-width: 767px) {
        left:50%;
        top:60%;
        transform:translateX(-50%);
        font-size:1rem;
    }
}

/*video-slider*/
.slider--teaser .teaser-video--item{
    margin-left: calc(6rem/16);
    margin-right: calc(6rem/16);
}

/* dot style */
.slick-dots {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: calc(20rem / 16);
    position: relative;
}
.slick-dots button {
    border: 0;
    border-radius: 50%;
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    padding: 0;
    text-indent: -9999em;
    background: var(--color-grey);
    margin: 0 calc(8rem / 16);
}
.slick-dots .slick-active button {
    background: var(--color-primary);
}

.slick-dots li:nth-last-child(n+8) ~ li button, .slick-dots li:nth-last-child(n+8) button {
    width: calc(12rem / 16);
    height: calc(12rem / 16);
    margin: 0 calc(4rem / 16);
}