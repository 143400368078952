.teaser-grid-video,
.teaser {
    position: relative;
    overflow: hidden;
    margin-bottom:.375rem;

    @media screen and (min-width: 768px) {
        margin-bottom:.75rem;
    }
}
.teaser:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity: 0.45;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    z-index:1;
    transition: all .2s linear;
}
.teaser:focus:after,
.teaser:hover:after {
    opacity: 0.66;
}
.teaser__body {
    padding:1.125rem;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    color:#fff;
    text-shadow: 0 0 10px rgba(0,0,0,.25);
    z-index:5;

    @media screen and (min-width: 768px) {
        padding:1.875rem;
    }
}
.teaser--big .teaser__body {
    padding:1.75rem;
    @media screen and (min-width: 768px) {
        padding:2.25rem;
    }
    @media screen and (min-width: 1440px) {
        padding:2.75rem;
    }
}
.teaser__hl {
    /*max-width:290px;*/
    hyphens: auto;
    line-height:1;
    margin:.25rem 0 .5rem 0;
    position: relative;
    font-family: var(--font-special);
    font-size: calc(19rem/16);
    @media screen and (max-width: 767px) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow:hidden;
    }
    @media screen and (min-width: 768px) {
        font-size: calc(28rem/16);
        margin:.5rem 0;
    }
    @media screen and (min-width: 1440px) {
        font-size: calc(32rem/16);
        margin:.75rem 0 .5rem 0;
    }
}


.teaser__hl.teaser__hl--smaller-tablet{
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        margin:.5rem 0;
    }

@media screen and (min-width: 1440px) {
    font-size: calc(32rem/16);
    margin:.75rem 0 .5rem 0;
}
}



.slider--teaser .teaser__hl {
    text-align: left;
}

.teaser--big .teaser__hl {
    font-size: calc(28rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(56rem/16);
    }
}
.teaser__decorator {
    width:10rem;
    height:.75rem;
    background:url(/static/img/hl-pattern-white.svg) 0 0 no-repeat;
}
.text-secondary .teaser__decorator {
    background:url(/static/img/hl-pattern-secondary.svg) 0 0 no-repeat;
}



/* teaser touren*/
.teaser.teaser--tour .teaser__hl {
    @media screen and (min-width: 992px) and (max-width: 1580px) {
        font-size: calc(20rem/16);
    }
}

.teaser-tour__atrributes{
    position: absolute;
    z-index: 2;
    top: 3.125rem;
    left: 0;
    right:0;
    color: var(--color-white);
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    padding:0 1.875rem;
    @media screen and (min-width: 1600px) {
        top: 4.5rem;
    }
}
@media screen and (max-width: 767px) {
    /*.pimcore_area_content-teaser-grid-special-sort .teaser-tour__atrributes{
        display: none;
    }*/
}
.teaser-tour__atrribute-item{
    //display: inline-block;
    //padding-left:2rem;
    position: relative;
    margin: .125rem 0;
    line-height:1.5;
    display: block;
    @media screen and (min-width: 1600px) {
        margin: .5rem 0;
    }
}
.teaser-tour__atrribute-item:first-of-type {
    @media screen and (min-width: 768px) {
        padding-top: calc(3rem/16);
    }
}
.teaser-tour__atrribute-icon {
    position: relative;
    left:0;
    top:.25rem;
    font-size: 1rem;
    @media screen and (min-width: 1600px) {
        font-size: 1.375rem;
    }
}

.teaser-tour__atrribute-text {
    font-family: var(--font-default-bold);
    margin-left: 0.5rem;
    font-size: 1rem;
    @media screen and (min-width: 1600px) {
        font-size: 1.375rem;
    }
}

.teaser__text-label {
    display: block;
    font-size:1rem;
    padding-right:6rem;
    margin:0 0 .25rem 0;
    font-family:var(--font-default);
    @media screen and (min-width: 1600px) {
        font-size: 1.125rem;
    }
}
.touren-label,
.teaser__label {
    font-size:.75rem;
    font-family: var(--font-default);
    line-height:1;
    color: #fff;
    background: var(--color-text-default);
    padding:.25rem .5rem;
    display: inline-block;
    margin:0 .125rem .125rem 0;

    @media screen and (min-width: 768px) {
        font-size:.875rem;
        margin:0 .25rem .375rem 0;
        padding:.375rem 1rem .375rem 1rem;
    }
}

.teaser--tour .teaser__label,
.is-tour-teaser .teaser__label,
.teaser--tour .touren-label,
.is-tour-teaser .touren-label {
    @media screen and (max-width: 1440px){
        display: none;
    }
}

.teaser__label:empty {
    display: none;
}

.touren-label.touren-label {
    position: relative;
    top:-.375rem;
    margin:0 0 0 1.5rem;

    @media screen and (max-width: 767px) {
        top:-.25rem;
        margin:0 0 0 .5rem;
    }
}

.touren-label.touren-label--heading {
    position: relative;
    top:-.375rem;
    margin:0 .5rem 0 0;

    @media screen and (max-width: 767px) {
        top:-.25rem;
        margin:0 .5rem 0 0;
    }
}
.touren-label--tour-leicht,
.teaser__label--tour-leicht,
.touren-label--tour-1,
.teaser__label--tour-1{
    background-color: #0066CC;
}

.touren-label--tour-mittel,
.teaser__label--tour-mittel,
.touren-label--tour-2,
.teaser__label--tour-2{
    background-color: #C51718;
}

.touren-label--tour-schwer,
.teaser__label--tour-schwer,
.touren-label--tour-3,
.teaser__label--tour-3{
    background-color: #282828;
}

.touren-label--secondary,
.teaser__label--secondary {
    background: var(--color-secondary);
}

.trust-label {
    position: relative;
    white-space: nowrap;
    display: inline-block;
    padding-right:5rem;
    line-height:0;
    background: #fff;

    @media screen and (min-width: 768px) {
        padding-right:6.5rem;
    }
}
.trust-label__nr {
    display: inline-block;
    font-family: var(--font-default-bold);
    line-height:1;
    font-size:.75rem;
    color:#fff;
    background: var(--color-secondary);
    padding:.25rem .5rem;
    @media screen and (min-width: 768px) {
        font-size:.875rem;
        padding:.375rem 1rem .375rem 1rem;
    }
}
.trust-label__img {
    position: absolute;
    right:.5rem;
    top:50%;
    transform: translateY(-50%);
    display: block;
    width:4rem;
    height:1.125rem;
    text-indent: -19999px;
    background: url(/static/build/img/trustyou-logo.png) #fff 50% 50% no-repeat;
    background-size: contain;

    @media screen and (min-width: 768px) {
        height:1.5rem;
        width:5.5rem;
    }
}
.touren-label--closed,
.teaser__label--closed {
    background: var(--color-dark-medium);
}
.touren-label--white,
.teaser__label--white {
    background: #fff;
    color: var(--color-secondary);
}


.teaser__badge {
    position: absolute;
    right: -6.75rem;
    top: -3.5rem;
    z-index:10;
    @media screen and (max-width: 767px) {
        top: -3.125rem;
    }
}
.teaser__badge--left {
    right:auto;
    left: -9rem;
    top: -4.5rem;

    @media screen and (max-width: 767px) {
        left: -7.75rem;
        top: -4.125rem;
    }
}
.teaser__badge-inner:empty{
    display: none;
}
.teaser__badge-inner{
    padding: .375rem 3.5rem;
    font-size:18px;
    line-height: 1.5;
    color:#fff;
    font-family: var(--font-default-bold);
    background: var(--color-primary);
    transform-origin: left center;
    transform: rotate(45deg);
    text-align: center;
    width: 18rem;
    @media screen and (min-width: 1920px) {
        font-size:1.125rem;
    }
    @media screen and (max-width: 767px) {
        font-size:13px;
        width: 16.125rem;
    }
}

.teaser__badge-inner--right {
    transform: rotate(45deg) translate(.5rem, -2rem);
}

.teaser__badge--left .teaser__badge-inner{
    transform-origin: right center;
    transform: rotate(-45deg);
}
.teaser__badge--secondary .teaser__badge-inner{
    background: var(--color-secondary);
}
.teaser__badge-inner .icon{
    position: relative;
    top:.25rem;
    margin-right:.25rem;
}
.teaser__badge-sommercard {
    position: relative;
    margin-right:.25rem;
    display: inline-block;
    width: 2rem;
}

.teaser__corner-badge {
    position: absolute;
    right: -1.5rem;
    top: 0;
    text-align: center;
    padding:.625rem 2.5rem .375rem 1.75rem;
    z-index:10;
    background: #fff;
    transform: skew(30deg);
    @media screen and (min-width: 768px) {
        padding:.625rem 3rem .5rem 2.375rem;
    }
}
.teaser__corner-badge-inner {
    position: relative;
    transform: skew(-30deg);
}
.teaser__corner-badge .icon {
    font-size:1.375rem;
    @media screen and (min-width: 768px) {
        font-size:2rem;
    }
}


/* small badge */
.teaser__badge--small .teaser__badge-inner{
    padding: .25rem 3.5rem;
    font-size:.75rem;
    @media screen and (min-width: 1440px) {
        font-size:.875rem;
    }
}

.teaser__badge--small .teaser__badge-sommercard {
    width: 1.375rem;
    top:-.125rem;
}
.teaser__badge--small .teaser__badge-inner .icon {
    top: .125rem;
}


/* teaser blog */
.teaser-blog {
    background-color: var(--color-dark-grey);
    text-align: center;
}
.teaser-blog__text {
    font-size: calc(14rem / 16);
    max-width: calc(500rem / 16);
    margin: calc(10rem / 16) auto 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        -webkit-line-clamp: 2;
        font-size: calc(12rem/16);
    }
}
.teaser-blog__text-label {
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-bottom: calc(5rem/16);
        display: block;
    }
}
.teaser-blog:not(.teaser--big) .teaser-blog__text {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        display: none;
    }
}
.teaser-blog__label {
    margin-top: calc(10rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }
}
.teaser-blog__link {
    margin-top: calc(10rem / 16);
    display: block;
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        margin-top: calc(5rem/16);
    }
}
.teaser-blog__hl {
    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: calc(10rem/16);
    }
}
.teaser-blog:not(.teaser--big) .teaser__hl {
    font-size: calc(19rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
    @media screen and (min-width: 1440px) {
        font-size: calc(32rem/16);
    }
}
.teaser-blog.teaser--big .teaser__hl {
    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(56rem/16);
    }
}
.teaser-blog:not(.teaser--big) .teaser__body {
    padding:1.125rem;
    @media screen and (min-width: 768px) {
        padding: calc(16rem/16);
    }
    @media screen and (min-width: 1400px) {
        padding:1.875rem;
    }
}
.teaser-blog:not(.teaser--big) .teaser__badge-inner {
    @media screen and (max-width: 1399px) and (min-width: 768px) {
        font-size: calc(10rem/16);
        padding: calc(5rem/16);
        width: calc(200rem/16);
    }
}

/* teaser nav */
.teaser-nav {
    color:var(--color-secondary);
}
.teaser-nav .nav-item {
    color:inherit;
    font-size: .75rem;
    font-family: var(--font-default-bold);
    text-align: center;

    @media screen and (max-width: 767px) {
        padding:.5rem;
    }

    @media screen and (min-width: 992px) {
        font-size: .9375rem;
    }
}
.teaser-nav .nav-item .icon {
    display: block;
    font-size:1.75rem;
    margin:.375rem auto;

    @media screen and (min-width: 992px) {
        font-size:2.375rem;
    }
}



/* teaser big*/
.teaser--big .teaser__hl:after {
    content:'';
    width:13.5rem;
    height:1rem;
}


/* body top*/
.teaser__body--top {
    bottom:auto;
    top:0;
}

/* video */
.image-teaser__play-button--right,
.image-teaser__pause-button--right{
    display: none;
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translateY(-50%);
    z-index: 20;
    @media screen and (min-width: 768px){
        display: block;
    }
    @media screen and (min-width: 1400px) {
        left: 74%;
    }
}

.image-teaser__play-button--left,
.image-teaser__pause-button--left{
    display: none;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    z-index: 20;
    @media screen and (min-width: 768px){
        display: block;
    }
    @media screen and (min-width: 1400px) {
        left: 26%;
    }
}

.image-teaser__pause-button--left,
.image-teaser__pause-button--right{
    @media screen and (min-width: 768px){
        opacity: 0;
        transition: opacity 0.2s;
    }
}

.image-teaser:hover .image-teaser__pause-button--left,
.image-teaser:hover .image-teaser__pause-button--right{
    @media screen and (min-width: 768px){
        opacity: 1;
    }
}

.teaser__play-button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    z-index: 20;
}
.teaser__checkbox {
    position:absolute;
    top:1.125rem;
    left:1.875rem;
    z-index:10;
}