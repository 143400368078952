.accordion > .card.card {
    margin-bottom:.375rem;
    border: 1px solid var(--color-grey) !important;
}
.accordion > .card > .card-header {
    margin: 0;
    font-family: var(--font-default-bold);
    background-color: var(--color-white);
    font-size: 1.125rem;
    border-bottom:0;

    @media screen and (max-width: 767px) {
        font-size: .875rem;
    }
}
.accordion .accordion__header-link{
    display: block;
}
.card-header > .accordion__header-link:not(.collapsed) {
    color: var(--color-primary);
}

.accordion__header-link:not(.collapsed) .accordion__toggle-icon {
    transform: rotate(45deg);
    transition: ease-in 0.1s;
}
.accordion__header-link:not(.collapsed) .accordion__toggle-icon--arrow {
    transform: rotate(-90deg);
}
.accordion__header-link.collapsed .accordion__toggle-icon--arrow {
    transform: rotate(90deg);
}
.accordion .card-body {
    border-top: 1px solid var(--color-grey);
}
.card-contact .card-header>.accordion__header-link:not(.collapsed) {
    color: var(--color-default);
}
.accordion > .card.card.card-contact {
    border: none !important;
}
.accordion .card-contact .card-body {
    border-top: none;
}
.card-contact .card-text {
    font-size: calc(16rem/16);
    line-height: calc(24/16);
}