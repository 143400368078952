.service-footer,
.breadcrumb-wrapper,
.demi-merchshop-overview-content-block,
.content-block:not(.content-block--fullwidth) {
    @media screen and (min-width: 768px) and (max-width: 1799px) {
        padding-left:100px;
    }
    @media screen and (min-width: 1800px) {
        padding-left:20px;
    }
}

body.is-affiliate .content-block {
    @media screen and (min-width: 768px) and (max-width: 1799px) {
        padding-left: 0;
    }
}

/*.content-block + .content-block,
.pimcore_area_content + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block*/

.content-block {
    margin: calc(40rem/16) 0;

    @media screen and (min-width: 768px) {
        margin: calc(90rem/16) 0;
    }
}
.content-block--small,
.affiliate .content-block {
    margin: calc(20rem/16) 0;

    @media screen and (min-width: 768px) {
        margin: calc(30rem/16) 0;
    }
}

.breadcrumb-wrapper + .content-block.content-block,
.breadcrumb-wrapper + .pimcore_area_content > .content-block.content-block {
    @media screen and (min-width: 768px) {
        margin: calc(30rem/16) 0;
    }
}

.main-img + .content-block.content-block,
.main-img + .pimcore_area_content > .content-block.content-block {
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(45rem/16);
    }
}


/* override contentblock in accordion */
.accordion .content-block.content-block {
    margin:0;
    padding:0;
}