.job-application-title{
    font-size: calc(17rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.job-information__icon{
    padding-right: calc(10rem/16);
    color: var(--color-primary);
}
.job-information{
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: calc(19/16);
}