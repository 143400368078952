.demi-merchshop__filterbox,
.demi-merchshop-paging{
    margin: 2rem 0;
    @media screen and (min-width: 768px) {
        margin: 3rem 0;
    }
}

/* teaser stuff */
.teaser.merchshop-teaser {
    background: #fff;
    overflow: visible;
    margin:0;
    height:100%;
}
.btn--merchshop-teaser__sizes {
    font-size:.75rem;
    font-family: var(--font-default);
    line-height:1;
    color: #fff;
    background: var(--color-text-default);
    padding:.375rem .75rem .25rem .75rem;
    display: inline-block;
    margin:.125rem .125rem .125rem 0;
}
.btn--merchshop-teaser__sizes:hover,
.btn--merchshop-teaser__sizes:focus {
    color: #fff;
    background: var(--color-secondary);
}
.merchshop-teaser__sizes {
    margin:.5rem 0 0 0;
    @media screen and (min-width: 768px) {
        margin:1rem 0 0 0;
    }
}
.merchshop-teaser__price {
    font-size: 1rem;
    margin:.5rem 0 0 0;
    font-family:var(--font-default-bold);
    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}
/*.merchshop-teaser__body {
    padding: 1.125rem;
    @media screen and (min-width: 768px) {
        padding: 1.875rem;
    }
}*/
.merchshop-teaser__badge {
    position: absolute;
    right:.25rem;
    top:.25rem;
    display: block;
    width:5rem;
    height:5rem;
    overflow: hidden;
    font-size:1.125rem;
    line-height:5rem;
    text-align:center;
    border-radius:100%;
    color:#fff;
    font-family:var(--font-default-bold);
    background: var(--color-primary);
    z-index: 10;
}

.gender-btn {
    padding:1rem 1.5rem;
    line-height:1;
    border: 0.125rem solid lightgrey;
    border-radius: 99px;
    margin: .25rem .25rem .25rem 0;
    display: inline-block;
    color: lightgrey;
    align-items: center;
    justify-content: center;
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    transition: all 200ms ease;
    cursor: pointer;
}
.gender-btn.is-selected,
.gender-btn:hover{
    border-color: var(--color-primary);
    background:  var(--color-primary);
    color:  #fff;
}