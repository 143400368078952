.demi-additionalservice-intro {
    margin-bottom:2rem;

    @media screen and (min-width: 768px) {
        margin-bottom:4rem;
    }
}

.demi-additionalservice-intro h1, .demi-additionalservice-intro h2, .demi-additionalservice-intro h3 {
    color:var(--color-secondary);
}


.filter-wrapper-demi-as {
    position: relative;
    width:100%;
    max-width:400px;
    margin:0 auto;
}