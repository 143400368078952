.has-add-animation {
    animation: shadow-pulse 1s infinite ease-in-out;
    animation-iteration-count: 1;
}

@keyframes shadow-pulse
{
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.625);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
    }
}