.teaser-challenge {
    box-shadow: 0 1.25rem 3.125rem 0 rgb(67 79 41 / 30%);
    border-radius: .5rem;
    position: relative;
    font-size: 1rem;
    padding: 1.25rem;
    height:auto;
    min-height: calc(210rem/16);

    @media screen and (min-width: 768px) {
        padding: 1.875rem 1.25rem;
        font-size: 1.25rem;
        height: 100%;
        min-height: calc(170rem/16);
    }

    @media screen and (min-width: 1200px) {
        min-height: calc(270rem/16);
    }
}

.teaser-challenge__title {
    font-family: var(--font-default-bold);
    font-weight: 400;
    line-height: 1.3;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        line-height: 1.3;
    }
}
.teaser-challenge__count {
    font-family: var(--font-special);
    line-height: 1;
    font-weight: 400;
    font-size: 6.25rem;
    padding: .625rem 0;
    bottom: 7%;
    position: absolute;
    left: 0;
    right: 0;

    @media screen and (min-width: 772px) {
        font-size: calc(150rem/16);
        padding: 1.25rem 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5%;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
        font-size: calc(80rem/16);
    }
}