/* Seasons */

@media screen and (max-width: 767px) {
    .tour-seasons__swipe {
        white-space: nowrap;
        overflow-x: scroll;
    }
    .tour-seasons__inner {
        width: 1250px;
    }
}
.tour-seasons__season{
    padding: 0.5rem 0;
    color: var(--color-text-default);
    font-family: var(--font-default-bold);
    text-align: center;
    line-height: 3;
}

.tour-seasons__season--disabled {
    color: var(--color-grey);
}

.tour-seasons__season:before{
    content:'';
    display: block;
    background-color: var(--color-primary);
    width: 100%;
    height: 0.5rem;
}

.tour-seasons__season.tour-seasons__season--disabled:before{
    background-color: var(--color-grey);
}

/* Info */

.tour-info__block{
    padding-left:2rem;
    position: relative;
    margin: 1rem 0;

    @media screen and (max-width: 767px) {
        margin:.375rem 0;
        padding-left:1.5rem;
    }
}
.tour-info__block__icon {
    position: absolute;
    left:0;
    top:.125rem;
    font-size: 1.5rem;
    @media screen and (max-width: 1199px) {
        font-size: 1rem;
    }
}

.tour-info__block__text {
    font-family: var(--font-default-bold);
    margin-left: 0.5rem;
    font-size: 1.375rem;
    @media screen and (max-width: 1199px) {
        font-size: 1rem;
    }
}


a[aria-expanded="true"] .collapse-tour-more {
    display: none;
}
a[aria-expanded="false"] .collapse-tour-less {
    display: none;
}