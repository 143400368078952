.rating-form{
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
    padding: 2rem;
}

.rating-details {
    background-color: var(--color-white);
    padding: 2rem;
    border: 1px solid var(--color-grey);
    @media screen and (max-width: 767px) {
        padding: 1.5rem;
    }
}

.rating-form__stars{
    font-size: 2rem;
    color: var(--color-grey);
    @media screen and (max-width: 767px) {
        font-size: 1.75rem;
    }
}

.rating-form__stars--small{
    font-size: 1.5rem;
    @media screen and (max-width: 767px) {
        font-size: 1.25rem;
    }
}

.rating-form__stars:not(.rating-form__stars--small) .icon{
    margin-right: 1rem;
    @media screen and (max-width: 767px) {
        margin-right: 0.5rem;
    }
}


.active-star{
    color: var(--color-star-rating);
}


/* rating-objekt */
.rating-objekt{
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
    padding: 2rem;
    @media screen and (max-width: 767px) {
        padding: 1rem;
    }
}