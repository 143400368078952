.season-switch {
    position: absolute;
    right:0;
    top:65%;
    background: #fff;
    padding:0 .5rem;
    pointer-events:initial;

    @media screen and (min-width: 768px){
        padding:0 .875rem;
        top:50%;
        transform: translateY(-50%);
    }
}
.season-switch__link {
    display: inline-block;
    padding:1rem .75rem .75rem .75rem;
    text-align:center;
    color:var(--color-text-muted);
    font-family: var(--font-default-bold);
    position: relative;

    @media screen and (min-width: 768px){
        display: block;
        width: 4.5rem;
        padding:3rem .5rem 1rem .5rem;
    }
}

.season-switch__link>.icon {
    font-size:1.5rem;
}

@media screen and (min-width: 768px){
    .season-switch__link:before,
    .season-switch__link:after {
        display: block;
        content:'';
        position: absolute;
        left:50%;
        top:1.875rem;
        width:1.25rem;
        height:1.25rem;
        border:2px solid;
        border-radius:50%;
        transform: translateY(-50%) translateX(-50%);
    }
    .season-switch__link:before {
        display: none;
        width:.5rem;
        height:.5rem;
        background: var(--color-primary);
    }
    .season-switch__link:hover,
    .season-switch__link:focus,
    .season-switch__link--active {
        color:var(--color-primary);
    }
    .season-switch__link--active:before {
        display: block;
    }
    .season-switch__link + .season-switch__link {
        border-top: 1px solid #F5F5F5;
    }
}
.season-switch__link:hover,
.season-switch__link:focus,
.season-switch__link--active {
    color:var(--color-primary);
}
