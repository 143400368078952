/*Margin Helper*/


/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hasDatepicker,
.isCursor {
    cursor: pointer;
}
.pointer-events-none { pointer-events: none;}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-primary { color: var(--color-primary); }
.text-color-default { color: var(--color-text-default); }
.text-color-secondary { color: var(--color-secondary); }
.text-color-danger { color: #C51718; }
.text-dark-grey, .text-muted { color: var(--color-dark-grey) !important; }
.text-grey { color: var(--color-grey); }
.text-light-grey { color: var(--color-light-grey); }
.text-booking {color: var(--color-booking);}

/*Background Color Helper*/
.bg-dark { background: var(--color-text-default); }
.bg-primary { background: var(--color-primary); }
.bg-secondary { background: var(--color-secondary); }
.bg-booking {background-color: var(--color-booking);}
.bg-booking-grey {background-color: #D8D8D8;}


.ajax-load-container.is-loading {
    opacity:.3;
}

.ov-hidden {
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    .xs-position-static {
        position: static;
    }
}

.box-shadow {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
}
.img-shadow-overlay:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity: .8;
    background: linear-gradient(219.02deg, rgba(29,29,27,0) 0%, #1D1D1B 100%);
}

.anchor-target:target {
    display: block;
    margin-top: calc(-100rem / 16);
    padding-top: calc(100rem / 16);
    /*z-index: -999;*/
}

a.link-with-icon {
    color:var(--color-text-default);
    position: relative;
    padding-left:1.875rem;
    text-decoration: none;
}
a.link-with-icon:hover,
a.link-with-icon:focus {
    color:var(--color-primary);
}

a.link-with-icon>.icon {
    position: absolute;
    line-height:1px;
    font-size: .9375rem;
    top: .7rem;
    left:0;
}



a.info-link {
    color:var(--color-primary);
    font-size: 1.375rem;
    text-decoration: none !important;
}
a.info-link:before {
    content:var(--icon-info);
    font-family: iconfont;
}


.objekt-detail-label {
    display: inline-block;
    background: var(--color-secondary);
    color:#fff;
    position: relative;
    padding-left:1.875rem;
    text-decoration: none;
}

.objekt-detail-label>.icon {
    position: relative;
    line-height:1px;
    font-size: .9375rem;
}


.tooltip-inner {
    text-align: left;
    max-width: 300px;
    padding:1.25rem .5rem;

    @media screen and (min-width: 768px) {
        max-width: 410px;
    }
}
.tooltip-inner ul {
    margin-right:1.5rem;
}

.w-100 {
    width: 100%;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact.mapboxgl-compact {
    padding: 12px !important;
    width: auto !important;
}


/* font sizes */
.fs1 {
    font-size: 1rem !important;
}
.fs0-875 {
    font-size: .875rem !important;
}
.fs0-75 {
    font-size: .75rem !important;
}
.fs1-125 {
    font-size: 1.125rem !important;
}
.fs1-25 {
    font-size: 1.25rem !important;
    @media screen and (max-width: 767px) {
        font-size: 1rem !important;
    }
}
.fs1-5 {
    font-size: 1.5rem !important;
    @media screen and (max-width: 767px) {
        font-size: 1.125rem !important;
    }
}
.fs2 {
    font-size: 2rem !important;
    @media screen and (max-width: 767px) {
        font-size: 1.25rem !important;
    }
}
.fs2-25 {
    font-size: 2.25rem !important;
    @media screen and (max-width: 767px) {
        font-size: 1.625rem !important;
    }
}
.fs2-5 {
    font-size: 2.5rem !important;
    @media screen and (max-width: 767px) {
        font-size: 1.5rem !important;
    }
}
.fs3 {
    font-size: 3rem !important;
    @media screen and (max-width: 767px) {
        font-size: 1.875rem !important;
    }
}


@media screen and (max-width: 767px) {
    .xs-w-100 {
        width: 100%;
    }
    .d-xs-none {
        display: none;
    }
}

.overflow-visible {
    overflow: visible;
}

/* categories */
.category-tile{
    display: inline-block;
    width:1.25rem;
    height:1rem;
    margin-left:.25rem;
    top:-.125rem;
    position: relative;
    background:url(/static/img/small-pattern.svg) 0 0 no-repeat;
}


.category-tile--category-blue {background:url(/static/img/small-pattern-blue.svg) 0 0 no-repeat;}
.category-tile--category-grey {background:url(/static/img/small-pattern-grey.svg) 0 0 no-repeat;}
.category-tile--category-violet {background:url(/static/img/small-pattern-violet.svg) 0 0 no-repeat;}
.category-tile--category-yellow {background:url(/static/img/small-pattern-yellow.svg) 0 0 no-repeat;}
.category-tile--category-pink {background:url(/static/img/small-pattern-pink.svg) 0 0 no-repeat;}
.category-tile--category-green {background:url(/static/img/small-pattern-green.svg) 0 0 no-repeat;}
.category-tile--category-red {background:url(/static/img/small-pattern-red.svg) 0 0 no-repeat;}
.category-tile--category-darkgreen {background:url(/static/img/small-pattern-darkgreen.svg) 0 0 no-repeat;}
.category-tile--category-darkblue {background:url(/static/img/small-pattern-darkblue.svg) 0 0 no-repeat;}
.category-tile--category-cyan {background:url(/static/img/small-pattern-cyan.svg) 0 0 no-repeat;}

.classic-teaser--category-blue .classic-teaser__decorator{background:url(/static/img/hl-pattern-blue.svg) 0 0 no-repeat;}
.classic-teaser--category-grey .classic-teaser__decorator{background:url(/static/img/hl-pattern-grey.svg) 0 0 no-repeat;}
.classic-teaser--category-violet .classic-teaser__decorator{background:url(/static/img/hl-pattern-violet.svg) 0 0 no-repeat;}
.classic-teaser--category-yellow .classic-teaser__decorator{background:url(/static/img/hl-pattern-yellow.svg) 0 0 no-repeat;}
.classic-teaser--category-pink .classic-teaser__decorator{background:url(/static/img/hl-pattern-pink.svg) 0 0 no-repeat;}
.classic-teaser--category-green .classic-teaser__decorator{background:url(/static/img/hl-pattern-green.svg) 0 0 no-repeat;}
.classic-teaser--category-red .classic-teaser__decorator{background:url(/static/img/hl-pattern-red.svg) 0 0 no-repeat;}
.classic-teaser--category-darkgreen .classic-teaser__decorator{background:url(/static/img/hl-pattern-darkgreen.svg) 0 0 no-repeat;}
.classic-teaser--category-darkblue .classic-teaser__decorator{background:url(/static/img/hl-pattern-darkblue.svg) 0 0 no-repeat;}
.classic-teaser--category-cyan .classic-teaser__decorator{background:url(/static/img/hl-pattern-cyan.svg) 0 0 no-repeat;}

.teaser--category-blue .teaser__decorator{background:url(/static/img/hl-pattern-blue.svg) 0 0 no-repeat;}
.teaser--category-grey .teaser__decorator{background:url(/static/img/hl-pattern-grey.svg) 0 0 no-repeat;}
.teaser--category-violet .teaser__decorator{background:url(/static/img/hl-pattern-violet.svg) 0 0 no-repeat;}
.teaser--category-yellow .teaser__decorator{background:url(/static/img/hl-pattern-yellow.svg) 0 0 no-repeat;}
.teaser--category-pink .teaser__decorator{background:url(/static/img/hl-pattern-pink.svg) 0 0 no-repeat;}
.teaser--category-green .teaser__decorator{background:url(/static/img/hl-pattern-green.svg) 0 0 no-repeat;}
.teaser--category-red .teaser__decorator{background:url(/static/img/hl-pattern-red.svg) 0 0 no-repeat;}
.teaser--category-darkgreen .teaser__decorator{background:url(/static/img/hl-pattern-darkgreen.svg) 0 0 no-repeat;}
.teaser--category-darkblue .teaser__decorator{background:url(/static/img/hl-pattern-darkblue.svg) 0 0 no-repeat;}
.teaser--category-cyan .teaser__decorator{background:url(/static/img/hl-pattern-cyan.svg) 0 0 no-repeat;}

.teaser--category-blue .text-teaser__decorator{background:url(/static/img/hl-pattern-blue.svg) 0 0 no-repeat;}
.teaser--category-grey .text-teaser__decorator{background:url(/static/img/hl-pattern-grey.svg) 0 0 no-repeat;}
.teaser--category-violet .text-teaser__decorator{background:url(/static/img/hl-pattern-violet.svg) 0 0 no-repeat;}
.teaser--category-yellow .text-teaser__decorator{background:url(/static/img/hl-pattern-yellow.svg) 0 0 no-repeat;}
.teaser--category-pink .text-teaser__decorator{background:url(/static/img/hl-pattern-pink.svg) 0 0 no-repeat;}
.teaser--category-green .text-teaser__decorator{background:url(/static/img/hl-pattern-green.svg) 0 0 no-repeat;}
.teaser--category-red .text-teaser__decorator{background:url(/static/img/hl-pattern-red.svg) 0 0 no-repeat;}
.teaser--category-darkgreen .text-teaser__decorator{background:url(/static/img/hl-pattern-darkgreen.svg) 0 0 no-repeat;}
.teaser--category-darkblue .text-teaser__decorator{background:url(/static/img/hl-pattern-darkblue.svg) 0 0 no-repeat;}
.teaser--category-cyan .text-teaser__decorator{background:url(/static/img/hl-pattern-cyan.svg) 0 0 no-repeat;}

.border-category-blue {border-color: #009ee0;}
.border-category-grey {border-color: #547483;}
.border-category-violet {border-color: #a59ec3;}
.border-category-yellow {border-color: #fabb21;}
.border-category-pink {border-color: #cf93a5;}
.border-category-green {border-color: #a7c225;}
.border-category-red {border-color: #c00418;}
.border-category-darkgreen {border-color: #005c24;}
.border-category-darkblue {border-color: #003c96;}
.border-category-cyan {border-color: #00dcd2;}

.text-category-blue {color: #009ee0;}
.text-category-grey {color: #547483;}
.text-category-violet {color: #a59ec3;}
.text-category-yellow {color: #fabb21;}
.text-category-pink {color: #cf93a5;}
.text-category-green {color: #a7c225;}
.text-category-red {color: #c00418;}
.text-category-darkgreen {color: #005c24;}
.text-category-darkblue {color: #003c96;}
.text-category-cyan {color: #00dcd2;}

.bg-category-blue {background-color: #009ee0;}
.bg-category-grey {background-color: #547483;}
.bg-category-violet {background-color: #a59ec3;}
.bg-category-yellow {background-color: #fabb21;}
.bg-category-pink {background-color: #cf93a5;}
.bg-category-green {background-color: #a7c225;}
.bg-category-red {background-color: #c00418;}
.bg-category-darkgreen {background-color: #005c24;}
.bg-category-darkblue {background-color: #003c96;}
.bg-category-cyan {background-color: #00dcd2;}

.flex-break {
    flex-basis:100%;
    height:0;
}