/* collapse checkbox plus */
a.collapse-all-filter {
    position: relative;
    top: -.375rem;
    line-height: 1px;
    display: inline-block;
    padding: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--color-dark-grey);
}
a.collapse-all-filter:not(.collapsed) {
    transform: rotate(45deg);
}
a.collapse-all-filter:hover,
a.collapse-all-filter:focus {
    color: var(--color-primary);
}



/* collapse link */
a.collapse-filterblock {
}

a.collapse-filterblock .collapse-filterblock__plus {
    line-height: 1px;
    display: inline-block;
    padding: .5rem;
    font-size: 1.5rem;
    color: var(--color-dark-grey);
}
a.collapse-filterblock:not(.collapsed) .collapse-filterblock__plus {
    transform: rotate(45deg);
}
a.collapse-filterblock:hover,
a.collapse-filterblock:focus {
    color: var(--color-text-default);
}
a.collapse-filterblock:hover .collapse-filterblock__plus,
a.collapse-filterblock:focus .collapse-filterblock__plus {
    color: var(--color-primary);
}