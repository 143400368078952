.info-block__item {
    position: relative;
    background-color: var(--color-white);
    padding: 1.25rem;
    border: 1px solid var(--color-grey);
    margin-bottom: .75rem;
    overflow:hidden;
}
.info-block__slider {
    margin:0 -1.25rem -1.25rem -1.25rem;
}
.info-block__hl {
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        font-size:1.625rem;
    }
}
.info-block__item--has-badge {
    padding-right:6rem;
    @media screen and (max-width: 767px) {
        padding-right: 2.75rem;
    }
}
.info-block__item--has-badge .info-block__hl {
    @media screen and (max-width: 767px) {
        padding-right: 2.75rem;
    }
}
.info-block__item__icon {
    padding-top: 0.5rem;
    font-size: 2.5rem;
    margin-left: 0.75rem;
    @media screen and (max-width: 767px) {
        font-size: 2rem;
    }
}

.info-block__collapse {
    display: block;
    padding-right:2.5rem;
}
.info-block__collapse .icon {
    position: absolute;
    right:1rem;
    top:2.125rem;
    font-size: .625rem;
    transform: rotate(180deg);
    @media screen and (max-width: 767px) {
        font-size: .5rem;
        top:1.875rem;
    }
}
.info-block__collapse.collapsed .icon {
    transform: rotate(0deg);
}

.info-block__list {
    margin: 0;
    padding: 0;
}

.info-block__list > li {
    margin: .5rem 0;
    padding-left: 1.75rem;
    list-style: none;
    position: relative;

    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.info-block__list > li:before {
    font-family: iconfont;
    content: var(--icon-check);
    font-size: .75rem;
    position: absolute;
    left: 0;
    top: .125rem;
}


.info-block__badge {
    position: absolute;
    right: -6.125rem;
    top: -3.5rem;
    z-index:10;
}
.info-block__badge-inner{
    padding: .375rem 2.5rem;
    font-size:18px;
    line-height: 1.5;
    color:#fff;
    font-family: var(--font-default-bold);
    background: var(--color-secondary);
    transform-origin: left center;
    transform: rotate(45deg);
    text-align: center;
    width: 15.625rem;

    @media screen and (max-width: 767px) {
        font-size: 1.125rem;
    }
}
.info-block__badge-inner .icon{
    position: relative;
    top:.25rem;
    margin-right:.25rem;
}