/* booking teaser */
.teaser-booking__text,
.teaser-booking__button{
    padding:0 2.25rem;
}

@media screen and (max-width:767px) {
    .teaser-booking__text,
    .teaser-booking__button{
        padding:0 1.5rem;
    }
}

@media screen and (min-width: 1200px) {
    .teaser-booking__text,
    .teaser-booking__button{
        padding:0 3.25rem;
    }
}

/* Anfrage Formular */
.request-form {
    position: relative;
}

.request-form__headline {
    color: white;
    font-family: var(--font-special);
    font-size: 1.75rem;
}

.request-form__counter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border-radius: 100%;
    border: 2px solid white;
    font-size: 1rem;
    line-height: 1;
    min-height: 2.75rem;
    min-width: 2.75rem;
}

.request-form__counter--text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    font-size: 1.25rem;
    font-family: var(--font-special);
}

.request-form__badge {
    display: inline-block;
    font-family: var(--font-default);
}

.request-form__headline.request-form__headline--form {
    padding: .9375rem 0;
}
.request-form--toggle{
    padding:.9375rem 0;
    cursor: pointer;
    color:#fff;
    display: block;
    background: var(--color-booking);
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    z-index: 99;
    transition: all .2s linear;
    transform: translateY(150px);
    @media screen and (max-width: 767px) {
        text-align:center;
        padding-right:2rem;
    }
}
.request-form--toggle.request-form--toggle-active {
    transform: translateY(0);
}

.request-form__area-heading{
    font-family: var(--font-special);
    font-size: 1.75rem;

    @media screen and (max-width: 767px) {
        font-size: 1.25rem;
    }
}

.request-form__badge{
    cursor: pointer;
}

.request-btn.is-added .icon:before{
    content:var(--icon-check);
}

@media screen and (max-width: 767px) {
    .request-form__headline {
        font-size: 1.125rem;
    }

    .request-form__counter {
        font-size: 0.75rem;
        min-height: 2rem;
        min-width: 2rem;
    }

    .request-form__counter--text {
        font-size: 0.875rem;
    }
}