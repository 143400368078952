.content-teaser:not(.content-teaser--full) {
    @media screen and (max-width: 1420px) {
        margin:0 -1rem;
    }
}
.content-teaser__body {
    padding:1.75rem;
    @media screen and (min-width: 768px) {
        padding:3.25rem 3.75rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1420px) {
        padding:4.25rem 4.75rem;
    }
}

.content-teaser__wrapper {
    @media screen and (min-width: 768px) and (max-width: 1499px) {
        padding-left: clamp(7.5rem,9vw,9rem);
    }
}

.content-teaser--full .content-teaser__body {
    @media screen and (min-width: 768px) {
        padding:4.25rem 4.75rem;
    }
}

.content-teaser__decorator {
    width:10rem;
    height:.75rem;
    background:url(/static/img/hl-pattern-secondary.svg) 0 0 no-repeat;
}