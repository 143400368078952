header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 950;

    @media screen and (max-width: 767px) {
        background: #fff;
        height: 3.125rem;
        box-shadow: 0 2px 14px 0 rgba(67, 79, 41, 0.2);
    }
}

header.nav-is-open {
    @media screen and (max-width: 767px) {
        background: #EBEAEB;
        box-shadow: none;
    }
}

header .nav-brand {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .2s linear;
    @media screen and (min-width: 768px) {
        width: 30%;
        max-width: 450px;
        min-width: 260px;
    }
    @media screen and (min-width: 1920px) {
        max-width: 600px;
    }
    @media screen and (max-width: 767px) {
        width: 100px;
        margin: .625rem 1rem 0 0;
    }
}

header .nav-brand.nav-brand--eem {
    @media screen and (min-width: 768px) {
        max-width: 280px;
    }
}

.has-high-logo header .nav-brand {
    @media screen and (min-width: 768px) {
        width: 150px;
        max-width: 150px;
        min-width: 150px;
        right: 1rem;
        top: 1rem;
    }
    @media screen and (max-width: 767px) {
        width: 40px;
        margin: .375rem 1rem 0 0;
    }
}

header .nav-brand.hide-brand {
    transform: translateY(-250px);
    pointer-events: none;
}

header .logo {
    display: block;
    width: 100%;
    height: auto;
}


header .sticky-cta {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-200px);
    transition: all .2s linear;
}

header .sticky-cta.show-cta {
    transform: translateY(0);
}

header .sticky-cta.show-cta .btn-booking {
    @media screen and (min-width: 768px) {
        font-size: calc(16rem /16);
        padding: clamp(1rem, 1.5vw, 1.75rem) clamp(.75rem, 1.25vw, 1.5rem);
    }
}

header .sticky-cta.show-cta .btn-booking .icon {
    @media screen and (min-width: 768px) and (max-width: 1399px){
        font-size: calc(24rem /16);
    }
}

/* left base */
.header__left-link.active .icon:before {
    content: var(--icon-close);
}

/* left desktop */
@media screen and (min-width: 1400px) {
    .header__left {
        width: 6.25rem;
        z-index: 980;
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: 0 2px 14px 0 rgba(67, 79, 41, 0.2);
    }

    .header__left > ul.header__left-list {
        margin: 0;
        padding: 0;
    }

    .header__left-item {
        position: relative;
    }

    .header__left-list li.header__left-item--border:after {
        content: '';
        position: absolute;
        left: 1.5rem;
        right: 1.5rem;
        bottom: 0;
        height: 1px;
        background-color: #DDDDDD;
    }

    .header__left-link {
        display: block;
        padding-top: 1.75rem;
        width: 6.25rem;
        height: 6.25rem;
        text-align: center;
        font-family: var(--font-default-bold);
        color: var(--color-dark-grey);
        background: #fff;
        font-weight: normal;
    }

    .header__left-link--toggler {
        color: #fff;
        background: var(--color-primary);
    }

    .header__left-link.active .header__left-link-txt {
        display: none !important;
    }

    .header__left-link.header__left-link--toggler:hover,
    .header__left-link.header__left-link--toggler:focus {
        color: #fff;
        background: var(--color-primary-dark);
    }

    .header__left-link .header__left-link-txt {
        display: block;
        font-size: .9375rem;
    }

    .header__left-link.active .icon {
        font-size: 1.25rem;
        margin: .75rem 0;
    }

    .header__left-link:hover,
    .header__left-link:focus {
        color: var(--color-text-default);
    }

    .header__left-link > .icon {
        font-size: 1.25rem;
        margin-bottom: .5rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1399px) {
    .header__left {
        width: clamp(4rem, 3vw, 6.25rem);
        z-index: 980;
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: 0 2px 14px 0 rgba(67, 79, 41, 0.2);
    }

    .header__left > ul.header__left-list {
        margin: 0;
        padding: 0;
    }

    .header__left-item {
        position: relative;
    }

    .header__left-list li.header__left-item--border:after {
        content: '';
        position: absolute;
        left: 1.5rem;
        right: 1.5rem;
        bottom: 0;
        height: 1px;
        background-color: #DDDDDD;
    }

    .header__left-link {
        display: block;
        padding-top: 1.25rem;
        width: clamp(4.5rem, 6.5vw, 6.25rem);
        height: clamp(4.5rem, 6.5vw, 6.25rem);
        text-align: center;
        font-family: var(--font-default-bold);
        color: var(--color-dark-grey);
        background: #fff;
        font-weight: normal;
    }

    .header__left-link--toggler {
        color: #fff;
        background: var(--color-primary);
    }

    .header__left-link.active .header__left-link-txt {
        display: none !important;
    }

    .header__left-link.header__left-link--toggler:hover,
    .header__left-link.header__left-link--toggler:focus {
        color: #fff;
        background: var(--color-primary-dark);
    }

    .header__left-link .header__left-link-txt {
        display: block;
        font-size: .75rem;
    }

    .header__left-link.active .icon {
        font-size: 1rem;
        margin: .75rem 0;
    }

    .header__left-link:hover,
    .header__left-link:focus {
        color: var(--color-text-default);
    }

    .header__left-link > .icon {
        font-size: 1rem;
        margin-bottom: .25rem;
    }
}

/* left mobile */
@media screen and (max-width: 767px) {
    .header__left {
        margin-left: .625rem;
    }

    .header__left-item {
        display: inline-block;
    }

    .header__left-link {
        position: relative;
        display: block;
        font-size: 1.125rem;
        padding-top: .75rem;
        width: 3rem;
        height: 3.125rem;
        line-height: 3.125rem;
        text-align: center;
        color: var(--color-dark-grey);
    }

    .lang-cs .header__left-link {
        width: 3.75rem;
    }

    .header__left-link-txt {
        font-size: .75rem;
    }

    .header__left-profile-img,
    .header__left-link .icon {
        position: absolute;
        left: 50%;
        top: .625em;
        font-size: 1rem;
        transform: translateX(-50%) translateY(0);
    }

    .header__left-profile-img {
        width: 1.375rem;
        height: auto;
        top: .375rem;
    }

    .header__left-link.active .icon {
        /*font-size:1.125rem;*/
    }

}