.liveinfo-block {
    margin-bottom:2rem;
    @media screen and (min-width: 768px){
        margin-bottom:4rem;
    }
}


.status {
    display: inline-block;
    width:1.875rem;
    height:1.875rem;
    border-radius: 50%;
    position: relative;
}
.status--small {
    width:1.25rem;
    height:1.25rem;
}
.status--ll,
.status--small-ll {
    margin-left:.25rem;
    width:2.5rem;
    height:1.25rem;
    border-radius: .5rem;
    background: var(--color-secondary);
}
.status--ll {
    width:3.75rem;
    height: 1.875rem;
    @media screen and (min-width: 768px){
        margin-left:1rem;
    }
}
.status--fahrbar>i,
.status--gut>i {
    display: block;
    position: absolute;
    left:-.25rem;
    top:0;
    bottom:0;
    border-bottom-left-radius: .5rem;
    border-top-left-radius: .5rem;
    background: var(--color-primary);
}
.status--sehr-gut {
    background: var(--color-primary);
}
.status--gut>i {
    right:30%;
}
.status--fahrbar>i {
    right:70%;
}
.status--open {
    background:#B1C200;
}
.status--closed {
    background:#E30613;
}
.status--preperation,
.status--warning{
    background:#F26518;
}
.status>.icon {
    color:#fff;
    font-size:1rem;
    line-height:1px;
    position: absolute;
    left:0;
    right: 0;
    top:50%;
    text-align: center;
}
.status--small>.icon {
    font-size:.625rem;
}

.anlagen-status__icon{
    font-size: 1.75rem;
}
.seats-tooltip{
    position: relative;
    display: inline-block;
    width: 2.5rem;
    padding-right:.75rem;
}
.seats-tooltip__nr{
    position: absolute;
    right:0;
    bottom:-.125rem;
    font-size: .875rem;
}


.piste-type {
    display: inline-block;
    width:1.875rem;
    height:1.875rem;
    border-radius: 50%;
    position: relative;
}
.piste-type--small {
    width:1.25rem;
    height:1.25rem;
}
.piste-type--black {
    background:#000;
}
.piste-type--blue {
    background:#0064B4;
}
.piste-type--red {
    background:#E30613;
}
.piste-type--skiroute {
    background:#E30613;
    border-radius: 0;
    width:1.625rem;
    height:1.625rem;
    margin-left:.125rem;
    transform: rotate(45deg);
}
.piste-type--skiroute.piste-type--small {
    width:1rem;
    height:1rem;
}




.liveinfo-legend__item {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    margin-right:.875rem;
    margin-bottom:.25rem;
}
.liveinfo-legend__icon {
    position: relative;
    top:.25rem;
    margin-right:.25rem;
}
.liveinfo-legend__icon.piste-type--skiroute {
    top:1px;
    margin-right:.5rem;
}