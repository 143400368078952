.main-img-teaser-container,
.hero-teaser-container {
    position: relative;
    top:-100px;
    margin-bottom:-100px;
    @media screen and (max-width: 767px){
        margin:0 -.625rem -60px -.625rem;
        top:-60px;
    }
    @media screen and (min-width: 768px) and (max-width: 1799px) {
        padding-left:100px;
    }
    @media screen and (min-width: 1800px) {
        padding-left:20px;
    }
}
.hero-teaser {
    position: relative;
    height:200px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50% 50%;
    @media screen and (min-width: 768px){
        font-size:1.125rem;
    }
    @media screen and (max-width: 991px){
        margin-bottom:.375rem;
        height:120px;
    }
}
.hero-teaser:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity: 0.45;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    z-index:1;
    transition: all .2s linear;
}
.hero-teaser.is-active:after,
.hero-teaser:focus:after,
.hero-teaser:hover:after {
    background-color: var(--color-primary);
    opacity: 0.66;
}
.hero-teaser__body {
    padding:1.5rem 1rem 1.75rem 1.75rem;
    position: absolute;
    left:0;
    right:0;
    top:0;
    color:#fff;
    font-family: var(--font-special);
    text-shadow: 0 0 10px rgba(0,0,0,.25);
    z-index:5;

    @media screen and (max-width: 991px){
        padding:1rem;
    }
}
.hero-teaser__hl {
    padding-left:3.75rem;
    font-size:1.375rem;
    position: relative;

    @media screen and (max-width: 991px){
        padding-left:0;
    }
}
.hero-teaser__icon {
    position: absolute;
    left:.25rem;
    top:-.625rem;
    font-size:2.625rem;

    @media screen and (max-width: 991px){
        display:none;
    }
}



/* bergbahn heroteaser */
.hero-teaser.hero-teaser--bergbahn {
    @media screen and (max-width: 1199px){
        margin-bottom:.5rem;
        height:175px;
    }
}

.hero-teaser--bergbahn .hero-teaser__hl {
    padding-left: 0;
}
.hero-teaser__icon-bb {
    margin-top:.25rem;
    font-size:2.875rem;
    @media screen and (min-width: 768px){
        font-size:3.75rem;
    }
}
.hero-teaser__icon-bb-circle {
    font-size:2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translateX(-50%) translateY(-50%);
    @media screen and (min-width: 768px){
        font-size:2.75rem;
    }
}
.hero-teaser__icon-bb-weather {
    display: block;
    width: 3rem;
    height:auto;
    margin:0 auto;
    @media screen and (min-width: 768px){
        width: 3.875rem;
        margin:0;
    }
}
.hero-teaser__info-circle {
    position: relative;
    display:inline-block;
}

/* social icons */

.social-icons {
    position: absolute;
    right: 10%;
    bottom: 1rem;

    @media screen and (min-width: 768px){
        bottom: 3rem;
    }
}

.social-icons--with-teasers {
    right: -.5rem;
    left:0;
    bottom: 4.5rem;

    @media screen and (min-width: 768px){
        bottom: 9rem;
        right: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1799px) {
        padding-left:100px;
    }
    @media screen and (min-width: 1800px) {
        padding-left:20px;
    }
}

.social-icons--icon {
    transition: all ease-in-out 0.25s;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    height: 3.5rem;
    width: 3.5rem;
    position: relative;
}

.social-icons--icon > .icon {
    font-size: 2rem;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.social-icons--icon + .social-icons--icon {
    margin-left: 0.75rem;
}

.social-icons--icon:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.social-icons--icon:hover > .icon{
    color: var(--color-default);
}