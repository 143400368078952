.lg-backdrop{
    background-color: var(--color-grey);
}
.lg-download,
#lg-counter {
    display: none;
}
.lg-outer .lg-img-wrap {
    padding: 50px;
}
.lg-outer .lg-video-cont {
    max-width: 80% !important;
}
.lg-outer .lg-video-cont.lg-has-iframe {
    max-width: 75% !important;
}
.lg-toolbar .lg-close:after {
    font-family: iconfont;
    color: var(--color-dark-dark);
    content: var(--icon-close);
}
.lg-toolbar .lg-icon{
    background-color: var(--color-grey);
}
.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
    display: block !important;
    font-family: iconfont;
    content: var(--icon-arrow-left);
    color: var(--color-dark-dark);
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
    border-radius: 0;
    margin-top:-20px;
    background-color: var(--color-grey);
}

.lg-actions .lg-next:before {
    content: var(--icon-arrow-right);
}

.lg-toolbar {
    background-color: transparent;
}


.lg-sub-html{
    color: var(--color-secondary);
    font-size: 1.75rem; /* default */
    font-family: var(--font-special);
    background-color: var(--color-grey);
}

.lg-sub-html .lightbox-caption__headline{
    font-size: 1.75rem; /* presse etc */
}

.lg-sub-html p{
    font-size: 1rem; /* presse etc */
    font-family: var(--font-default);
}

/* video play button */
.video-play-button{
    background-color: hsla(0,0%,100%,.8);
    width: 50px;
    height: 50px;
    font-size: calc(14rem/16);
    color: var(--color-primary);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: background-color 0.2s;

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        width: 60px;
        height: 60px;
    }
}

.video-play-button:hover{
    background-color: #FFF;
}

.video-play-button:before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-45%) translateY(-50%);
}
/* video sizing */
.lg-outer .lg-video-cont:not(.lg-has-iframe) .lg-video {
    width: 100%;
    height: auto;
    padding-bottom: 0;
    overflow: hidden;
    position: relative;
}
.lg-video video {
    object-fit: cover;
}

.lg-outer .lg-video-cont:not(.lg-has-iframe) {
    max-width: 70%!important;
}


@media screen and (max-width: 767px) {
    .lg-actions .lg-prev::after,
    .lg-actions .lg-next::before{
        display: none;
    }
}
.lightbox-item {
    display: block;
    overflow: hidden;
    position: relative;
}
@media screen and (min-width: 768px) {
    .lightbox-zoom-item {
        content:'';
        position: absolute;
        right: -1.5rem;
        top: 0;
        text-align: center;
        z-index:10;
        background: #fff;
        transform: skew(30deg);
        padding: .875rem 3rem .875rem 1.75rem;
    }
    .lightbox-zoom-item .icon {
        position: relative;
        font-size:1.25rem;
        transform: skew(-30deg);
    }
}

@media screen and (max-width: 767px) {
    .lightbox-zoom-item {
        display: none;
    }
}