.list__items-container:not(.is-full-width) .demi-campaign-teaser-col {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
}

.pimcore_area_campaign-wysiwyg-teaser .demi-campaign-teaser-col.demi-campaign-teaser-col {
    flex: 0 0 100%;
    max-width: 100%;
}
@media screen and (min-width: 768px) {
    .pimcore_area_campaign-wysiwyg-teaser .demi-campaign-teaser__bg {
        min-height:500px;
    }
}

.demi-campaign-teaser {
    height:100%;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        min-height:350px;
        margin-bottom:1rem;
    }
}

@media screen and (min-width: 768px) {
    .list-with-map-container .demi-campaign-teaser.demi-campaign-teaser__bg {
        min-height:400px;
    }
}
.demi-campaign-teaser--split {
    color:#fff;
    background: var(--color-primary);
}
.demi-campaign-teaser--split>.row {
    height:100%;
}
.demi-campaign-teaser__bg {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    min-height:200px;
}
.demi-campaign-teaser:not(.demi-campaign-teaser--split):after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity: 0.45;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    z-index:1;
    transition: all .2s linear;
}
.demi-campaign-teaser:focus:after,
.demi-campaign-teaser:hover:after {
    opacity: 0.66;
}
.demi-campaign-teaser .teaser__body {
    padding:1.125rem;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    color:#fff;
    text-shadow: 0 0 10px rgba(0,0,0,.25);
    z-index:5;

    @media screen and (min-width: 768px) {
        padding:1.875rem;
    }
}
.demi-campaign-teaser--split .teaser__body.teaser__body {
    position: relative;
    left:auto;
    right:auto;
    bottom:auto;
    text-shadow: none;
    padding:1rem 1.375rem 1rem 1rem;

    @media screen and (min-width: 768px) {
        padding-bottom:1.75rem;
    }
}
.demi-campaign-teaser .teaser__hl {
    /*max-width:290px;*/
    line-height:1;
    margin:.25rem 0 .5rem 0;
    position: relative;
    font-family: var(--font-special);
    font-size: calc(19rem/16);
    @media screen and (max-width: 767px) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow:hidden;
    }
    @media screen and (min-width: 768px) {
        font-size: calc(28rem/16);
        margin:.5rem 0;
    }
    @media screen and (min-width: 1440px) {
        font-size: calc(32rem/16);
        margin:.75rem 0 .5rem 0;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
        word-break: break-all;
    }
}
.demi-campaign-teaser .teaser__decorator {
    width:10rem;
    height:.75rem;
    margin:1rem 0;
    background:url(/static/img/hl-pattern-white.svg) 0 0 no-repeat;
}
.demi-campaign-teaser .teaser__badge {
    position: absolute;
    right: -6.75rem;
    top: -3.5rem;
    z-index:10;
    @media screen and (max-width: 767px) {
        top: -3.125rem;
    }
}
.demi-campaign-teaser.demi-campaign-teaser--split .teaser__badge {
    right:auto;
    left:-1.5rem;
    top:9rem;
    @media screen and (max-width: 767px) {
        top:8.5rem;
    }
}
.demi-campaign-teaser .teaser__badge-inner{
    padding: .375rem 3.5rem;
    font-size:18px;
    line-height: 1.5;
    color:#fff;
    font-family: var(--font-default-bold);
    background: var(--color-primary);
    transform-origin: left center;
    transform: rotate(45deg);
    text-align: center;
    width: 18rem;
    @media screen and (min-width: 1920px) {
        font-size:1.125rem;
    }
    @media screen and (max-width: 767px) {
        font-size:13px;
        width: 16.125rem;
    }
}
.demi-campaign-teaser.demi-campaign-teaser--split .teaser__badge-inner{
    transform: rotate(-45deg);
}