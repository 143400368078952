.embed-responsive-heroteaser:before {
    padding-top: 74%;

    @media screen and (min-width: 768px) {
        padding-top: 54%;
    }
}
.embed-responsive-teaser:before {
    padding-top: 120.7547%;
}
.embed-responsive-teaser-big:before {
    padding-top: 66.6667%;

    @media screen and (min-width: 768px) {
        padding-top:133.3%;
    }
}
.embed-responsive-teaser-high:before {
    padding-top: 66.66667%;

    @media screen and (min-width: 768px) {
        padding-top:125%;
    }
}
.embed-responsive-teaser-wide:before {
    padding-top: 59.25%;
}
.embed-responsive-portalmap:before {
    padding-top: 54.79452054794521%;
}
.embed-responsive-intermaps:before {
    padding-top: 100%;
    @media screen and (min-width: 768px) {
        padding-top: 40%;
    }
}
.embed-responsive-2by1:before {
    padding-top: 49.3333%;
}
.embed-responsive-3by2:before {
    padding-top: 66.666666667%;
}
.embed-responsive-detail-header:before {
    padding-top: 56%;
}
.embed-responsive-teaser-blog--landscape:before {
    padding-top: 49.3333%;
    @media screen and (max-width: 767px) {
        padding-top:100%;
    }
}

.embed-responsive-map:before {
    padding-top: 150%;

    @media screen and (min-width: 768px) {
        padding-top: 45%;
    }
}

.embed-responsive-ts-map:before {
    padding-top: 165%;

    @media screen and (min-width: 768px) {
        padding-top: 100%;
    }
}

.embed-responsive-tourmap-xs:before {
    padding-top: 133%;
}


.embed-responsive-teaser-row-sm:before {
    padding-top: 100%;
}
.embed-responsive-teaser-row-md:before {
    padding-top: 100%;
    @media screen and (min-width: 768px) {
        padding-top: 74.5%;
    }
}
.embed-responsive-teaser-row-lg:before {
    padding-top: 100%;
    @media screen and (min-width: 768px) {
        padding-top: 59.35%;
    }
}






/* cover */
.embed-responsive-cover {
    height:100%;
}
.embed-responsive-cover .embed-responsive-item {
    width:auto;
    min-height:100%;
    left:50%;
    transform: translateX(-50%);
}