.demi-destination-package-list {
    margin-top: 1.5rem !important;
    @media screen and (min-width: 768px) {
        margin-top: 3rem !important;
    }
}

.pimcore_area_demi-destinationpackage-list .card-body .embed-responsive {
    margin:-1.25rem -1.25rem .25rem -1.25rem;
    width: calc(100%+2.5rem);
}
.pimcore_area_demi-destinationpackage-list .card-body .btn-success {
    background: var(--color-booking) !important;
    border-color: var(--color-booking) !important;
}

.pimcore_area_demi-destinationpackage-list .card-body .col {
    flex: 0 0 100%;
    max-width: 100%;
    margin: .5rem 0;
}