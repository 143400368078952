.watchlist-collection__btn {
    color: white!important;

    @media screen and (min-width: 768px) {
        width: 100%;
    }
}

.watchlist-collection-teaser__img {
    height: 100%;
    width: auto;
    position: absolute;
    left: 50%;
}

.watchlist-collection__btn-icon {
    @media screen and (min-width: 768px) {
        font-size: calc(12rem/16);
    }
}

.watchlist-collection__select {
    border: none;
    border-bottom: 1px solid #3e363f;
    font-size: calc(14rem/16);
    padding: .6rem 1rem;

    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        padding: .8rem 1rem;
    }
}

.watchlist-collection-teaser {
    background: #fff;
    padding:.375rem;
    box-shadow: 0 0 4px 0 rgba(62,54,63,0.1);
    margin-bottom:.5rem;
    font-size:.875rem;
    color:var(--color-text-muted);

    @media screen and (min-width: 768px) {
        margin-bottom: 1.5rem;
    }
}

.watchlist-collection-teaser__info {
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.watchlist-collection-teaser__headline {
    color: var(--color-primary);
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    margin-bottom: calc(60rem/16);
    margin-top: .75rem;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        font-size: calc(18rem/16);
        margin-bottom: calc(100rem/16);
    }
}
.watchlist-collection-teaser__headline a:hover {
    text-decoration: underline;
    color: var(--color-primary-dark);
}
.watchlist-collection-teaser__text-wrapper {
    @media screen and (max-width: 767px) {
        margin-bottom: .75rem;
    }
}
.watchlist-collection-teaser__text {
    font-family: var(--font-default-bold);
}
.watchlist-collection-teaser__btn-wrapper {
    @media screen and (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    @media screen and (min-width: 768px) {
        max-width: calc(130rem/16);
        min-width: calc(130rem/16);
    }
}
.watchlist-collection-teaser__btn {
    @media screen and (max-width: 767px) {
        width: 48%;
        text-align: center;
    }
    @media screen and (min-width: 768px) {
        max-width: calc(130rem/16);
        min-width: calc(130rem/16);
        text-align: left;
    }
}
.watchlist-collection-teaser__btn:hover, .watchlist-collection-teasr__btn:active {
    background-color: var(--color-default-light);
}


/*------------ green modal ------------*/

.modal--green-small {
    @media screen and (min-width: 768px) {
        width: calc(340rem/16);
    }
}
.modal--green-small__content {
    background-color: var(--color-primary);
    color: white;
}
.modal--green-small__body {
    @media screen and (min-width: 768px) {
        padding: 1rem;
    }
}
.modal--green-small__body  .form-group {
    margin-bottom: 0;
}
.modal--green-small__body  .form-group .form-control-label--small  {
    color: white;
    top: 0;

    padding-bottom: calc(4rem/16);
       font-size: calc(16rem/16);
}

.modal--green-small__footer {
    border-top: none;
}

.modal--green-small input, .modal--green-small select, .modal--green-small input:focus, .modal--green-small select:focus {
    background-color: white;
    border-radius: calc(5rem/16);
    padding: calc(5rem/16) calc(10rem/16);
    border: calc(1rem/16) solid #3e363f;
}
.modal--green-small .form-control-label span {
    color:white;
}
.modal--green-small .filter-dd-toggle:after, .select:after {
    top:auto;
    bottom:.75rem;
    color:var(--color-text-default);
}
.modal--green-small__body .custom-checkbox {
    margin-top: 20px;
}
.modal--green-small__btn {
    background-color: white;
    color: var(--color-primary);
}
.modal--green-small__btn .icon {
    font-size: calc(12rem/16);
    padding-right: calc(4rem/16);
}
.modal--green-small__close {
    color: white;
    font-size: calc(12rem/16);
    top: calc(8rem/16);

}
.modal--green-small__close:hover {
    color: white;
}

.watchlist-collection-teaser__share {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
        font-size: calc(25rem/16);
    }
}

.watchlist-collection-teaser__share-link {
    text-decoration: none!important;
}
.watchlist-collection-teaser__share-text {
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: 0.5;
    }
}