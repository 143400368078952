@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1734424535184/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1734424535184/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1734424535184/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-abstieg:before { content: "\EA01" }
.icon-achtung:before { content: "\EA02" }
.icon-action:before { content: "\EA03" }
.icon-addthis:before { content: "\EA04" }
.icon-addtocart:before { content: "\EA05" }
.icon-alert:before { content: "\EA06" }
.icon-anlagen:before { content: "\EA07" }
.icon-anreise:before { content: "\EA08" }
.icon-arrow-big:before { content: "\EA09" }
.icon-arrow-down:before { content: "\EA0A" }
.icon-arrow-full-left:before { content: "\EA0B" }
.icon-arrow-left:before { content: "\EA0C" }
.icon-arrow-right:before { content: "\EA0D" }
.icon-arrow-tablescroll:before { content: "\EA0E" }
.icon-arrow:before { content: "\EA0F" }
.icon-aufstieg:before { content: "\EA10" }
.icon-auto:before { content: "\EA11" }
.icon-avatar:before { content: "\EA12" }
.icon-babylift:before { content: "\EA13" }
.icon-baden:before { content: "\EA14" }
.icon-bahn:before { content: "\EA15" }
.icon-bed:before { content: "\EA16" }
.icon-beschreibung:before { content: "\EA17" }
.icon-bestellungen:before { content: "\EA18" }
.icon-bike:before { content: "\EA19" }
.icon-biken:before { content: "\EA1A" }
.icon-buchen:before { content: "\EA1B" }
.icon-burger-search:before { content: "\EA1C" }
.icon-burger:before { content: "\EA1D" }
.icon-busse:before { content: "\EA1E" }
.icon-calendar:before { content: "\EA1F" }
.icon-cart:before { content: "\EA20" }
.icon-check:before { content: "\EA21" }
.icon-close:before { content: "\EA22" }
.icon-closed:before { content: "\EA23" }
.icon-copy:before { content: "\EA24" }
.icon-couple:before { content: "\EA25" }
.icon-cow:before { content: "\EA26" }
.icon-delete:before { content: "\EA27" }
.icon-document:before { content: "\EA28" }
.icon-download:before { content: "\EA29" }
.icon-edit:before { content: "\EA2A" }
.icon-einseil-umlaufbahn:before { content: "\EA2B" }
.icon-elements:before { content: "\EA2C" }
.icon-erlebniswelten:before { content: "\EA2D" }
.icon-facebook:before { content: "\EA2E" }
.icon-fahrbar:before { content: "\EA2F" }
.icon-familie:before { content: "\EA30" }
.icon-family:before { content: "\EA31" }
.icon-family2:before { content: "\EA32" }
.icon-flower:before { content: "\EA33" }
.icon-flower2:before { content: "\EA34" }
.icon-flug:before { content: "\EA35" }
.icon-foerderband:before { content: "\EA36" }
.icon-fotos:before { content: "\EA37" }
.icon-geochallenge:before { content: "\EA38" }
.icon-golf:before { content: "\EA39" }
.icon-gondel:before { content: "\EA3A" }
.icon-group:before { content: "\EA3B" }
.icon-gut:before { content: "\EA3C" }
.icon-heart-filled:before { content: "\EA3D" }
.icon-heart:before { content: "\EA3E" }
.icon-heisl:before { content: "\EA3F" }
.icon-hoch:before { content: "\EA40" }
.icon-hotels:before { content: "\EA41" }
.icon-img:before { content: "\EA42" }
.icon-in-vorbereitung:before { content: "\EA43" }
.icon-info:before { content: "\EA44" }
.icon-instagram:before { content: "\EA45" }
.icon-klettern:before { content: "\EA46" }
.icon-kontakt:before { content: "\EA47" }
.icon-kultur:before { content: "\EA48" }
.icon-leaf:before { content: "\EA49" }
.icon-leistung:before { content: "\EA4A" }
.icon-lift:before { content: "\EA4B" }
.icon-lifte:before { content: "\EA4C" }
.icon-link:before { content: "\EA4D" }
.icon-linkedin:before { content: "\EA4E" }
.icon-list:before { content: "\EA4F" }
.icon-location:before { content: "\EA50" }
.icon-loipen:before { content: "\EA51" }
.icon-loyalty:before { content: "\EA52" }
.icon-mail:before { content: "\EA53" }
.icon-menu:before { content: "\EA54" }
.icon-minus:before { content: "\EA55" }
.icon-mute:before { content: "\EA56" }
.icon-open:before { content: "\EA57" }
.icon-panomax:before { content: "\EA58" }
.icon-pause:before { content: "\EA59" }
.icon-phone:before { content: "\EA5A" }
.icon-pinterest:before { content: "\EA5B" }
.icon-piste:before { content: "\EA5C" }
.icon-play:before { content: "\EA5D" }
.icon-plus:before { content: "\EA5E" }
.icon-poi:before { content: "\EA5F" }
.icon-present:before { content: "\EA60" }
.icon-pressemappen:before { content: "\EA61" }
.icon-print:before { content: "\EA62" }
.icon-restaurants:before { content: "\EA63" }
.icon-schlepper:before { content: "\EA64" }
.icon-search:before { content: "\EA65" }
.icon-sehrgut:before { content: "\EA66" }
.icon-send:before { content: "\EA67" }
.icon-sessellift:before { content: "\EA68" }
.icon-sessellift_acht:before { content: "\EA69" }
.icon-sessellift_drei:before { content: "\EA6A" }
.icon-sessellift_sechs:before { content: "\EA6B" }
.icon-sessellift_vier:before { content: "\EA6C" }
.icon-sessellift_zwei:before { content: "\EA6D" }
.icon-share:before { content: "\EA6E" }
.icon-single:before { content: "\EA6F" }
.icon-ski:before { content: "\EA70" }
.icon-skifahrer:before { content: "\EA71" }
.icon-skiroute:before { content: "\EA72" }
.icon-skiticket:before { content: "\EA73" }
.icon-snow:before { content: "\EA74" }
.icon-snowflake:before { content: "\EA75" }
.icon-sommercard:before { content: "\EA76" }
.icon-sonstige:before { content: "\EA77" }
.icon-stern-bewerten:before { content: "\EA78" }
.icon-strecke:before { content: "\EA79" }
.icon-sun:before { content: "\EA7A" }
.icon-sun2:before { content: "\EA7B" }
.icon-tent:before { content: "\EA7C" }
.icon-thermometer:before { content: "\EA7D" }
.icon-thumb-down:before { content: "\EA7E" }
.icon-thumb-up:before { content: "\EA7F" }
.icon-tief:before { content: "\EA80" }
.icon-tragseilbahn:before { content: "\EA81" }
.icon-train:before { content: "\EA82" }
.icon-transfer:before { content: "\EA83" }
.icon-treehouse:before { content: "\EA84" }
.icon-triangle:before { content: "\EA85" }
.icon-twitter:before { content: "\EA86" }
.icon-uhr:before { content: "\EA87" }
.icon-unmute:before { content: "\EA88" }
.icon-wandern:before { content: "\EA89" }
.icon-wasser:before { content: "\EA8A" }
.icon-water:before { content: "\EA8B" }
.icon-webcam:before { content: "\EA8C" }
.icon-wetter:before { content: "\EA8D" }
.icon-whatsapp:before { content: "\EA8E" }
.icon-youtube:before { content: "\EA8F" }


:root {
--icon-abstieg: "\EA01";
    --icon-achtung: "\EA02";
    --icon-action: "\EA03";
    --icon-addthis: "\EA04";
    --icon-addtocart: "\EA05";
    --icon-alert: "\EA06";
    --icon-anlagen: "\EA07";
    --icon-anreise: "\EA08";
    --icon-arrow-big: "\EA09";
    --icon-arrow-down: "\EA0A";
    --icon-arrow-full-left: "\EA0B";
    --icon-arrow-left: "\EA0C";
    --icon-arrow-right: "\EA0D";
    --icon-arrow-tablescroll: "\EA0E";
    --icon-arrow: "\EA0F";
    --icon-aufstieg: "\EA10";
    --icon-auto: "\EA11";
    --icon-avatar: "\EA12";
    --icon-babylift: "\EA13";
    --icon-baden: "\EA14";
    --icon-bahn: "\EA15";
    --icon-bed: "\EA16";
    --icon-beschreibung: "\EA17";
    --icon-bestellungen: "\EA18";
    --icon-bike: "\EA19";
    --icon-biken: "\EA1A";
    --icon-buchen: "\EA1B";
    --icon-burger-search: "\EA1C";
    --icon-burger: "\EA1D";
    --icon-busse: "\EA1E";
    --icon-calendar: "\EA1F";
    --icon-cart: "\EA20";
    --icon-check: "\EA21";
    --icon-close: "\EA22";
    --icon-closed: "\EA23";
    --icon-copy: "\EA24";
    --icon-couple: "\EA25";
    --icon-cow: "\EA26";
    --icon-delete: "\EA27";
    --icon-document: "\EA28";
    --icon-download: "\EA29";
    --icon-edit: "\EA2A";
    --icon-einseil-umlaufbahn: "\EA2B";
    --icon-elements: "\EA2C";
    --icon-erlebniswelten: "\EA2D";
    --icon-facebook: "\EA2E";
    --icon-fahrbar: "\EA2F";
    --icon-familie: "\EA30";
    --icon-family: "\EA31";
    --icon-family2: "\EA32";
    --icon-flower: "\EA33";
    --icon-flower2: "\EA34";
    --icon-flug: "\EA35";
    --icon-foerderband: "\EA36";
    --icon-fotos: "\EA37";
    --icon-geochallenge: "\EA38";
    --icon-golf: "\EA39";
    --icon-gondel: "\EA3A";
    --icon-group: "\EA3B";
    --icon-gut: "\EA3C";
    --icon-heart-filled: "\EA3D";
    --icon-heart: "\EA3E";
    --icon-heisl: "\EA3F";
    --icon-hoch: "\EA40";
    --icon-hotels: "\EA41";
    --icon-img: "\EA42";
    --icon-in-vorbereitung: "\EA43";
    --icon-info: "\EA44";
    --icon-instagram: "\EA45";
    --icon-klettern: "\EA46";
    --icon-kontakt: "\EA47";
    --icon-kultur: "\EA48";
    --icon-leaf: "\EA49";
    --icon-leistung: "\EA4A";
    --icon-lift: "\EA4B";
    --icon-lifte: "\EA4C";
    --icon-link: "\EA4D";
    --icon-linkedin: "\EA4E";
    --icon-list: "\EA4F";
    --icon-location: "\EA50";
    --icon-loipen: "\EA51";
    --icon-loyalty: "\EA52";
    --icon-mail: "\EA53";
    --icon-menu: "\EA54";
    --icon-minus: "\EA55";
    --icon-mute: "\EA56";
    --icon-open: "\EA57";
    --icon-panomax: "\EA58";
    --icon-pause: "\EA59";
    --icon-phone: "\EA5A";
    --icon-pinterest: "\EA5B";
    --icon-piste: "\EA5C";
    --icon-play: "\EA5D";
    --icon-plus: "\EA5E";
    --icon-poi: "\EA5F";
    --icon-present: "\EA60";
    --icon-pressemappen: "\EA61";
    --icon-print: "\EA62";
    --icon-restaurants: "\EA63";
    --icon-schlepper: "\EA64";
    --icon-search: "\EA65";
    --icon-sehrgut: "\EA66";
    --icon-send: "\EA67";
    --icon-sessellift: "\EA68";
    --icon-sessellift_acht: "\EA69";
    --icon-sessellift_drei: "\EA6A";
    --icon-sessellift_sechs: "\EA6B";
    --icon-sessellift_vier: "\EA6C";
    --icon-sessellift_zwei: "\EA6D";
    --icon-share: "\EA6E";
    --icon-single: "\EA6F";
    --icon-ski: "\EA70";
    --icon-skifahrer: "\EA71";
    --icon-skiroute: "\EA72";
    --icon-skiticket: "\EA73";
    --icon-snow: "\EA74";
    --icon-snowflake: "\EA75";
    --icon-sommercard: "\EA76";
    --icon-sonstige: "\EA77";
    --icon-stern-bewerten: "\EA78";
    --icon-strecke: "\EA79";
    --icon-sun: "\EA7A";
    --icon-sun2: "\EA7B";
    --icon-tent: "\EA7C";
    --icon-thermometer: "\EA7D";
    --icon-thumb-down: "\EA7E";
    --icon-thumb-up: "\EA7F";
    --icon-tief: "\EA80";
    --icon-tragseilbahn: "\EA81";
    --icon-train: "\EA82";
    --icon-transfer: "\EA83";
    --icon-treehouse: "\EA84";
    --icon-triangle: "\EA85";
    --icon-twitter: "\EA86";
    --icon-uhr: "\EA87";
    --icon-unmute: "\EA88";
    --icon-wandern: "\EA89";
    --icon-wasser: "\EA8A";
    --icon-water: "\EA8B";
    --icon-webcam: "\EA8C";
    --icon-wetter: "\EA8D";
    --icon-whatsapp: "\EA8E";
    --icon-youtube: "\EA8F";
    
}