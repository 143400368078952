.custom-checkbox {
    position: relative;
    padding-left: calc(20rem/16);
}
.custom-checkbox a {
    color:var(--color-primary);
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    overflow: hidden;
    border: 2px solid var(--color-text-default);
    background: #ffffff;
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius:2px;
    margin-top: .2em;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: .5rem;
    color: var(--color-text-default);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box:before {
    color: var(--color-primary);
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    /*font-size:1.125rem;*/
    padding-left:.75rem;
}


/* box Checkbox */
.custom-checkbox--box {
    padding:0;
    margin-bottom:1rem;
    height:calc(100% - 1rem);
    @media screen and (max-width: 767px){
        margin-bottom:1.5rem;
        height:calc(100% - 1.5rem);
    }
}
.custom-checkbox--box label{
    position: relative;
    background: #fff;
    height:100%;
    padding: calc(18rem/16) calc(8rem/16);
    transition: all 250ms ease;

    @media screen and (max-width: 767px){
        box-shadow: 0 0 calc(6rem/16) 0 rgba(0, 0, 0, 0.07);
    }
}
.custom-checkbox--box .icon{
    font-size: 2.125rem;
    margin: .5rem 0;
}
.custom-checkbox--box label.is-checked,
.custom-checkbox--box label:hover{
    background:var(--color-primary);
    color:#fff;
    box-shadow: 0 0 calc(15rem/16) 0 rgba(0,0,0,0.12);
}

.custom-checkbox--box .custom-checkbox__box{
    float: none;
    width: calc(24rem/16);
    height: calc(24rem/16);
    border-radius: 50%;
    border:0;
    background-color: #fff;
    position: absolute;
    bottom: calc(-18rem/16);
    left: 50%;
    transform: translateX(-50%);
    display: none;
}


.custom-checkbox--box .custom-checkbox__box:before{
    color: var(--color-primary) !important;
}

.custom-checkbox--box label.is-checked .custom-checkbox__box{
    display: block;
}

.custom-checkbox--box .custom-checkbox__text{
    font-size: calc(16rem/16);
    line-height: calc(22/18);
    padding-left:0;
}