.geolocation-paging__left {
    font-size: calc(10rem/16);
    transform: rotate(90deg);
}
.geolocation-paging__right {
    font-size: calc(10rem/16);
    transform: rotate(-90deg);
}
.geolocation-paging__page-link {
    position: relative;
}
.geolocation-paging__page-link:before {
    width: 1.125rem;
    height: 1.125rem;
    background-color: var(--color-grey);
    position: absolute;
    content: '';
    z-index: 100000;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    right: 0;
    text-align: center;
    left: 50%;
}
.geolocation-paging__page-item.active .geolocation-paging__page-link:before {
    background-color: var(--color-primary);
}
.geolocation-paging__page-arrow {
    position: relative;
}
.geolocation-paging__page-arrow:before {
    content: var(--icon-arrow-down);
    font-family: iconfont;
    position: absolute;
    font-size: calc(10rem/16);
    top: calc(11rem/16);
    color: var(--color-primary);
}
.arrow-left.geolocation-paging__page-arrow:before {
    left: 0;
    transform: rotate(90deg);
}
.arrow-right.geolocation-paging__page-arrow:before {
    right: 0;
    transform: rotate(-90deg);
}
.disabled .geolocation-paging__page-arrow:before {
    color: var(--color-grey);
}