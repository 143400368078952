/* personal recommendation CA */

.personal-recommendation-area {
    position: relative;
    color: var(--color-white);
    background-color: var(--color-text-default);
    overflow: hidden;
    padding: 2rem 1rem;
    @media screen and (min-width: 768px){
        padding: 3rem;
    }
}
.personal-recommendation-area .teaser__badge {
    position: absolute;
    right: -6.75rem;
    top: -2.75rem;
    z-index: 10;
}
.personal-recommendation-area .teaser__badge-inner {
    padding: .375rem 3.5rem;
    font-size: 18px;
    line-height: 1.5;
    color: #fff;
    font-family: var(--font-default-bold);
    background: var(--color-primary);
    transform-origin: left center;
    transform: rotate(45deg);
    text-align: center;
    width: 18rem;
}
.personal-recommendation-area .teaser__badge-inner .icon {
    position: relative;
    top: .25rem;
    margin-right: .25rem;
}

/* personal recommendation pop-up */

.personal-recommendation-popup {
    position: fixed;
    z-index: 1000;
    color: #fff;
    background-color: var(--color-primary);
    top: calc(50rem/16);
    left: 0;
    right: 0;
    padding: 1rem 2.5rem 1rem 1rem;
    @media screen and (min-width: 768px){
        left: calc(100rem/16);
        top: calc(100rem/16);
        max-width: 34.25rem;
        padding: 1.5rem 3rem 1.5rem 1.5rem;
    }
}

.personal-recommendation-popup__close {
    padding: .5rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
}