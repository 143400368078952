.floating-label {
    position: absolute;
    left: .75rem;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    white-space: nowrap;
    z-index: 1;
}
.form-group.form-group--with-icon-left .floating-label{
    text-indent:1.375rem;
}
.floating-label.has-value,
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(-1.875rem) translateX(-.75rem) scale(.75);
    color:inherit;
    padding:0;
    text-indent:0;

    @media screen and (min-width: 768px) {
        transform: translateY(-2rem) translateX(-.75rem) scale(.75);
    }
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label{
    transform: translateY(-1.875rem) translateX(-.75rem) scale(.75);
    color:inherit;
    padding:0;
    text-indent:0;

    @media screen and (min-width: 768px) {
        transform: translateY(-2rem) translateX(-.75rem) scale(.75);
    }
}

/* strange bugfix */
select ~ .floating-label {
    transform: translateY(-1.875rem) translateX(-.75rem) scale(.75);
    @media screen and (min-width: 768px) {
        transform: translateY(-2rem) translateX(-.75rem) scale(.75);
    }
}


/* floating label in textarea */
textarea ~ .floating-label__label {
    top:.75rem;

    @media screen and (min-width: 768px) {
        top: 1rem;
    }
}