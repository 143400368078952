.login-form{
     background-color: var(--color-white);
     border: 1px solid var(--color-grey);
     padding: 2rem;

    @media screen and (max-width: 767px) {
        padding: 1rem;
    }
}

.login-form__sommercard{
    @media screen and (max-width: 767px) {
        display:none;
    }
}