.watchlist-popup {
    position: relative;
    padding-top: calc(10rem/16);
    width: calc(300rem/16);
    z-index:1000;
}
.watchlist-popup:before {
    content: '';
    width: 0;
    height: 0;
    border-left: calc(10rem/16) solid transparent;
    border-right: calc(10rem/16) solid transparent;
    border-bottom: calc(10rem/16) solid var(--color-primary);
    position: absolute;
    top: calc(1rem/16);
    left: 50%;
    transform: translateX(-50%);
}
.watchlist-popup--title {
    font-family: var(--font-default-bold);
    padding-right: calc(20rem/16)
}
.watchlist-popup.bs-popover-top:before {
    transform:translateX(-50%) rotate(180deg);
    top:auto;
    bottom:calc((9rem/16)*-1);
}
.watchlist-popup__wrapper {
    background-color: var(--color-primary);
    color: white;
    padding: calc(15rem/16) calc(20rem/16);
    height: fit-content;
    border: calc(1rem/16) solid white;
}
.watchlist-popup__close {
    position: absolute;
    top: calc(22rem/16);
    right: calc(12rem/16);
    font-size: calc(12rem/16);
    color: white;
}
.watchlist-popup__close:hover {
    color: var(--color-dark-grey);
}

.watchlist-popup__icon-check {
    font-size: 10px;
    padding-right: 6px;
}
.watchlist-popup__icon-plus {
    font-size: 12px;
    padding-right: 4px;
}
.watchlist-popup__list {
    max-height:180px;
    overflow-y:auto;
}

/* ------------- toggle input ------------*/
.watchlist-popup__add .form-group {
    margin-bottom: 0;
}
.watchlist-popup__add .form-control {
    background-color: white;
    padding: calc(5rem/16) calc(10rem/16);
    margin-top: calc(5rem/16);
}
.watchlist-popup__add .form-control-label {
    color: white;
    font-size: calc(13rem/16);
}
.watchlist-popup__add button {
    margin-top: calc(15rem/16);
}

/* --------- send popup -------------*/
.watchlist-popup.watchlist-popup--send {
    padding-top: 0;
    width: auto;
    margin: .5rem;
    @media screen and (min-width: 576px) {
        max-width: 33.75rem;
        width: 33.75rem;
        margin: 0;
    }

}
.watchlist-popup.watchlist-popup--send:before {
    font-size: var(--font-size-default);
    color: white;
    border: none;
}
.watchlist-popup.watchlist-popup--send .form-control {
    font-size: var(--font-size-default);
    //background-color: white;
    //border-radius: 3px;
    //border: 1px solid grey;
    //padding: 5px;
    //font-size: 16px;
}
.watchlist-popup.watchlist-popup--send .form-control-label {
    display: block;
    font-size: var(--font-size-default);
}
.watchlist-popup--send .watchlist-popup__wrapper {
    background-color: white;
    color: var(--color-default);
    padding: 0;
}
.watchlist-popup--send .watchlist-popup__title{
    font-size: 1.25rem;
    font-family: var(--font-special);
    color: var(--color-default);

    @media screen and (min-width: 768px) {
        font-size: 1.625rem;
    }
}
.watchlist-popup--send__footer {
    border-top: calc(1rem/16) solid #dee2e6;
}
.watchlist-popup--send__body {
    @media screen and (min-width: 768px) {
        padding: 1rem 4rem;
    }
}
.watchlist-popup--send__body, .watchlist-popup--send__footer, .watchlist-popup--send .watchlist-popup__title {
    padding: 1rem;
}
.watchlist-popup--send .watchlist-popup__close{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1rem;
    opacity: .33;
    margin: 0;
    padding: 1.5rem;
    cursor: pointer;
    z-index: 1;
    color: #000;
}
.watchlist-popup--send .watchlist-popup__close:hover {
    opacity: 1;
}