.skip-links,
.toc{
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    z-index: 999999999;
}
.toc a{
    display:block;
    padding:.5rem;
    color:#fff;
    background: var(--color-primary);
}