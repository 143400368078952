@media (min-width: 576px){
    .modal-dialog {
        max-width: 33.75rem;
    }
}
.modal-header .close {
    position: absolute;
    right:0;
    top:0;
    font-size:1rem;
    opacity: .33;
    margin: 0;
    padding:1.5rem;
    cursor: pointer;
    z-index: 1;
}
.modal-header .close:hover,
.modal-header .close:focus {
    opacity: .75;
}
.modal-body {
    @media screen and (min-width: 768px) {
        padding: 1rem 4rem;
    }
}
.modal-dialog.modal-lg {
    @media (min-width: 576px){
        max-width: calc(800rem /16);
    }
}