.watchlist-send-form__title-group {
    display: flex;
    align-items: center;
}

.watchlist-send-form__input {
    max-width: calc(300rem/16);
}
.watchlist-send-form__textarea textarea {
    @media screen and (min-width: 768px) {
        max-width: calc(600rem/16);
        min-height: calc(450rem/16);
    }
}