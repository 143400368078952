footer {
    position: relative;
    margin-top:4rem;
    @media screen and (max-width: 767px) {
        margin-bottom:4rem;
        margin-top:2rem;
    }
}

/* service footer */
.service-footer {
    font-size:1rem;
    color:#fff;
    text-align: center;
    position: relative;
    padding:8rem 0;
    background-size:cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow:hidden;

    @media screen and (max-width: 767px) {
        padding:3rem 0;
    }
}
.service-footer:before {
    content:'';
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background-color: #3E363F;
    opacity: 0.7;
}
.service-footer__col {
    position: relative;
}
.service-footer__col .h3,
.service-footer__col h3 {
    font-size:2.75rem;
    font-family:var(--font-special);
    @media screen and (max-width: 767px) {
        font-size:1.75rem;
    }
}
@media screen and (min-width: 768px) {
    .service-footer__col + .service-footer__col {
        border-left:1px solid #fff;
    }
}
@media screen and (max-width: 767px) {
    .service-footer__col + .service-footer__col:before {
        content:'';
        display: block;
        width: 7.5rem;
        position: relative;
        margin:2rem auto;
        border-top:1px solid #fff;
    }
}
.service-footer__hotline {
    font-family: var(--font-default-bold);
    font-size:1.375rem;
    @media screen and (min-width: 768px) {
        font-size:1.75rem;
    }
}
.footer__social-list,
.footer__contact-list,
.footer__partner-list {
    margin:1.75rem auto;
    font-size:.875rem;
    color:var(--color-dark-grey);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        margin:2rem auto;
    }
}
.footer__partner-list > li {
    margin:0 .5rem .5rem 0 !important;
    @media screen and (min-width: 768px) {
        margin-right:3rem !important;
    }
}
.footer__social-list > li {
    margin-left:.875rem;
    font-size:1.375rem;
}
.footer__social-list > li a:hover,
.footer__social-list > li a:focus {
    color:var(--color-dark);
}
@media screen and (min-width: 768px) {
    .footer__contact-list > li+li:before {
        content:'·';
        position: relative;
        font-size:.75rem;
        top:-.125rem;
        margin-right:.5rem;
        color:var(--color-dark-grey);
        font-family: var(--font-default-bold);
    }
}
.footer__partner-list-img {
    display: inline-block;
    width:auto;
    height:2.25rem;
}

/* footer */



/* foot */
.foot {
    font-size:.75rem;
    padding:1.125rem 0;
    border-top:1px solid var(--color-light-grey);

    @media screen and (min-width: 768px) {
        padding:2rem 0;
    }
}
.foot__link-list {
    font-size:.875rem;
    color:var(--color-dark-grey);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        margin:1rem 0;
    }
}
.foot__link-list > li {
    margin:0 .625rem;
}
.foot__link-list a {
    color:inherit;
}
.foot__link-list a:hover,
.foot__link-list a:focus {
    color:var(--color-text-default);
}







/* scroll top btn */
.scroll-top-btn {
    position: fixed;
    bottom: 1rem;
    right:1rem;
    width:40px;
    height: 40px;
    display: block;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.33);
    z-index:800;
    @media screen and (max-width: 767px) {
        bottom: 5rem;
    }
}
.scroll-top-btn--hide-mobile {
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.scroll-top-btn:before {
    transform: rotate(-45deg);
    position: absolute;
    left: 12px;
    top:15px;
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #2a2a2a;
    border-width: 1px 1px 0 0;
}