.gallery-slider-main {
    position: relative;
}
.gallery-slider__arrows {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: .375rem 3rem;
    background: #fff;
    z-index: 1;
    font-size: .875rem;
}
.gallery-slider__arrows--center {
    right: unset;
}

.gallery-slider__img{
    object-fit: cover;
}

/* thumb slider */
.gallery-slider__thumb {
    margin:.75rem .375rem;
}
.gallery-slider__thumbs .slick-slide {
    opacity: .55;
}
.gallery-slider__thumbs .slick-slide.slick-current {
    opacity:1;
}