.on-page-nav {
    position: fixed;
    top: 3.125rem;
    left: 0;
    right: 0;
    bottom: auto;
    background: #fff;
    z-index: 850;
    box-shadow: 0 0 .25rem rgba(0, 0, 0, .3);
    transform: translateY(-120%);
    transition: transform 120ms ease-out;
    will-change: transform;

    @media screen and (min-width: 768px) and (max-width: 1399px) {
        top: 0;
        left: clamp(4rem, 3vw, 6.25rem);
    }

    @media screen and (min-width: 1400px) {
        top: 0;
        left: 100px;
    }

    @media screen and (max-width: 767px) {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

.on-page-nav.is-visible {
    transform: translateY(0);
}

.is-affiliate .on-page-nav.on-page-nav {
    @media screen and (min-width: 768px) {
        left: 0;
    }
    @media screen and (max-width: 767px) {
        top: 0;
        transform: translateY(0);
    }
}

.on-page-nav__list {
    flex-direction: row;
}

.on-page-nav__list > li {
    display: inline-block;
    margin-left: 1.25rem;
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-left: .75rem;
    }
}

.on-page-nav.navbar a.nav-link.on-page-nav__link {
    position: relative;
    display: inline-block;
    padding: .625rem .25rem;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    font-family: var(--font-default);
    color: var(--color-text-muted);
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: .75rem .25rem;
        font-size: 12px;
    }
    @media screen and (min-width: 1200px) {
        padding: 1.25rem .25rem;
        font-size: 14px;
    }
}

.on-page-nav.navbar a.nav-link.on-page-nav__link.active {
    font-family: var(--font-default-bold);
}

.on-page-nav__link.active:before {
    content: '';
    position: absolute;
    left: -19999px;
    bottom: 0;
    right: 0rem;
    width: auto;
    height: .375rem;
    background: var(--color-primary);
}

.on-page-nav__link.active:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 0 6px;
    border-color: transparent transparent transparent var(--color-primary);
    position: absolute;
    bottom: 0;
    right: -.375rem;
}