@font-face {
    font-family: 'Roboto_Medium';
    src: url('/static/fonts/Roboto-Medium.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

.btn-oauth__wrapper {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.btn-oauth {
    border-radius: calc(4rem/16);
    padding: calc(8rem/16) calc(8rem/16);
    height: calc(40rem/16);
    font-size: calc(17.2rem/16);
    min-width: calc(140rem/16);
    text-align: center;
    margin-top: calc(15rem/16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }
}

.btn-oauth:hover{
    color: #ffffff;
}

.btn-oauth__facebook {
    background-color: #1877F2;
    color: #ffffff;
}

.btn-oauth__google {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.5);
    font-size: calc(14rem/16);
    text-align: left;
    padding-top: calc(4.5rem/16);
    padding-bottom: calc(4.5rem/16);
    font-family: "Roboto_Medium";
}

.btn-oauth__google:hover {
    color: rgba(0, 0, 0, 0.5);
}

.btn-oauth__google:focus {
    color: rgba(0, 0, 0, 0.5);
    outline: 4px solid blue;
}

.btn-oauth__apple {
    color: #ffffff;
    background-color: #000000;
}

.btn-oauth__google-icon {
    margin-right: calc(24rem/16);
    height: calc(30rem/16);
    width: calc(30rem/16);
}

.btn-oauth__icon {
    margin-right: calc(4rem/16);
    margin-bottom: calc(5rem/16);
    height: calc(14.5rem/16);
    width: calc(14.5rem/16);
}

.btn-oauth__icon--facebook {
    height: calc(20rem/16);
    width: calc(20rem/16);
}