.img-copyright{
    position: absolute;
    color: #FFF;
    line-height: 1;
    font-size: calc(12rem/16);
    cursor: pointer;
    z-index: 20;
    max-width: 92%;
}

.img-copyright--top-left{
    top: calc(8rem/16);
    left: calc(8rem/16);
}

.img-copyright--top-right{
    top: calc(8rem/16);
    right: calc(8rem/16);
}

@media screen and (max-width: 767px){
    .is-affiliate .on-page-nav + main .img-copyright--top-right{
        top: 3rem;
    }
}

.img-copyright--bottom-left{
    bottom: calc(8rem/16);
    left: calc(8rem/16);
}

.img-copyright--bottom-right{
    bottom: calc(8rem/16);
    right: calc(8rem/16);
}

.img-copyright__icon{
    line-height: 1;
    padding: calc(4rem/16);
    font-size: calc(14rem/16);
}

.img-copyright__content{
    line-height: 1;
    padding: calc(4rem/16);
    align-items: center;

    background-color: rgba(255,255,255,.8);
    color: var(--color-text-default);
    display: none;
}

.img-copyright__close-icon{
    color: var(--color-text-default);
    font-size: calc(10rem/16);
}

.img-copyright__text+.img-copyright__close-icon,
.img-copyright__close-icon+.img-copyright__text{
    margin-left: calc(7rem/16);
}

.img-copyright .active .img-copyright__content{
    display: flex;
}

.img-copyright .active .img-copyright__icon{
    display: none;
}

.img-copyright--lightbox{
    @media screen and (min-width: 768px){
        right: calc(94rem/16);
    }
}