.geolocation-item {
    background-color: white;
    position: relative;
}
.geolocation-item__body {
    padding: calc(20rem/16) calc(12rem/16) calc(15rem/16) calc(12rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(32rem/16) calc(55rem/16) calc(30rem/16) 0;
    }
}
.geolocation-item__title {
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: calc(24/20);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
        line-height: calc(28/24);
    }
}
.geolocation-item__subtitle {
    color: var(--color-text-muted);
    font-size: calc(16rem/16);
    line-height: calc(18/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(7rem/16);
    }
}
.geolocation-item__content {
    margin-top: calc(10rem/16);
}
.geolocation-item__stages {
    padding: calc(10rem/16) calc(54rem/16) 0 calc(45rem/16);

    @media screen and (min-width: 768px) {
        padding: 0;
        margin-top: calc(24rem/16);
    }
}

.geolocation-item__label {
    position: relative;
}


/* form light radio box */
.geolocation-item__item .custom-radio__text {
    padding-left: calc(25rem/16);
    text-decoration: underline;
}
.geolocation-item__item.checked .custom-radio__text {
    color: var(--color-primary);
}
.geolocation-item__item .custom-radio__box {
    border-color: var(--color-text-muted);
}
.geolocation-item__item.checked .custom-radio__box {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
}
.geolocation-item__item.checked .custom-radio__box:after {
    background-color: transparent;
    color: white;
    font-family: iconfont;
    content: var(--icon-check);
    font-size: calc(7rem/16);
    left: calc(3rem/16);
    top: calc(4rem/16);
    position: absolute;
}

.geolocation-item__img-overlay {
    position: absolute;
    top: 0;
    left: 1rem;
    font-size: calc(24rem/16);
    width: calc(70rem/16);
    height: calc(59rem/16);
    text-align: center;
    font-family: var(--font-special);
    background-color: white;
    box-shadow: 0 0.8rem 5rem 0.2rem rgba(67,79,41,0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        box-shadow: 0 1rem 4rem 0.2rem rgba(67,79,41,0.3);
    }
}

.geolocation-item__img-overlay:after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(70rem/16);
    width: 0;
    height: 0;
    border-color: white transparent transparent;
    border-style: solid;
    border-width: calc(59rem/16) calc(45rem/16) 0 0;
}
.geolocation-item__img-overlay-body {
    position: absolute;
    z-index: 10;
    color: var(--color-primary);
    font-size: calc(20rem/16);
    left: 50%;
    transform: translateX(-30%);
    white-space: nowrap;
}
.geolocation-item__img {
    width: 100%;
    padding: calc(5rem/16);
    height: auto;

    @media screen and (min-width: 768px) {
        padding: calc(8rem/16);

    }

    /* img only */
    //width: auto!important;
    //height: 100%;
    /*@media screen and (min-width: 768px) {
        position: absolute;
        left: 50%!important;
        transform: translateX(-50%);
    }*/
}

/*-------------- challenge done -------------*/
.geolocation-item__done {
    display: none;
}
.geolocation-finished .geolocation-item__done {
    display: block;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    font-family: var(--font-default-bold);
    line-height: 1;
    position: absolute;
    border-radius: calc(8rem/16) calc(8rem/16) 0 calc(8rem/16);
    top: calc(6rem/16);
    right: calc(-14rem/16);
    padding: calc(10rem/16) calc(15rem/16) calc(10rem/16) calc(40rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(13rem/16) calc(46rem/16) calc(13rem/16) calc(55rem/16);
        font-size: calc(20rem/16);
    }
}
.geolocation-finished .geolocation-item__done:before {
    font-family: iconfont;
    content: var(--icon-check);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(14rem/16);
    left: calc(15rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        left: calc(22rem/16);
    }
}
.geolocation-finished .geolocation-item__done:after {
    content: '';
    width: calc(20rem/16);
    height: calc(20rem/16);
    background-color: var(--color-secondary);
    position: absolute;
    transform: rotate(45deg);
    right: calc(4rem/16);
    bottom: calc(-10rem/16);
    z-index: -1;
}
.geolocation-finished .geolocation-item__img-overlay-body  {
    color: white;
}
.geolocation-finished .geolocation-item__img-overlay  {
    background-color: var(--color-primary);
}
.geolocation-finished .geolocation-item__img-overlay:after {
    border-color: var(--color-primary) transparent transparent;
}