.live {
    padding:1.875rem 1rem 0 1rem;
    border: 1px solid var(--color-grey);
    background:#fff;
    @media screen and (min-width: 768px){
        padding:1.625rem 1.25rem 2.25rem 1.25rem;
    }
}
@media screen and (min-width: 768px){
    .live__col + .live__col .live__element:before {
        content:'';
        display: block;
        position: absolute;
        top:4.5rem;
        bottom: -.5rem;
        left:0;
        height: auto;
        width: 2px;
        background-color: var(--color-light-grey);
    }
}

.live__element {
    @media screen and (max-width: 767px){
        margin-bottom:1.5rem;
    }
}

.live__element--icon-container{
    position: relative;
    height: 4rem;
}

.live__element__icon {
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translateY(-50%);
}

.live__element__text{
    font-family: var(--font-default-bold);
    color: var(--color-dark-grey);
    font-size: 1.125rem;
}

.live__element__text--w-circle {
    @media screen and (max-width: 767px){
        margin-top:1rem;
    }
}

.live-element__info-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 768px){
        margin-top:-.25rem;
    }
}
.live-element__icon-bb-circle {
    font-size:1.25rem;
    @media screen and (min-width: 768px){
        font-size: 1.875rem;
    }
}


.live-element__icon-weather {
    font-size:1.625rem;
    @media screen and (min-width: 768px){
        font-size: 2rem;
    }
}