.weather-day-box,
.weather-item {
    margin-bottom:3rem;

    @media screen and (min-width: 768px){
        margin-bottom:8rem;
    }
}

.weather-teaser {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

}
.weather-teaser__body {
    text-align: center;
    padding:1rem .25rem;
    background:rgba(255,255,255,.8);

    @media screen and (min-width: 768px){
        text-align: left;
        font-size:2rem;
        padding:1.5rem 3.75rem 2rem 3.75rem;
    }
}

.weather-teaser__temp {
    text-align:center;
    font-family:var(--font-default-bold);
}
.weather-teaser__img-wrapper {
    height:2rem;
    margin:1.5rem auto;

    @media screen and (min-width: 768px){
        height:6rem;
    }
}
.weather-teaser__img {
    display: inline-block;
    width:2.75rem;
    height:auto;
    margin:0 auto;

    @media screen and (min-width: 768px){
        width:12rem;
    }
}