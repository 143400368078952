.loyalty-teaser {
    font-size: calc(13rem / 16);
    line-height: calc(20/13);
    background-color: #FFFFFF;
    box-shadow: 0 0 calc(10rem / 16) 0 rgba(67,79,41,0.14);
    padding: calc(15rem / 16) calc(20rem / 16);
    border-radius: calc(8rem / 16);
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(20rem / 16);
    }
}
.loyalty-teaser__title {
    font-size: calc(18rem / 16);
    line-height: calc(21/18);
}
.loyalty-teaser__text {
    margin-top: calc(6rem / 16);
}
.loyalty-teaser__value {
    color: var(--color-primary);
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}

/* product teaser slider */
.loyalty-teaser-slider {
    @media screen and (min-width: 768px) {
        padding: 0 calc(30rem / 16);
    }
}
.loyalty-teaser-slider:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(ellipse at center, rgba(66,79,41,.5) 0%,rgba(67,79,41,0) 80%);
    opacity: .2;
}
.loyalty-teaser-slider .slick-list{
    padding: calc(30rem / 16) calc(40rem / 16);
    margin: calc(-30rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: calc(-12rem / 16);
        margin-right: calc(-12rem / 16);
    }
}
.loyalty-teaser-slider .slider-item {
    padding-left: calc(5rem / 16);
    padding-right: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(12rem / 16);
        padding-right: calc(12rem / 16);
    }
}
.loyalty-teaser-slider .slick-arrow {
    width: auto;
    padding: 0;
    height: calc(20rem / 16);
}
.loyalty-teaser-slider .slick-arrow:before {
    font-size: calc(8rem / 16);
    color: var(--color-primary);
    content: var(--icon-arrow-down);
    position: absolute;
    left: 50%;
    top: 50%;

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
    }
}
.loyalty-teaser-slider .slick-arrow.slick-disabled:before {
    color: var(--color-text-default);
}
.loyalty-teaser-slider .slick-next:before {
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
.loyalty-teaser-slider .slick-prev:before {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
}


.loyalty-teaser-slider .slick-track{
    display: flex;
}
.loyalty-teaser-slider .slick-slide {
    height: auto;
    flex-grow: 1;
}
.loyalty-teaser-slider .slick-slide>div {
    display: flex;
    height: 100%;
}

/* horizontal */
.loyalty-teaser--horizontal {
    font-size: calc(13rem / 16);
    line-height: calc(24/15);
    padding: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(50rem / 16) calc(70rem / 16);
        font-size: calc(15rem / 16);
    }
}
.loyalty-teaser--horizontal + .loyalty-teaser--horizontal {
    margin-top: calc(24rem / 16);
}
.loyalty-teaser--horizontal .loyalty-teaser__title {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}


.loyalty-teaser__btn:disabled {
    cursor: pointer;
}
.is-disabled.loyalty-teaser,
.is-disabled-default.loyalty-teaser {
    pointer-events: none;
    opacity: .6;
}
.is-added .loyalty-teaser__btn-remove,
.loyalty-teaser__btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
}
.is-added .loyalty-teaser__btn-add,
.loyalty-teaser__btn-remove {
    display: none;
}
.loyalty-teaser__btn-icon {
    position: relative;
    top: calc(2rem / 16);
}

/* loyalty count */
.loyalty-count {
    font-size: calc(15rem / 16);
    border-radius: calc(8rem / 16);
    box-shadow: 0 calc(10rem / 16) calc(40rem / 16) 0 rgba(67,79,41,0.15);
    padding: calc(15rem / 16);
}
.loyalty-count__val {
    font-size: calc(40rem / 16);
    line-height: 1;
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: var(--color-primary);
}
.loyalty-count__label {
    font-size: calc(18rem / 16);
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: var(--color-primary);
}

/* loyalty teaser badge */