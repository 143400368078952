#filters {

}
@media screen and (min-width: 768px) {
    .content {
        position: relative;
    }
}

@media screen and (max-width: 767px) {
    .xs-map-holder {
        position: relative;
        margin:0 -1rem;
        width:auto;
    }
}
#close {
    position: absolute;
    top: 0;
    z-index: 2;
    background: #ebeaeb;
    right: 0;
    padding: 3px 8px;
    width: auto;
    cursor: pointer;
    font-weight: bold;
    @media screen and (max-width: 767px) {
        right:1rem;
    }
}
@media screen and (max-width: 767px) {
    #itemview {
        position: absolute;
        top:-1.5rem;
        left:3rem;
        right:0;
        z-index: 1;
    }
    #itemview .map-teaser {
        box-shadow: 0 2px 14px 0 rgba(67,79,41,.75);
    }
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
    @media screen and (max-width: 767px) {
        width: 90% !important;
    }
    @media screen and (max-width: 360px) {
        width: 225px !important;
    }
}

/*@media screen and (min-width: 768px) {
    .mapboxgl-ctrl-fullscreen {
        display:none !important;
    }
}*/

.mapboxgl-ctrl-top-left {
    top:auto !important;
    bottom: 30px;
}
.mapboxgl-ctrl-fullscreen span {
    background-size: 70%;
}