.main-img {
    position: relative;
    height:75vh;
    background-size:cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    /*pointer-events:none;*/

    @media screen and (max-width: 767px) {
        margin-top:50px
    }
}
@media screen and (max-width: 767px) {
    .main-img,
    .mainimg-content {
        margin-top:50px;
    }
    .is-affiliate .main-img,
    .is-affiliate .mainimg-content {
        margin-top:0;
    }
    .slider .main-img,
    .slider .mainimg-content {
        margin-top:0;
    }
    .js-hero-slider, .hero-slider {
        margin-top:50px;
    }
}
.main-img--fullscreen {
    height:calc(100vh - 80px);
    @media screen and (min-width: 768px) {
        height:100vh;
    }
}
.main-img--halfscreen {
    height:60vh;
    @media screen and (min-width: 768px) {
        height:50vh;
    }
}
.main-img--small {
    height:60vh;
}
.main-img--has-video {
    overflow: hidden;
    /*pointer-events: initial;*/
}
.main-img video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-width: 1920px;
    height: auto;
    transform:translateX(-50%) translateY(-50%);
    @media screen and (min-width: 1681px) and (max-width: 2199px) {
        min-width: 2200px;
    }
    @media screen and (min-width: 2200px) {
        min-width: 2750px;
    }
}
.main-img__overlay {
    position: absolute;
    top:40%;
    left:7.125rem;
    right:7.125rem;
    pointer-events:initial;
    z-index:1;
    transform:translateY(-50%);
    text-shadow: 0 0 10px rgba(0,0,0,.25);
    text-align:center;
    @media screen and (max-width: 767px) {
        left:1rem;
        right:1rem;
        top:36%;
        transform:translateY(0);
    }
}
.main-img__overlay.main-img__overlay--center-bottom {
    bottom:2rem;
    top:auto;
    transform:translateY(0);
    @media screen and (min-width: 768px) {
        bottom:6rem;
    }
}
.main-img__overlay.main-img__overlay--left-bottom {
    text-align:left;
    top: auto !important;
    bottom:2rem;
    transform:translateY(0);
    @media screen and (min-width: 768px) {
        bottom:5rem;
    }
}
.main-img__overlay.main-img__overlay--left-top {
    text-align:left;
    top:5rem;
    transform:translateY(0);
}
.main-img__quickfinder {
    width: auto;
    display: block;
    position: absolute;
    top:66%;
    left:115px;
    right:115px;
    z-index:1;
    transform:translateY(-50%);
    @media screen and (min-width: 768px) and (max-height: 800px) {
        top:50%;
    }
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.main-img__quickfinder--btn-only {
    z-index:9999;
}
.main-img__quickfinder__inner {
    position: relative;
    margin:0 auto;
    padding:.375rem;
    background: var(--color-mid-grey);
}

.main-img__profile {
    box-shadow: 0 0 10px rgba(0,0,0,.25);
    border-radius: 100%;
    margin-bottom:.5rem;
    @media screen and (min-width: 768px) {
        margin:4rem auto 1rem auto;
    }
    @media screen and (max-width: 359px) {
        width:70px;
        height:auto;
    }
}

.main-img__lp-logo {
    margin:0 auto 1rem auto;

    @media screen and (min-width: 768px) {
        margin-bottom:2rem;
    }
}

/* logo */
.mainimg__logo {
    position: absolute;
    right:.5rem;
    bottom:2rem;
}