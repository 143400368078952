.pagination {
    margin: 0;
}
.page-link {
    color:var(--color-text-default);
    cursor: pointer;
    border: none;
    background: transparent;
}
.page-item.active .page-link,
.page-link:hover,
.page-link:focus {
    color:var(--color-primary);
}
.page-link--with-icon {

}
.page-link .icon,
.page-link--with-icon .icon {
    position: relative;
    top:.125rem;
    color:var(--color-primary);
}

.pagination--dots {
    display: flex;
    align-items: center;
}
.pagination--dots .page-link--with-icon {
    font-size: calc(15rem / 16);
    padding: 0;
}
.pagination--dots .page-item + .page-item {
    margin-left: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(16rem / 16);
    }
}
.pagination--dots .page-link:not(.page-link--with-icon) {
    padding: 0;
    display: block;
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    border-radius: 50%;
    opacity: .1;
    background: var(--color-text-default);
}
.pagination--dots .active .page-link:not(.page-link--with-icon),
.pagination--dots .page-link:not(.page-link--with-icon):hover {
    background: var(--color-primary);
    opacity: 1;
}
.pagination--dots .disabled .page-link--with-icon .icon {
    color: var(--color-text-default);
}