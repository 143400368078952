.ui-datepicker {
    border: 0;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
    background-color: #fff;
    z-index: 1150 !important; /* higher as modal */

    @media screen and (min-width: 768px) {
        width:350px;
    }
}
.ui-datepicker td {
    padding-left: 0;
    padding-right: 0;
}
.ui-datepicker td a,
.ui-datepicker td span {
    padding: .2em .4em;
}

.ui-datepicker.is-loading {
    /*opacity: .2;*/
    /*pointer-events: ;*/
}
/*.ui-datepicker table .ui-state-active {
    background: inherit;
    color: inherit;
}*/

.ui-datepicker table .is-loading > a,
.ui-datepicker table .is-loading > span {
    opacity: .2;
}
.ui-datepicker table .is-arrival {
    opacity: 1;
}
.ui-datepicker table .is-arrival > a,
.ui-datepicker table .is-arrival > span {
    background: var(--color-primary);
    color: var(--color-primary-contrast);
    border-radius: 100px 0 0 100px;
}
.ui-datepicker .ui-state-disabled {
    opacity: .4;
}
.ui-datepicker table .is-departure {
    opacity: 1;
}
.ui-datepicker table .is-in-range {
    opacity: 1;
}
.ui-datepicker table .is-in-range > a,
.ui-datepicker table .is-in-range > span {
    background: var(--color-primary-light);
}
.ui-datepicker table .is-in-range.ui-state-disabled > span {
    color: #999;
}

.ui-datepicker table .is-booked {
    opacity: 1;
}
.ui-datepicker table .is-booked > a,
.ui-datepicker table .is-booked > span {
    background: var(--color-danger);
    color: var(--color-danger-contrast);
}
.ui-datepicker table .is-departure > a,
.ui-datepicker table .is-departure > span {
    background: var(--color-primary);
    color: var(--color-primary-contrast);
    border-radius: 0 50% 50% 0;
}


.ui-datepicker table .ui-datepicker-today>a,
.ui-datepicker table .ui-state-hover,
.ui-datepicker td a:hover {
    background: var(--color-secondary);
    color: var(--color-secondary-contrast);
}



/* arrows */
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    text-align: center;
    line-height: 1.8em;
    cursor: pointer;
    top: 2px;
    color: var(--color-secondary);
}
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover {
    text-decoration: none;
}

.ui-datepicker-prev:before {
    font-family: iconfont;
    content: var(--icon-arrow-left);
}

.ui-datepicker-next {
    transform:scaleX(-1);
}
.ui-datepicker-next:after {
    font-family: iconfont;
    content: var(--icon-arrow-left);
}


/* clear */
.datepicker__clear {
    position: absolute;
    right: .5rem;
    top: 100%;
    margin-top:.25rem;
    font-size: .75rem;
    z-index:5;
}
.datepicker__clear:hover,
.datepicker__clear:focus {
    text-decoration: underline;
}

