.embed-responsive-svg-map:before {
    padding-top:94.85066941297631%;
}

.map-nav {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    display: block;
    background: rgba(255, 255, 255, .95);
    z-index: 990;
    transform: translateX(-100%);
    @media screen and (max-width: 767px) {
        z-index: 1111;
    }
}

.map-nav__close {
    position: absolute;
    z-index: 995;
    top: 0;
    right: 0;
    height: 100px;
    width: 100px;
    background-color: var(--color-secondary);
    @media screen and (max-width: 767px) {
        height: 4rem;
        width: 4rem;
    }
}

.map-nav__close .icon {
    color: var(--color-white);
    font-family: iconfont;
    font-size: 1.25rem;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.map-nav.is-open {
    transform: translateX(0%);
}

.map-nav.is-open .map-nav__list {
    position: absolute;
    display: block;
    right: 0;
    width: 33.3333%;
    max-width:30rem;
    height: 100vh;
    background: var(--color-white);
    box-shadow: 0 12px 10px var(--color-dark-grey);
    z-index: 993;
    @media screen and (max-width: 767px) {
        width: 100%;
        box-shadow: none;
    }
}

.map-nav__content {
    position: absolute;
    top: 50%;
    margin-left: 5rem;
    margin-right: 5rem;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

.map-nav__content__headline {
    color:var(--color-secondary);
    font-family: var(--font-default-bold);
}

.map-nav-item{
    color:var(--color-dark-grey);
    font-family: var(--font-default-bold);
    display: block;
    margin-top:2rem;
    @media screen and (max-width: 767px) {
        margin-top:0.75rem;
    }
}

.map-nav-item:hover,
.map-nav-item.is-hovered{
    color:var(--color-secondary);
}

.map-nav__map {
    position: absolute;
    display: block;
    left: 0;
    top:0;
    right:0;
    bottom:0;
    z-index: 991;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.svg-map{
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-75%);
    position: absolute;
    width: 50%;
    max-width:1000px;
}

#inactive-map{
    fill: #80876E;
}

.svg-map__austria {
    fill:#E3E4E5;
}
.svg-map__stmk {
    fill:#C4C4C4;
}
.svg-map__sdt{
    fill:#3E363F;
}

.svg-map__line{
    fill:none;
    stroke:#AAAAAA;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-dasharray:10.01,10.01;
}

.svg-map__active-layer {
    opacity: 0;
    transition: opacity 110ms ease;
    cursor: pointer;
}

.svg-map__active-layer {
    fill: var(--color-secondary);
}

.svg-map__active-layer.is-hovered,
.svg-map__active-layer:hover {
    opacity: 1;
}

.inactive{
    transition: ease-in 0.1s;
    opacity: 0.8;
}

.inactive .icon.icon-close{
    color: var(--color-secondary-dark);
    opacity: 0.8;
    transform: rotate(-45deg);
    transition: ease-out 0.1s;
}

.svg-map__icon-ramsau,
.svg-map__icon-schladming,
.svg-map__icon-haus,
.svg-map__icon-groebming,
.svg-map__icon-soelk,
.svg-map__icon-oeblarn,
.svg-map__icon-grimming {
    position: absolute;
    z-index: 1100;
    padding: 0.25rem 0.42rem;
    display: inline-block;
    background-color: white;
    color: var(--color-primary);
    font-family: var(--font-default);
    font-size: 0.875rem;
    pointer-events: none;
}

.inactive.svg-map__icon-ramsau,
.inactive.svg-map__icon-schladming,
.inactive.svg-map__icon-haus,
.inactive.svg-map__icon-groebming,
.inactive.svg-map__icon-soelk,
.inactive.svg-map__icon-oeblarn,
.inactive.svg-map__icon-grimming {
    z-index: 1090;
}
.svg-map__icon-ramsau > .icon,
.svg-map__icon-schladming > .icon,
.svg-map__icon-haus > .icon,
.svg-map__icon-groebming > .icon,
.svg-map__icon-soelk > .icon,
.svg-map__icon-oeblarn > .icon,
.svg-map__icon-grimming > .icon  {
    transition: ease-in 0.1s;
    font-family: iconfont;
    vertical-align: middle;
}

.svg-map__icon-ramsau > span,
.svg-map__icon-schladming > span,
.svg-map__icon-haus > span,
.svg-map__icon-groebming > span,
.svg-map__icon-soelk > span,
.svg-map__icon-oeblarn > span,
.svg-map__icon-grimming > span {
    vertical-align: middle;
    margin-left: 0.5rem;
    font-size: 0.875rem;
}

.svg-map__txt-valley {
    padding-left:1.25rem;
    max-width:20rem;
    display: block;
}

/* Marker Ramsau */
.svg-map__icon-ramsau {
    left: 13%;
    top: 24%;
}

/* Marker Schladming */
.svg-map__icon-schladming {
    left: 17%;
    top: 43%;
}

/* Marker Haus */
.svg-map__icon-haus {
    left: 29%;
    top: 33%;
}

/* Marker Sölk */
.svg-map__icon-soelk {
    left: 48%;
    top: 45%;
}

/* Marker Gröbming */
.svg-map__icon-groebming {
    left: 42%;
    top: 22%;
}

/* Marker Öblarn */
.svg-map__icon-oeblarn {
    left: 54%;
    top: 28%;
}

/* Marker Irdning */
.svg-map__icon-grimming {
    left: 67%;
    top: 25%;
}