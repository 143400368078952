@media (min-width: 768px) {
    .progress-bar {
        margin-top: -2rem;
    }
    .step-progress__bar-item.is-done .step-progress__icon {
        background-color: var(--color-progress);
    }
    .step-progress__bar-item.is-done .step-progress__text {
        color: var(--color-progress);
    }

    .step-progress__bar-item.is-active .step-progress__icon {
        background-color: var(--color-primary);
        color: white;
    }
    .step-progress__bar-item.is-active .step-progress__text{
        color: var(--color-primary);
    }

    .step-progress__bar-item.is-done:after {
        background-color: var(--color-progress);
    }

    .step-progress__bar-item.is-active:after {
        background: linear-gradient(90deg, var(--color-progress) 0%, var(--color-primary) 100%);
    }

    .step-progress__text {
        font-size: 14px;
        margin-top: 2px;
    }
}