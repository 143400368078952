.container-outer {
    width:100%;
    margin:0 auto;
    max-width: 100vw;
    @media screen and (min-width: 4100px) {
        width: calc(4000rem / 32);
        margin: auto;
        box-shadow: 0 0 2rem rgba(0,0,0,0.2);
        border: .25rem solid #BBB;
        border-top: 0;
        border-bottom: 0;
    }
}