/* desktop */
.sticky-btn {
    position: fixed;
    top: 60%;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 995;
    transition: all .2s linear;

    @media screen and (min-width: 768px) and (max-height: 820px) {
        top: 80%;
    }

    @media screen and (max-width: 767px) {
        top:auto;
        bottom:0;
        right: 0;
        transform: translateX(0) translateY(100px);
    }
}

.sticky-btn.is-visible {
    @media screen and (max-width: 767px) {
        transform: translateX(0) translateY(0);
    }
}

.sticky-btn .sticky-btn__btn  {
    display: block;
    text-align: center;
    white-space: nowrap;
    padding:1.125rem 1.5rem;
    font-size: .925rem;
    color:var(--color-secondary);
    font-family: var(--font-default-bold);
    background: #fff;
    z-index:850;
    pointer-events:initial;
    @media screen and (min-width: 768px) {
        display: inline-block;
        transform-origin: left center;
        transform: rotate(90deg) translate(-50%,-50%);
        box-shadow: 0 2px 14px 0 rgba(67,79,41,0.2);
    }
    @media screen and (min-width: 768px) and (max-width:1399px){
        padding: .75rem 1.5rem;
    }
    @media screen and (min-width: 1400px) {
        padding:1.375rem 2.75rem;
    }
}
.sticky-btn .sticky-btn__btn > .icon  {
    position: relative;
    top: .25rem;
    font-size: 1.25rem;
    margin-right: .5rem;
}




/* mobile */
.sticky-bottom {
    position: fixed;
    bottom:-.125rem;
    left: 0;
    right: 0;
    z-index: 800;
    box-shadow: 0 2px 14px 0 rgba(67,79,41,0.2);
    transform: translateX(0) translateY(100px);
    transition: all .2s linear;
}
.sticky-bottom.is-visible {
    transform: translateX(0) translateY(0);
}
.sticky-bottom--small {
    right: auto;
    width:25%;
}
.sticky-bottom .sticky-bottom__btn-cart,
.sticky-bottom .sticky-bottom__btn-login {
    padding:.875rem;
    color:var(--color-dark-grey);
}
.sticky-bottom .sticky-bottom__btn-cart {
    border-left:1px solid #ccc;
}
.sticky-bottom .sticky-bottom__btn-cart>.icon,
.sticky-bottom .sticky-bottom__btn-login>.icon {
    font-size: 1.25rem;
    position: relative;
    top:.125rem;
    @media screen and (max-width: 374px) {
        font-size: 1.125rem;
    }
}
.sticky-bottom .sticky-bottom__btn-booking {
    padding:.875rem 1rem;
    font-size:.9375rem;
}
.sticky-bottom .sticky-bottom__btn-booking>.icon {
    font-size:1.25rem;
    margin-right:.5rem;
    position: relative;
    top:.25rem;
}

.sticky-bottom:not(.sticky-bottom--with-ticket) .sticky-bottom__btn-booking>.icon {
    @media screen and (max-width: 374px) {
        display:none;
    }
}

@media screen and (max-width: 767px) {
    .sticky-bottom--with-ticket .btn-booking--has-ticket,
    .sticky-bottom--with-ticket .sticky-bottom__btn-booking-with-ticket {
        display:inline-block;
        padding:1rem .75rem;
        width: 50%;
    }
    .sticky-bottom--with-ticket .sticky-bottom__btn-booking-text,
    .sticky-bottom--with-ticket .btn-booking--has-ticket-text {
        display:none;
    }
    .sticky-bottom--with-ticket .sticky-bottom__btn-booking>.icon,
    .sticky-bottom--with-ticket .btn-booking--has-ticket>.icon {
        display:block;
        position: relative;
        top:.125rem;
        line-height:1;
        font-size: 1.25rem;
        margin:0;
    }
}
@media screen and (max-width: 374px) {
    .sticky-bottom--with-ticket .sticky-bottom__btn-booking>.icon,
    .sticky-bottom--with-ticket .btn-booking--has-ticket>.icon {
        font-size: 1.125rem;
    }
}