.custom-radio ul.parsley-errors-list.filled,
.custom-checkbox ul.parsley-errors-list.filled,
.form-errors {
    position: absolute;
    left:0;
    bottom:-1.375rem;
    color: var(--color-danger);
    font-size:14px;
    z-index:10;
    pointer-events: none;
}
.form-errors--relative {
    position: relative;
    left: auto;
    bottom:auto;
    margin-top:-1.5rem;
}
.custom-radio ul.parsley-errors-list.filled,
.custom-checkbox ul.parsley-errors-list.filled{
    left:1.75rem;
    bottom:-.75rem;
}
.form-errors:empty {
    display: none;
}
.form-errors--right{
    left: unset;
    bottom: unset;
    right: calc(-150rem/16);
}
.custom-radio ul,
.custom-checkbox ul,
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* EEM */
.form-errors--eem-checkbox {
    position: relative;
    left: auto;
    bottom:auto;
}
.form-errors--eem-checkbox ul.parsley-errors-list.filled,
.form-errors--eem-checkbox ul.parsley-errors-list.filled{
    position: relative;
    left: .75rem;
    bottom:auto;
}

.form-errors.form-errors--bottom {
    bottom: -2rem;
}