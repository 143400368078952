html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/

    @media screen and (min-width: 768px) and (max-width: 991px) {
        /* Table scaling */
        font-size: calc(16 / (991 / 100) * 1vw);
    }

    @media screen and (min-width: 2000px) and (max-width: 3999px) {
        /*Scale the whole page up on bigger screens*/
        font-size: calc(16vw / 2000 * 100);
    }

    @media screen and (min-width: 4000px) {
        font-size: 32px;
    }
}
body {
    position: relative;
    overflow-x: hidden;
    background: var(--color-mid-grey);
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
}

body:not(.is-affiliate) #main-content.no-img {
    padding-top:4rem;

    @media screen and (max-width: 767px) {
        padding-top:7.5rem;
    }
    @media screen and (min-width: 1920px) {
        padding-top:7.5rem;
    }
}


#main-content.eem {
    padding:5rem 0;

    @media screen and (max-width: 767px) {
        padding:4rem 0;
    }
}

.container-outer {
    position: relative;
    width:100%;
    margin:0 auto;
    max-width: 100vw;
    @media screen and (min-width: 4100px) {
        width: calc(4000rem / 32);
        margin: auto;
        box-shadow: 0 0 2rem rgba(0,0,0,0.2);
        border: .25rem solid #BBB;
        border-top: 0;
        border-bottom: 0;
    }
}

.container.container {
    max-width: calc(1420rem/16);
    width: 100%;
}
.container.container--extra-narrow {
    max-width: calc(780rem/16);
}
.container.container--narrow {
    max-width: calc(1020rem/16);
}
.container.container--recommendation-area,
.container.container--wide {
    max-width: calc(1580rem/16);
}
.container .container {
    padding-left:0;
    padding-right:0;
}

@media screen and (max-width: 767px) {
    .container.container--recommendation-area{
        padding: 0;
        width: 100vw;
        margin: 0;
    }
    .container.container--xs-full {
        margin:0 -1rem;
        width: auto;
    }
}
