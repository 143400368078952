.console {
    color:var(--color-secondary);
    padding:.5rem 8rem;
    position: absolute;
    left:0;
    right:0;
    top:0;
    font-family: var(--font-default-bold);
    z-index:800;
    pointer-events: none;

    @media screen and (max-width: 767px) {
        top:2rem;
        padding:.5rem;
    }
}
.console--noimg {
    @media screen and (max-width: 767px) {
        top:4.5rem;
    }
}
.console--white {
    color:#fff;
    text-shadow: 0px 0px 20px rgba(0,0,0,0.59);
}
.console__item {
    color:inherit;
    margin:0 .125rem !important;
    position: relative;
    font-size: .75rem;
    @media screen and (min-width: 768px) {
        font-size: .875rem;
    }
}
.console__link {
    color:inherit;
    padding:.25rem;
    position: relative;
    pointer-events: initial;
    @media screen and (min-width: 768px) {
        padding:1rem;
    }
    @media screen and (max-width: 374px) {
        padding:.125rem;
        font-size:.625rem;
    }
}
.console__link>.icon {
    color:inherit;
    font-size: 1.375rem;
    margin-bottom: .125rem;

    @media screen and (min-width: 768px) {
        font-size: 1.75rem;
    }
}