.icon-slider {
    position:relative;

}
.icon-slider__hero-img__img {
    display: block;
    width: 100%;
    height: auto;
}

.icon-slider__headline{
    color: white;
    text-align: center;
    margin: 0 auto 1.5rem auto;
    text-shadow: 0 0 10px rgba(0,0,0,.25);

    @media screen and (min-width: 1200px) {
        margin: 0 auto 5rem auto;
    }
}

.icon-slider__slider{
    position: absolute;
    top: 50%;
    left: 5%;
    right: 5%;
    transform: translateY(-50%);
    z-index:10;
}

.icon-slider__description{
    font-size: 1.125rem;
    line-height:1.33;
    color: #fff;
    font-family: var(--font-default-bold);
    position: relative;
    padding:1.5rem 1rem;
    margin: 0 auto;
    text-shadow: 0 0 8px rgba(0,0,0,.35);

    @media screen and (min-width: 1200px) {
        font-size: 1.25rem;
    }
}

.icon-slider__icon{
    position:relative;
    display: block;
    background: white;
    opacity: 1;
    border-radius: 100%;
    height: 4rem;
    width: 4rem;
    margin:0 auto;
    text-align: center;

    @media screen and (min-width: 1200px) {
        height: 6.5rem;
        width: 6.5rem;
    }
}

.icon-slider__icon > a > .icon-slider__img {
    border-radius: 100%;
    border: 1px solid white;
}

.icon-slider__icon > a > .icon {
    font-size: 2rem;
    color: var(--color-primary);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 1200px) {
        font-size: 3rem;
    }
}

.icon-slider .slick-disabled {
    pointer-events: none;
    opacity: 0.35;
}

.icon-slider .slick-prev {
    left: -5%;
}

.icon-slider .slick-next {
    right: -5%;
}

@media screen and (max-width: 767px){
    .icon-slider__headline{
        margin: 0 auto 2rem auto;
    }
}
