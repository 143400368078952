.marker-cluster__count {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
    font-size:1.125rem;
    font-family: var(--font-default-bold);
}
.leaflet-top.leaflet-top, .leaflet-bottom.leaflet-bottom {
    z-index: 500;
}
.leaflet-container a {
    color: var(--color-primary);
}
.leaflet-container a.btn {
    color:#fff;
}

.leaflet-popup-content-wrapper.leaflet-popup-content-wrapper {
     border-radius: 0;
}
.leaflet-popup-content.leaflet-popup-content p {
    font-size:1rem;
}
.leaflet-container a.leaflet-popup-close-button.leaflet-popup-close-button {
    padding: 1rem;
    width: auto;
    height:auto;
    color:#333 !important;
    font-size:1.5rem;
    background:rgba(255,255,255,.5)
}
.leaflet-popup-content.leaflet-popup-content {
    margin: 0;
    width: 300px;
}
.leaflet-popup__text {
    padding: .865rem 1.25rem;
}
.leaflet-popup__title {
    margin: .5rem 0;
}
