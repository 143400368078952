.custom-radio{
    position: relative;
    padding-left: calc(20rem/16);
}
a.custom-radio {
    display:block;
}
a.custom-radio.active .custom-radio__box:before {
    visibility: visible;
}
.custom-radio a {
    color:var(--color-primary);
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    overflow: hidden;
    border: 2px solid var(--color-text-default);
    background: #ffffff;
    width: calc(18rem/16);
    height: calc(18rem/16);
    margin-top: .2em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-text-default);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box:before {
    background: var(--color-primary);
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
    /*font-size:1.125rem;*/
    padding-left:.75rem;
}