.profil-sprite {
  background-image: url(/static/img/sprite/profil-sprite.png);
  background-repeat: no-repeat;
  display: inline-block;
}

.profil-sprite-couple-active {
  width: 71px;
  height: 50px;
  background-position: -5px -5px;
}

.profil-sprite-couple-inactive {
  width: 71px;
  height: 50px;
  background-position: -86px -5px;
}

.profil-sprite-family-active {
  width: 89px;
  height: 50px;
  background-position: 3px -65px;
}

.profil-sprite-family-inactive {
  width: 89px;
  height: 50px;
  background-position: -78px -65px;
}

.profil-sprite-group-active {
  width: 110px;
  height: 50px;
  background-position: -4px -125px;
}

.profil-sprite-group-inactive {
  width: 110px;
  height: 50px;
  background-position: -123px -125px;
}

.profil-sprite-single-active {
  width: 37px;
  height: 50px;
  background-position: -167px -5px;
}

.profil-sprite-single-inactive {
  width: 37px;
  height: 50px;
  background-position: -213px -5px;
}

.profil-sprite-summer-active {
  width: 52px;
  height: 50px;
  background-position: -124px -185px;
}

.profil-sprite-summer-inactive {
  width: 52px;
  height: 50px;
  background-position: -184px -185px;
}

.profil-sprite-winter-active {
  width: 52px;
  height: 50px;
  background-position: -4px -185px;
}

.profil-sprite-winter-inactive {
  width: 52px;
  height: 50px;
  background-position: -64px -185px;
}
