.masonry-gallery {
    position: relative;
    margin:0 -.375rem;
}
.masonry-gallery__item {
    padding:.375rem;
    margin:0;
    width:50%;
    @media screen and (min-width: 768px) {
        width:33.333333%;
    }
}
.masonry-gallery--2cols .masonry-gallery__item {
    width:50%;
}
.masonry-gallery__item img,
.masonry-gallery__item picture {
    width:100%;
    height:auto;
    display: block;
}
